.NAMLLogsHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #FFF1F4;
}

.NApageTitle {
    color: #4E253A;
    font-family: "GTWalsheimProRegular";
    font-size: 30px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: auto;
}

.mlLogWrapper {
    padding: 10px 10px 0px 10px;
}

.TableWrapper {
    background: transparent;
    border: none;
    padding: 10px;
    border-radius: 10px;
    align-items: center;
    color: #495057;
    box-shadow: 0px 2px 5px 0px #cccaca;
    margin-left: 10%;
    margin-bottom: 20px;
    width: 78.9%;
}

.TableWrapperGrid {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  .TableWrapperGrid::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }

.modal {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    position: relative;
}

.responsive {
    overflow-x: auto;
    width: 100%;
    /* padding: 20px; */
}

.rowCellSubjectID {
    max-width: 150px;
    min-width: 150px;
    text-align: center;
    background-color: #f5f6f8;
    font-size: 18px;
    font-family: GTWalsheimProRegular;
}

.nameDiv {
    margin-left: 3px;
    align-items: center;
}

/* Device Status Vailable */
.deviceStatus {
    max-width: 150px;
    min-width: 150px;
    text-align: center;
    background-color: #D9EEEB;
    font-size: 18px;
    font-family: "GTWalsheimProRegular";
}

/* Device Status Not available */
.deviceStatusNotAvailable {
    max-width: 150px;
    min-width: 150px;
    text-align: center;
    background-color: #F9DED7;
    font-size: 18px;
    font-family: "GTWalsheimProRegular";
}

.rowCellMouthLabID {
    max-width: 150px;
    font-size: 18px;
    min-width: 150px;
    border: 4px solid #F5F6F8;
    background-color: #fff;
    text-align: center;
    font-family: "GTWalsheimProRegular";
}

.actionHeader {
    max-width: 100px;
    min-width: 100px;
    text-align: center;
    align-items: center;
    font-size: 18px;
    font-family: "GTWalsheimProRegular";
}

.buttonDiv {
    text-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 20px; */
}

.assignButton {
    /* min-width: 150px; */
    width: 100%;
    background-color: #7C596A;
    color: white;
    /* font-weight: 200; */
    border-style: none;
    border-width: 10px;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
    cursor: pointer;
}

.assignButton:hover {
    background-color: #4E253A;
    color: white;
    border: 1px solid #4E253A;
}

.removeButton {
    /* min-width: 150px; */
    width: 100%;
    background-color: #F1D4D4;
    color: #4E253A;
    font-weight: 700;
    border-style: none;
    border-width: 10px;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
    cursor: pointer;
}

.removeButton:hover {
    background-color: #F14C00;
    color: white;
    border: 1px solid #F14C00;
}

.buttonDiv {
    text-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 20px; */
}

.paper {
    background-color: #fff;
    outline: none;
    border-radius: 20px;
    padding: 10px;
    align-items: center;
    width: 400px;
    /* height: 350px; */
    overflow-x: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.menuTitle {
    background-color: #ffff;
    display: flex;
    /* border-bottom: 1px solid gray; */
    text-align: center;
    width: 100%;
    align-items: center;
    font-size: 20px;
    font-family: "GTWalsheimProRegular";
}

.titleText {
    font-style: "GTWalsheimProRegular";
    font-weight: bold;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 28px;
    padding: 10px;
    color: #4E253A;
}

.paperUnassignML {
    background-color: #fff;
    outline: none;
    border-radius: 20px;
    padding: 10px;
    align-items: center;
    width: 400px;
    /* height: 350px; */
    overflow-x: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.cardHighRiskUA {
    box-shadow: 0 0 2px 3px #cccaca;
    margin: 20px 60px 20px;
    padding: 10px 10px 30px 10px;
    height: 30px;
    border-radius: 10px;
    transition: 0.3s linear all;
    /* background-image: linear-gradient(to right, #00B4D8 ,#ADE8F4); */
    background-color: #FFF8F9;
    opacity: 0.7;
    display: flex;
    /* border: 1px solid linear-gradient(to right, #00B4D8 ,#ADE8F4); */
    border: 0px;
    min-width: 200px;
}

.cardHighRiskUA:hover {
    box-shadow: 2px 2px 3px #495057;
}

.cardHighRiskA {
    box-shadow: 0 0 4px 2px #4E253A;
    margin: 20px 60px 20px;
    padding: 10px 10px 30px 10px;
    height: 30px;
    border-radius: 10px;
    transition: 0.3s linear all;
    /* background-image: linear-gradient(to right, #00B4D8 ,#ADE8F4); */
    background-color: #7C596A;
    opacity: 0.9;
    display: flex;
    /* border: 1px solid linear-gradient(to right, #00B4D8 ,#ADE8F4); */
    border: 0px;
    min-width: 200px;
}

.cardHighRiskA:hover {
    box-shadow: 2px 2px 3px #7C596A;
    background-color: #4E253A;
    cursor: pointer;
}

.iconDivStyles {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    width: 25%;
    margin: 0px;
    padding-top: 0px;
    background-color: #F1D4D4;
    border-radius: 50%;
    justify-content: center;
}

.iconDivStyles :hover>.highRiskIconA {
    color: #03045e;
}

.iconDivStyles :hover>.highRiskIconUA {
    color: #03045e;
}

.highRiskIconA {
    width: 32px !important;
    height: 32px !important;
    fill: #4E253A !important;
}

.highRiskIconUA {
    width: 32px !important;
    height: 32px !important;
    fill: #7C596A;
}

.countDivStyles {
    width: 70%;
    text-align: right;
}

.patientCountHighRiskUA {
    font-weight: 1000;
    font-size: 28px;
    color: #4E253A;
    text-align: right;
    font-family: "GTWalsheimProRegular";
}

.patientCountHighRiskA {
    font-weight: 1000;
    font-size: 28px;
    color: #f5f6f8;
    text-align: right;
    font-family: "GTWalsheimProRegular";
}

.MLInstructions {
    width: 100%;
    display: flex;
    flex-direction: column;
    column-gap: 0;
    align-items: center;
    justify-items: center;
    padding: 20px;
}

.deviceSelectionWarning {
    display: flex;
    width: 100%;
    justify-content: left;
    margin-top: -20px;
    margin-bottom: -20px;
}

.mlImg {
    width: 25%;
    height: 25%;
}

.mouthLabImgDiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

.submitButton {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    min-width: 40%;
    background-color: #7C596A;
    color: white;
    /* font-weight: 200; */
    border: 1px solid #7C596A;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 12px;
}

.submitButton:hover {
    background-color: #4E253A;
    border: 1px solid #4E253A;
}

.cancelButton {
    margin-top: 10px;
    margin-right: 10px;
    min-width: 40%;
    background-color: #fff;
    color: #8D919A;
    font-weight: 600;
    border: 1px solid #8D919A;
    border-radius: 10px;
    padding: 10px;
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 12px;
}

.cancelButton:hover {
    color: #4E253A;
    background-color: #F1D4D4;
    border: 1px solid #8D919A;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 10px;
}

.selectedML {
    font-weight: bolder;
    font-size: 20px;
    padding-left: 5px;
    padding-right: 3px;
}
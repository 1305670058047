.containerDiv {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-right: 40px;
}

.containerHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #ffe4f2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.headerText {
    display: flex;
    color: #4e253a;
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
    font-weight: bold;
}

.expandButtonDiv {
    display: flex;
    border-radius: 50%;
    background-color: #ffffff;
}

.expandButtonDiv:hover {
    cursor: pointer;
}

.containerBody {
    display: flex;
    padding: 10px;
    background-color: #f8eff5;
    width: 100%;
}

.containerFooter {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: -18px;
    margin-bottom: 20px;
}

.addDiagnosisDiv {
    display: flex;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 80px;
    padding-right: 80px;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-family: "GTWalsheimProRegular";
    font-size: 12px;
    font-weight: bold;
    background-color: #AA346F;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.addDiagnosisDiv:hover {
    cursor: pointer;
}

.paper {
    display: flex;
    flex-direction: column;
    background-color: #FFF6FC;
    outline: none;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 75vw;
    min-height: 70vh;
    max-height: 95vh;
    position: fixed;
    top: 50%;
    left: 50%;
    right: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.paper {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.paper::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.addDxHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #AA346F;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    font-family: "GTWalsheimProRegular";
    padding: 10px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    overflow: hidden;
}

.addDxBody {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    row-gap: 20px;
    height: 100%;
    overflow-y: auto;
}

.searchBar {
    position: relative;
    width: 90%;
    height: 36px;
    border-radius: 50px;
    font-size: 14px;
    box-shadow: 0px 0px 4px #ccc;
    font-style: italic;
    background-color: #ffffff;
}

.modal {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    position: relative;
    /* margin-left: 10%;
            margin-right: 90%;
            top: 50; */
}

.rowDiv {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.rowDiv1 {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.rowDiv2 {
    display: flex;
    flex-direction: column;
    width: 85%;
    justify-content: center;
    /* padding-left: 7vw; */
}

.rowDiv3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.errMsgDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: red;
    font-style: italic;
    font-family: "GTWalsheimProRegular";
    margin-bottom: 15px;
}

.buttonsDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 40px;
    margin-top: 15px;
    margin-bottom: 30px;
}

.fieldDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px;
}

.cancelButton {
    display: flex;
    color: #AA346F;
    border-radius: 20px;
    width: 85px;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #FFFFFF;
    border: #AA346F 1px solid;
}

.cancelButton:hover {
    cursor: pointer;
}

.saveButton {
    display: flex;
    color: #FFFFFF;
    border-radius: 20px;
    width: 85px;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #AA346F;
    border: #AA346F 1px solid;
}

.saveButton:hover {
    cursor: pointer;
}

.fieldDiv1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
}

.fieldLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-left: -1px;
    font-family: "GTWalsheimProRegular";
    font-size: 13px;
    font-weight: bold;
    color: #4e253a;
}

.fieldLabel1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-left: 20px;
    font-family: "GTWalsheimProRegular";
    font-size: 13px;
    font-weight: bold;
    color: #4e253a;
    width: 100%;
}

.notesCharCount {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    font-family: "GTWalsheimProRegular";
    font-size: 10px;
    color: #4e253a;
    padding-top: 5px;
    padding-left: 20px;
}

.notesCharCountRed {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    font-family: "GTWalsheimProRegular";
    font-size: 10px;
    color: red;
    padding-top: 5px;
    padding-left: 20px;
}

.fieldInput {
    display: flex;
}

.inputFieldSelcted {
    display: flex;
    height: 32px;
    width: 26vw;
    border: solid #A040AB 0.5px;
    background-color: #FFFFFF;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
}

.inputFieldSelctedDisabled {
    display: flex;
    height: 32px;
    width: 26vw;
    border: solid #A040AB 0.5px;
    background-color: #f8eff5;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
}

.inputFieldSelctedDisabledReq {
    display: flex;
    height: 32px;
    width: 26vw;
    border: solid red 0.5px;
    background-color: #f8eff5;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
}

.dropdown {
    height: 2.5vh !important;
    width: 11.5vw !important;
    border: solid #A040AB 0.5px !important;
    background-color: #FFFFFF !important;
    padding: 3px 5px !important;
    font-family: "GTWalsheimProRegular" !important;
}

.dropdownReq {
    height: 2.5vh !important;
    width: 11.5vw !important;
    border: solid red 0.5px !important;
    background-color: #FFFFFF !important;
    padding: 3px 5px !important;
    font-family: "GTWalsheimProRegular" !important;
}

.checkBoxLabel {
    display: flex;
    font-size: 15px;
    font-family: "GTWalsheimProRegular";
    margin-left: 0px;
}

.inputFieldSelctedDOB {
    display: flex;
    height: 32px;
    width: 11vw;
    border: solid #A040AB 0.5px;
    background-color: #FFFFFF;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
}

.inputFieldSelctedDOBDisabled {
    display: flex;
    height: 32px;
    width: 11vw;
    border: solid #A040AB 0.5px;
    background-color: #f8eff5;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
}

.datePickerWrapper {
    /* margin-left: 190px; */
    border-radius: 10px;
    margin-right: auto;
    width: 20vw;
    overflow: auto;
    visibility: visible;
    color: #4E253A;
    z-index: 2;
    position: absolute;
    border: 2px solid #4E253A;
    display: flex;
    flex-direction: column;
    margin-top: 35px !important;
}

.applyButtonDiv {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    padding-bottom: 10px;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    margin-top: -5px;
}

.applyButton {
    vertical-align: middle !important;
    justify-content: center !important;
    /* display:flex !important; */
    flex-direction: row !important;
    text-align: center !important;
    align-items: center !important;
    color: #fff !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    background-color: #aa346f !important;
    font-family: GTWalsheimProRegular !important;
    text-transform: capitalize !important;
    width: 85px !important;
    margin: 4px 6px 2px 5px !important;
    padding: 3px 0px !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.applyButton:hover {
    background-color: #F14C00 !important;
    cursor: pointer;
}

.addNewNoteDiv {
    display: flex;
    flex-direction: row;
    position: relative;
    height: fit-content;
    align-items: center;
    column-gap: 0px;
}

.notesEditor {
    position: relative;
    width: 94% !important;
    padding-right: 10px !important;
    border-radius: 2px !important;
    font-size: 14px !important;
    align-items: start !important;
    justify-content: left !important;
    margin-left: 20px !important;
    padding-left: 10px !important;
    display: inline-block;
    overflow: hidden;
    font-family: GTWalsheimProRegular !important;
    background-color: #FFFFFF;
    border: solid #A040AB 0.5px;
    padding-top: 10px !important;
}



.mainDiv {}

.cancelButton1 {
    vertical-align: middle !important;
    justify-content: center !important;
    /* display:flex !important; */
    flex-direction: row !important;
    text-align: center !important;
    align-items: center !important;
    color: #aa346f !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    background-color: #ffe0f0 !important;
    font-family: GTWalsheimProRegular !important;
    text-transform: capitalize !important;
    width: 85px !important;
    margin: 4px 6px 2px 5px !important;
    padding: 3px 0px !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.cancelButton1:hover {
    background-color: #ffffff !important;
    cursor: pointer;
}
.containerDiv {
    /* width: 100%; */
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    /* padding: 10px; */
    background-color: #ffffff;
    border-radius: 10px;
}

.datePickerDiv {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #FFE0F0;
    text-align: left;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.datePickerIconDiv svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
    color: #FFFFFF;
    background-color: #AA346F;
    padding: 8px;
    border-radius: 50px;
    margin-left: 2px;
    margin-bottom: -5px;
}

.accContainer {
    align-items: center;
}

.displayContainer {
    background-color: #fff;
    /* background-color: red; */
    /* border-radius: 10px; */
    padding-left: 3px;
    padding-right: 3px;
    padding-bottom: 5px;
    padding-top: 5px;
    /* margin-left: 10px;
     margin-right: 10px; */
    align-items: center;
    height: 55px;
    display: flex;
    box-shadow: 0px 0px 8.107px 0px rgba(0, 0, 0, 0.25);
}

.pageHeader {
    margin-left: 20px;
    display: flex !important;
    flex-direction: row;
    column-gap: 10px;
    font-family: 'GTWalsheimProRegular';
    font-size: 20px;
    font-weight: 900;
    align-items: center;
    justify-content: center;
    color: #4E253A;
}

.userGreetings {
    margin-left: auto;
    margin-right: 20px;
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
}

.userInfoDiv {
    display: flex;
    flex-direction: row !important;
    column-gap: 10px !important;
    border-radius: 50px;
    border: 1.5px solid #7C596A;
    padding-left: 3px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 5px;
    background-color: #FFFBFD;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    min-width: 140px;
    align-items: center;
    justify-content: left;
    font-size: 16px;
    font-family: "GTWalsheimProRegular";
}

.avatar2 {
    height: 26px !important;
    width: 26px !important;
    border: 1.5px solid #7C596A;
}

.filterDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'GTWalsheimProRegular' !important;
    justify-content: space-between;
    float: right;
    column-gap: 10px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding-left: 4px;
    padding-right: 6px;
    height: 32px;
    margin-bottom: 2px;
    background-color: #ffffff;
    min-width: 122px;
}

.filterDiv:hover {
    cursor: pointer;
}

.filterDivSelected {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'GTWalsheimProRegular' !important;
    float: right;
    column-gap: 10px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding-left: 6px;
    padding-right: 6px;
    height: 32px;
    margin-bottom: 2px;
    background-color: #ffffff;
}

.filterDivLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
}

.filterDivLabel2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    margin-left: 10px;
}

.filterBackdrop {
    position: absolute;
}

.wrapper {
    background-color: #FFFFFF;
    /* display: flex; */
    flex-direction: row;
    outline: none;
    /* width: fit-content; */
    border-radius: 5px;
    align-items: center;
    overflow-x: hidden;
    position: absolute;
    transform: translate(-59px, 5px);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    min-width: 122px;
}

.filtersDivText, .filtersDivTextSelected {
    font-family: 'GTWalsheimProRegular' !important;
    font-size: 0.8em !important;
    padding-bottom: 5px;
    column-gap: 7px;
    color: #4E253A;
    font-weight: bold;
    height: 30px;
    /* padding-left: 10px; */
    /* width: fit-content; */
    margin-right: 0px !important;
    margin-left: 0px !important;
    padding-top: 5px;
    /* padding-right: 15px; */
    padding-left: 4px;
    white-space: nowrap;
}

.filtersDivTextSelected {
    background-color: #FDEAFF;
}

.buttonContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    padding-right: 7px;
}

.applyButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background-color: #AA346F;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 6px;
    padding-bottom: 6px;
    color: #ffffff;
    font-size: 13px;
    width: 60px;
}

.applyButton:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: #4E253A;
}

.downloadButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background-color: #AA346F;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 6px;
    padding-bottom: 6px;
    color: #ffffff;
    font-size: 13px;
    width: 60px;
}

.downloadButtonDisabled {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background-color: #9c9599;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 6px;
    padding-bottom: 6px;
    color: #ffffff;
    font-size: 13px;
    width: 60px;
    cursor: not-allowed;
}

.downloadButton:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: #4E253A;
}

.downloadingButton {
    position: relative;
    overflow: hidden;
    transform: translateZ(0);
    display: grid;
    place-items: center;
    cursor: wait;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background-color: #AA346F;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 6px;
    padding-bottom: 6px;
    color: #ffffff;
    font-size: 13px;
    width: 60px;
  }
  
  @keyframes loading {
    0% {
      transform: translateX(25px);
    }
    100% {
      transform: translateX(-20px);
    }
  }
  
  .downloadingButton > span {
    position: relative;
    z-index: 2;
  }
  
  .downloadingButton::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: -100%;
    width: 300%;
    height: 100%;
    background: #AA346F
      repeating-linear-gradient(
      60deg,
        transparent,
        transparent 10px,
        #d25191 10px,
        #d25191 20px
      );
    
    animation: loading 1s infinite linear;
  }

.reportDiv {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #f8eff5;
}

.reportDivHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #AA346F;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    font-weight: bolder;
    font-size: 16px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.searchBar {
    position: relative;
    width: 96%;
    height: 36px;
    border-radius: 50px;
    font-size: 14px;
    box-shadow: 0px 0px 4px #ccc;
    font-style: italic;
    background-color: #ffffff;
    padding: 10px 4px 10px 4px;
}

.searchIcon {
    height: 32px;
    width: 32px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-decoration: none;
    color: #4E253A;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5px;
    margin-left: auto;
}

.topDiv {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-top: 0px;
    padding: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.cancelButWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    padding: 4px;
}

.tableDiv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.responsive {
    overflow-x: auto;
    width: 100%;
    margin-bottom: 10px;
    /* border-bottom: 1px solid #cfd0d3; */
    padding: 10px;
}


.responsive1 {
    overflow-x: auto;
    width: 100%;
    /* border-bottom: 1px solid #cfd0d3; */
    padding-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.headingContainer1 {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px !important;
    margin-right: 10px !important;
    background-color: #aa346f;
    color: #ffffff;
    border-radius: 1px;
}

.iconsStyleSort {
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
}

.fullNameHeader {
    max-width: 8vw;
    min-width: 8vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    color: #ffffff;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 1px;
}

.dobHeader {
    max-width: 5vw;
    min-width: 5vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    color: #ffffff;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 1px;
}

.medicareIDHeader {
    max-width: 6vw;
    min-width: 6vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    color: #ffffff;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 1px;
}

.programHeader {
    max-width: 5vw;
    min-width: 5vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    color: #ffffff;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 1px;
}

.dxCodeHeader {
    max-width: 6vw;
    min-width: 6vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    color: #ffffff;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 1px;
}

.cptCodeHeader {
    max-width: 12vw;
    min-width: 12vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    color: #ffffff;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 1px;
}

.usageHeader {
    max-width: 4vw;
    min-width: 4vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    color: #ffffff;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 1px;
}

.timeSpentHeader {
    max-width: 5vw;
    min-width: 5vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    color: #ffffff;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 1px;
}

.selectedHeader {
    max-width: 3.6vw;
    min-width: 3.6vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    color: #ffffff;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 1px;
}

.circularProgress {
    display: flex;
    justify-content: center;
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 16px !important;
}

.circularProgress svg {
    margin: 0 auto;
    display: block;
    color: #4E253A;
}

.reportsTableBody {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.reportsTableBody::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.tableCellContainer {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px !important;
    margin-right: 10px !important;
    border-radius: 1px;
    background-color: #f7e3e6;
    min-height: 2vh;
}

.tableCellContainerCheckbox {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    margin-left: 10px !important;
    margin-right: 10px !important;
    border-radius: 1px;
    background-color: #f7e3e6;
    height: 38px;
}

.tableCellContainerUsageFulFilled {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px !important;
    margin-right: 10px !important;
    border-radius: 1px;
    background-color: #d0ffdd;
    min-height: 2vh;
    color: #AA346F;
}

.tableCellContainerUsageUnfulFilled {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px !important;
    margin-right: 10px !important;
    border-radius: 1px;
    background-color: #ffd8df;
    min-height: 2vh;
    color: #AA346F;
}

.tableCellContainerProgramRPM {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px !important;
    margin-right: 10px !important;
    border-radius: 1px;
    background-color: #f7e3e6;
    min-height: 2vh;
    color: #AA346F;
}

.tableCellContainerProgramCCM {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px !important;
    margin-right: 10px !important;
    border-radius: 1px;
    background-color: #f7e3e6;
    min-height: 2vh;
    color: #F14C00;
}

.tableCellContents {
    background-color: #f7e3e6;
    /* max-width: 50px;
      min-width: 50px; */
    text-align: center;
    font-family: 'GTWalsheimProRegular';
    padding: 10px;
    min-height: 20px;
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.tableCellContentsFullName {
    max-width: 8vw;
    min-width: 8vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    margin-bottom: 10px;
    border-radius: 1px;
}

.tableCellContentsDOB {
    max-width: 5vw;
    min-width: 5vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    margin-bottom: 10px;
    border-radius: 1px;
}

.tableCellContentsMId {
    max-width: 6vw;
    min-width: 6vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    margin-bottom: 10px;
    border-radius: 1px;
}

.tableCellContentsProgram {
    max-width: 5vw;
    min-width: 5vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    margin-bottom: 10px;
    border-radius: 1px;
}

.tableCellContentsDXCode {
    max-width: 6vw;
    min-width: 6vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    margin-bottom: 10px;
    border-radius: 1px;
}

.tableCellContentsSelected {
    max-width: 3.6vw;
    min-width: 3.6vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    margin-bottom: 10px;
    border-radius: 1px;
    height: 38px;
}

.tableCellContentsUsage {
    max-width: 4vw;
    min-width: 4vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    margin-bottom: 10px;
    border-radius: 1px;
}

.tableCellContentsTimeSpent {
    max-width: 5vw;
    min-width: 5vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    margin-bottom: 10px;
    border-radius: 1px;
}

.tableCellContentsCPTCode {
    max-width: 12vw;
    min-width: 12vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    margin-bottom: 10px;
    border-radius: 1px;
}

.rpmCodesContainer {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
}

.ccmCodesContainer {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
}

.rpmUnfulfilled {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #9c9599;
    color: white;
    font-family: GTWalsheimProRegular;
    font-size: 0.8em;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 2px;
    border-radius: 10px;
}

.rpmFulFilled {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #aa346f;
    color: white;
    font-family: GTWalsheimProRegular;
    font-size: 0.8em;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 2px;
    border-radius: 10px;
}

.ccmFulFilled {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #F14C00;
    color: white;
    font-family: GTWalsheimProRegular;
    font-size: 0.8em;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 2px;
    border-radius: 10px;
}

.ccmUnfulfilled {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #9c9599;
    color: white;
    font-family: GTWalsheimProRegular;
    font-size: 0.8em;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 2px;
    border-radius: 10px;
}

.filterToggle {
    display: flex;
    width: 100%;

}

.applyButtonDisabled {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background-color: #9c9599;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 6px;
    padding-bottom: 6px;
    color: #ffffff;
    font-size: 13px;
    width: 60px;
    cursor: not-allowed;
}

.billingButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    width: 100px;
    height: 30px;
    font-family: GTWalsheimProRegular;
    font-size: 13px;
    border-radius: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    color: white;
    background-color: #AA346F;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    letter-spacing: 0.5px;
}

.billingButton:hover {
    cursor: pointer;
    background-color: #4E253A;
}

.billingButtonDisabled {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    width: 100px;
    height: 30px;
    font-family: GTWalsheimProRegular;
    font-size: 13px;
    border-radius: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    color: white;
    background-color: grey;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    letter-spacing: 0.5px;
    cursor: not-allowed;
}

.confirmatiomBannerDiv {
    position: fixed;
    /* Fixes the div relative to the viewport */
    left: 50%;
    /* Centers the div horizontally */
    bottom: 20px;
    /* Aligns the div at the bottom of the screen */
    transform: translateX(-50%);
    /* Corrects the horizontal positioning by shifting the div back by 50% of its width */
    /* Optional: you can also add padding, background, etc. */
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    padding-left: 20px;
    border-radius: 30px;
    border: 2.828px solid #FFDEEE;
    background: #FFF2F9;
    box-shadow: 0px 2.828px 6.787px 2.828px rgba(0, 0, 0, 0.10);
    align-items: center;
    justify-content: center;
}

.confirmationText {
    color: #AA346F;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.confirmationButtons {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    margin-left: 10px;
    letter-spacing: 0.5px;
}

.cancelButton {
    display: flex;
    color: #AA346F;
    font-weight: 600;
    font-size: 14px;
    border-radius: 100px 0px 0px 100px;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 3px;
    margin-bottom: 4px;
    background: #FFFAFC;
    border: 0.5px solid white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.cancelButton:hover {
    cursor: pointer;
    background-color: #f7e3e6;
}

.cancelButton2 {
    display: flex;
    color: #AA346F;
    font-weight: 600;
    font-size: 14px;
    border-radius: 100px 100px 100px 100px;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 3px;
    margin-bottom: 4px;
    margin-right: 3px;
    background: #FFFAFC;
    border: 0.5px solid white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.cancelButton2:hover {
    cursor: pointer;
    background-color: #f7e3e6;
}

.submitButton {
    display: flex;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    border-radius: 0px 100px 100px 0px;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 3px;
    margin-bottom: 4px;
    background: #AA346F;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-right: 3px;
    border: 0.5px solid white;
}

.submitButton:hover {
    cursor: pointer;
    background-color: #4E253A;
}
/* MEDIA QUERIES */
@media screen and (min-width: 600px) {
  /* For Tablets */
  .lfGraphCard {
    width: 100;
  }
}

@media screen and (min-width: 768px) {
  /* For Laptops */
  .lfGraphCard {
    width: 738px;
  }
}

@media screen and (min-width: 992px) {
  /* //For Large Laptops */
  .lfGraphCard {
    width: 1200px;
  }
}

@media screen and (min-width: 1280px) {
  /* //For Big TV's (HD Screens)  */
  .lfGraphCard {
    width: 1300px;
  }
}

@media screen and (min-width: 1920px) {
  /* //For Projectors or Higher Resolution Screens (Full HD) */
  .lfGraphCard {
    width: 1740px;
  }
}

@media screen and (min-width: 2500px) {
  /* //For Projectors or Higher Resolution Screens (Full HD) */
  .lfGraphCard {
    width: 2340px;
  }
}
@media screen and (min-width: 3840px) {
  /* //For 4K Displays (Ultra HD) */
  .lfGraphCard {
    width: 3810px;
  }
}
.headerDiv {
  display: flex;
  color: #AA346F;
  font-weight: 700;
  margin-bottom: 15px;
  margin-left:7px;
}
.cardTitle {
  font-size: 20px;
  font-family: GTWalsheimProRegular;
}

.timestampLungFunction {
  margin-left: auto;
  font-family: GTWalsheimProRegular;
  font-size: 15px;
  display: flex;
}

.lfGraphCard {
  border: none;
  background-color: #faf9f9;
  padding: 10px;
  border-radius: 10px;
  color: #495057;
  box-shadow: 0px 1px 7px 0px #888888;
  margin-bottom: 30px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
}

.lfGraphContent {
  text-align: center;
  width: 1200px;
}

.graphStyles {
  background-color: blue;
}
.lungRateInformation{
  display: flex;

}
.wrapper {
   padding: 0px 20px;
}

.headerWrapper {
   padding-bottom: 20px;
}

.headerDiv {
   margin-left: 20px;
   margin-right: 20px;
}

.headerStyles {
   height: 100%;
   max-width: 100%;
   background-color: #F9F2F2;
   float: left;

}

.headerMenuBar {
   background-color: #F9F4FA;
   border-radius: 0px 0px 20px 20px;
}

.headerBarWrapper {
   display: inline-flex;
   padding-left: 20px;
   height: 10px;
}

.headerBarWrapper div {
   display: flex;
}

.headerMenuItem {
   padding-right: 25px;
   font-family: GTWalsheimProRegular;
   font-size: 11px;
   color: #686666;
}

.contactWrapper {
   background-color: #F5D1DE;
   border-radius: 10px 10px 10px 10px;
   -webkit-box-shadow: 0px 3px 3px 0px rgba(176, 176, 176, 0.86);
   box-shadow: 0px 3px 3px 0px rgba(176, 176, 176, 0.86);
   margin-bottom: 20px;
}

.collapseWrapper {
   background-color: #F9F4FA;
   border-radius: 0px 0px 10px 10px;
   padding-top: 5px;
   padding-bottom: 10px;
}

.contactTitle {
   margin-left: 12px;
   font-family: GTWalsheimProRegular;
   font-size: 15px;
   font-weight: 600;
   color: #4E253A;
   padding-top: 5px;
   padding-bottom: 5px;
}

.contactBody {
   background-color: #F9F4FA;
   border-radius: 0px 0px 10px 10px;
   padding-top: 10px;
   padding-left: 20px;
   padding-bottom: 20px;
   display: flex;
   flex-direction: row;
   column-gap: 5px;
   align-items: center;
   justify-content: left;
}

.phoneDiv {
   display: inline-flex;
   align-items: center;
   flex-wrap: wrap;
   padding-right: 80px;
}

.locationDiv {
   align-items: center;
   flex-wrap: wrap;
   display: inline-flex;
   grid-column: 1 / 3;
}

.phoneNum {
   color: #4E253A;
   font-family: GTWalsheimProRegular;
   font-size: 14px;
   font-weight: 600;
   display: inline-flex;
   padding-top: 10px;
}

.videoButtonsWrapper {
   background-color: #F9F4FA;
}

.videoButtonSelected {
   color: #4E253A;
   font-family: GTWalsheimProRegular;
   font-size: 13px;
   font-weight: 600;
   background-color: #F5D1DE;
   padding: 7px 10px;
   margin: 0px 8px;
   width: 125px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-top-right-radius: 10px;
   border-top-left-radius: 10px;
   border-bottom-left-radius: 2px;
   border-bottom-right-radius: 2px;
}

.videoButton {
   color: #B09BA6;
   font-family: "GTWalsheimProRegular";
   font-size: 13px;
   font-weight: 600;
   background-color: #F9E3EB;
   padding: 7px 10px;
   margin: 0px 8px;
   width: 125px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-top-right-radius: 10px;
   border-top-left-radius: 10px;
   border-bottom-left-radius: 2px;
   border-bottom-right-radius: 2px;
}

.videoResponsive {
   border: 2px solid #d695b7;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.videoResponsive .iframe {
   position: absolute;
}

.videoWrapper {
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: 1%;
}

.videoTitle {
   color: #7C596A;
   font-family: GTWalsheimProRegular;
   font-size: 25px;
   font-weight: 1000;
   margin: 8px 14px;
}

.accContainer {
   align-items: center;
   padding-bottom: 20px;
}

.displayContainer {
   background-color: #fff;
   /* background-color: red; */
   /* border-radius: 10px; */
   padding-left: 3px;
   padding-right: 3px;
   padding-bottom: 5px;
   padding-top: 5px;
   /* margin-left: 10px;
    margin-right: 10px; */
   align-items: center;
   height: 55px;
   display: flex;
   box-shadow: 0px 0px 8.107px 0px rgba(0, 0, 0, 0.25);
}

.pageHeader {
   margin-left: 20px;
   display: flex !important;
   flex-direction: row;
   column-gap: 10px;
   font-family: 'GTWalsheimProRegular';
   font-size: 20px;
   font-weight: 900;
   align-items: center;
   justify-content: center;
   color: #4E253A;
}

.userGreetings {
   margin-left: auto;
   margin-right: 20px;
   font-family: "GTWalsheimProRegular";
   font-size: 18px;
}

.userInfoDiv {
   display: flex;
   flex-direction: row !important;
   column-gap: 10px !important;
   border-radius: 50px;
   border: 1.5px solid #7C596A;
   padding-left: 3px;
   padding-top: 3px;
   padding-bottom: 3px;
   padding-right: 5px;
   background-color: #FFFBFD;
   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
   min-width: 140px;
   align-items: center;
   justify-content: left;
   font-size: 16px;
   font-family: "GTWalsheimProRegular";
}

.avatar2 {
   height: 26px !important;
   width: 26px !important;
   border: 1.5px solid #7C596A;
}
.containerDiv {
   /* width: 100%; */
   margin-left: 20px;
   margin-right: 20px;
   margin-top: 20px;
   margin-bottom: 20px;
   /* padding: 10px; */
   background-color: #ffffff;
   border-radius: 10px;
}

.iconDiv {
   margin-left: 20px;
   margin-right: 20px;
   margin-top: 20px;
   margin-bottom: 20px;
   background-color: #FFE0F0;
   text-align: left;
   display: flex;
   flex-direction: row;
   column-gap: 10px;
   border-top-left-radius: 30px;
   border-bottom-left-radius: 30px;
   border-top-right-radius: 10px;
   border-bottom-right-radius: 10px;
   vertical-align: middle;
}

.iconDivSvg {
   cursor: pointer;
   width: 24px;
   height: 24px;
   color: #FFFFFF;
   background-color: #AA346F;
   padding: 8px;
   border-radius: 30px;
}

.iconEditAll svg {
   cursor: pointer;
   width: 20px;
   height: 20px;
   color: #FFFFFF;
   background-color: #4E253A;
   padding: 6px;
   border-radius: 30px;
   margin: 0px 0px 0px 0px;
}

.iconEdit svg {
   cursor: pointer;
   width: 20px;
   height: 20px;
   color: #4E253A;
   background-color: #FFFFFF;
   padding: 6px;
   border-radius: 30px;
   box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
   /* margin-left: 20px; */
}

.iconEdit svg:hover {
   color: #FFFFFF;
   background-color: #4E253A;
}

.iconEditDisabled {
   cursor: none;
   opacity: 0;
   margin: 0px 2px;
   display: none;
}

.responsive {
   margin: 15px auto;
   max-width: 720px;
   min-width: 720px;
   padding: 3px 6px;
   background-color: #fff;
   border-radius: 10px;
   box-shadow: 0px 0px 11px 6px rgba(0, 0, 0, 0.1);
   display: flex;
   flex-wrap: wrap;
}

.cardStyle {
   background-color: #fff;
   table-layout: auto;
   width: 94%;
   margin: 10px auto 0px 5px;
   table-layout: fixed;
   border-collapse: collapse;
}

.w {
   width: 180px;
}

.w1 {
   width: 120px;
}

.w2 {
   width: 50px;
}

.header {
   color: #4E253A;
   font-family: GTWalsheimProRegular;
   font-size: 14px;
   font-weight: lighter;
   background-color: #F6F6F6;
   width: 100%;
}

.headerText {
   border-collapse: collapse;
   padding: 10px;
   text-align: center;
}

.headerIcon {
   padding: 2px 0px;
   background-color: #fff;
}

.valuesTable {
   background-color: #fff;
   table-layout: auto;
   width: 100%;
   margin: 6px;
   table-layout: auto;
   border-collapse: collapse;
}

.iconTd {
   border: solid 2px #fff;
   text-align: center;
   padding-top: 2px;
   padding-bottom: 2px;
   padding-left: 10px;
   padding-right: 0px;
}

.rowWrapper {
   background-color: #fff;
   display: flex;
   font-family: GTWalsheimProRegular;
   color: #4E253A;
   font-size: 15px;
   font-weight: bold;
   height: 40px;
   width: 100%;
}

.rowWrapperDisabled {
   background-color: #fff;
   display: flex;
   font-family: GTWalsheimProRegular;
   color: #4E253A;
   font-size: 15px;
   font-weight: bold;
   height: 40px;
   width: 100%;
   opacity: 0.5;
}

.param {
   background-color: #F6F6F6;
   min-width: 180px;
   max-width: 180px;
   text-align: left;
   border-collapse: collapse;
   padding: 10px;
   border: solid 2px #fff;
}

.paramValue {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   background-color: #F6F6F6;
   min-width: 115px;
   max-width: 115px;
   border-collapse: collapse;
   padding: 10px;
   border: solid 2px #fff;
   text-align: center;
}

.lowSign {
   /* background-color: #F7F8CC;
    color:#DE9122; */
   background-color: #FDE1E1;
   color: #F06868;
   margin-left: 15px;
   width: 23px;
   height: 23px;
}

.inputLowValue {
   /* background-color: #F7F8CC;
    color:#DE9122; */
   background-color: #FDE1E1;
   color: #F06868;
   border: none;
   width: 34px;
   height: 21px;
   text-align: center;
}

.inputLowValueFocused {
   /* background-color: #F7F8CC;
    border:solid 1px #DE9122; */
   background-color: #FDE1E1;
   border: solid 1px #F06868;
   color: #000;
   width: 33px;
   height: 20px;
   text-align: center;
}

.baselineWrapper {
   background-color: #EfFFF7;
   text-align: center;
   min-width: 115px;
   max-width: 115px;
   border-collapse: collapse;
   padding: 7px 10px;
   border: solid 2px #fff;
   text-align: center;
}

.inputValue {
   color: #06784F;
   border: none;
   width: 34px;
   text-align: center;
   height: 22px;
   background-color: #EfFFF7;
}

.inputValueFocused {
   color: #06784F;
   border: solid 1px #06784F;
   width: 115px;
   text-align: center;
   height: 21px;
   background-color: #EfFFF7;
}

.highSign {
   background-color: #FDE1E1;
   color: #F06868;
   margin-right: 15px;
   width: 23px;
   height: 23px;
}

.inputHighValue {
   background-color: #FDE1E1;
   color: #F06868;
   border: none;
   width: 34px;
   height: 21px;
   text-align: center;
}

.inputHighValueFocused {
   background-color: #FDE1E1;
   border: solid 1px #F06868;
   color: #000;
   width: 33px;
   height: 20px;
   text-align: center;
}

.tootlTipHeading {
   font-family: "GTWalsheimProRegular";
   font-size: 11px;
   /* font-weight: bold; */
   font-style: normal;
   line-height: normal !important;
}

.editOptions {
   display: flex;
   flex-direction: row;
   column-gap: 10px;
}

.editOptions2 {
   display: flex;
   flex-direction: row;
   column-gap: 5px;
   margin-left: 10px;
}

.editOptions2 svg {
   cursor: pointer;
   width: 20px;
   height: 20px;
   color: #FFFFFF;
   background-color: #4E253A;
   padding: 6px;
   border-radius: 30px;
   margin: 0px 0px 0px 0px;
}

.promptDiv {
   display: flex;
   flex-direction: column;
   width: 100%;
   align-items: center;
   justify-content: center;
   padding-bottom: 20px;
   margin-top: -5px;
}

.newBaselinePrompt {
   display: flex;
   flex-direction: row;
   width: fit-content;
   padding: 10px;
   background-color: #FFBCC2;
   margin-top: -9px;
   box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
   border-bottom-left-radius: 10px;
   border-bottom-right-radius: 10px;
   font-size: 14px;
   align-items: center;
   justify-content: center;
}

.promptMessage {
   display: flex;
   flex-direction: row;
}

.confMessageDiv {
   display: flex;
   flex-direction: row;
}

.confMessage {
   display: flex;
   flex-direction: row;
}

.yesButton {
   display: flex;
   flex-direction: row;
   column-gap: 3px;
   padding-top: 1px;
   padding-right: 6px;
   padding-left: 6px;
   margin-left: 10px;
   margin-right: 10px;
   background-color: rgb(132, 212, 116);
   border-radius: 10px;
   align-items: center;
   justify-content: center;
}

.yesButton:hover {
   cursor: pointer;
}

.noButton {
   display: flex;
   flex-direction: row;
   column-gap: 3px;
   padding-top: 1px;
   padding-right: 6px;
   padding-left: 6px;
   margin-left: 10px;
   margin-right: 10px;
   background-color: rgb(252, 93, 86);
   border-radius: 10px;
   align-items: center;
   justify-content: center;
}

.noButton:hover {
   cursor: pointer;
}

.rootDiv {
   display: flex;
   flex-direction: column;
   overflow: hidden !important;
}

.circularProgressDiv {
   display: flex;
   justify-content: center;
   height: 100%;
   align-items: center;
   padding-top: 40px;
   padding-bottom: 40px;
}

.filtersDivTextActive {
   font-family: 'GTWalsheimProRegular' !important;
   font-size: 12px !important;
   padding-bottom: 5px;
   column-gap: 5px;
   color: #4E253A;
   font-weight: bold;
   height: 30px;
   width: 150px;
   margin-right: 0px !important;
   margin-left: 0px !important;
   padding-top: 5px;
   padding-right: 5px;
   padding-left: 10px;
}

.filtersDivTextActive:hover {
   background-color: #dfdfdf;
}

.filtersDivTextSelectedActive {
   font-family: 'GTWalsheimProRegular' !important;
   font-size: 12px !important;
   column-gap: 5px;
   color: #4E253A;
   font-weight: bold;
   background-color: #EEFFF7;
   width: 150px;
   height: 30px;
   margin-right: 0px !important;
   margin-left: 0px !important;
   padding-bottom: 5px;
   padding-top: 5px;
   padding-right: 5px;
   padding-left: 10px;
}

.wrapper {
   display: flex;
   height: fit-content !important;
   width: fit-content !important;
   margin-top: -10px;
   padding: 0px;
}

.filterBackdrop {
   display: flex;
   height: fit-content !important;
   width: fit-content !important;
   padding: 0px;
   margin-left: 120px;
}

.filterNamediv {
   display: flex;
   flex-direction: row;
   padding-left: 10px;
   padding-top: 8px;
   font-size: 20px;
}
.loginWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  /* background: linear-gradient(to right, #f26539, #d36d80, #b76987,#943c73 ); */
  align-items: center;
  background-color: #FFF5FA;
}


.loginContentCard {
  /* background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(5px); */
  background-image: url(`../../../../Assets/Images/login-bg14.png);
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  /* Resize the background image to cover the entire container */
  font-family: "GTWalsheimProRegular";
  border-radius: 20px !important;
  text-align: center;
  align-items: center;
  display: flex;
  width: 60%;
  height: 70vh;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.qwe {
  /* background-color: bisque; */
  margin-top: 100%;
  margin-right: 70%;
  margin-bottom: 10%;
  padding: 10px;

}


.logoImage {
  max-width: 100%;
  max-height: 100%;
}

.loginFormContainer {
  /* background-color: rgb(211,109,128,); */
  /* background-color: rgba(255, 255, 255, 0.05); */
  backdrop-filter: blur(100px);
  border-radius: 20px;
  overflow-y: scroll !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  overflow-x: auto;
  border: 1px solid #AA346F;
  background-color: #FFEFF6;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
}


.loginFormContainer {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.loginFormContainer::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.loginCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  min-width: 30vw;
  /* text-align: center; */
}

.openMessage {
  font-family: "GTWalsheimProRegular";
  font-size: 26px;
  color: #AA346F;
  margin-left: 30px;
  margin-bottom: 20px;
  font-weight: 600;
}

.openMessageSmall {
  font-family: "GTWalsheimProRegular";
  font-size: 15px;
  color: #000000;
  margin-left: 30px;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: 30vw;
  padding-left: 10px;
  padding-right: 30px;

}

.loginContainer1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  min-width: 30vw;
  padding-left: 10px;
  padding-right: 30px;

}

.FormContainer {
  display: flex;
  flex-direction: row;
  column-gap: 160px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
}

.formFieldWrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
}

.labelWrapper {
  margin-top: 5px;
  margin-bottom: 5px;
}

/* Input Element Styles */
.labelDivStyles {
  text-align: left;
  align-items: center;
}

.labelStyles {
  text-transform: none;
  font-size: calc(8px + 0.390625vw);
  font-family: 'GTWalsheimProRegular';
  color: #AA346F;
  margin-top: 10px;
  margin-bottom: 10px;
}

.labelStylesRequired {
  text-transform: none;
  font-family: 'GTWalsheimProRegular';
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: calc(8px + 0.390625vw);
  color: #AA346F;
}

.labelStylesRequired::after {
  content: " *";
  color: #AA346F;
}

.errorMessageStyles {
  text-transform: none;
  font-size: calc(6px + 0.390625vw);
  font-family: "GTWalsheimProRegular";
  /* color: blueviolet; */
  color: red;
  margin-left: 10px;
}

.errorMessageStyles1 {
  text-transform: none;
  font-size: calc(10px + 0.390625vw);
  font-family: "GTWalsheimProRegular";
  /* color: blueviolet; */
  color: red;
  margin-left: 10px;
  text-align: center;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}


.successMessageStyle {
  text-transform: none;
  font-size: 14px;
  font-family: GTWalsheimProRegular;
  /* color: blueviolet; */
  color: red;
  margin-top: 15px;
  margin-bottom: 15px;
}

.inputStyle {
  min-width: 280px;
  background-color: #ffffff;
  /* color: blueviolet; */
  color: #3e3f40;
  font-weight: 200;
  border-style: none;
  border-width: 10px;
  border-radius: 50px;
  padding: 0.4vw;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  /* caret-color: #7400b8; */
  caret-color: #000000;
  font-family: "GTWalsheimProRegular";
  font-size: 15px;
  border: 1px solid #ced4da;
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.inputStyle:focus {
  border-radius: 17px;
  background-color: #ffffff;
  border-color: #793f98;
}

.inputStyle::placeholder {
  color: #8c8490;
  font-size: 1.2em;
  font-style: italic;
  font-size: 15px;
}

.inputDropDown {
  font-family: "GTWalsheimProRegular";
  font-size: 15px;
  color: #3e3f40;
  font-weight: 200;
  border-radius: 50px;
}

/* Button Styles */

.buttonDiv {
  display: flex;
  margin-top: 40px;
  margin-bottom: 0px;
  margin-left: 30px;
  color: black;
  text-transform: none;
  font-size: 16px;
  font-family: "GTWalsheimProRegular";
  text-decoration: none;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.buttonDiv1 {
  display: flex;
  margin-top: 0px;
  margin-bottom: 20px;
  margin-left: 30px;
  color: black;
  text-transform: none;
  font-size: 16px;
  font-family: "GTWalsheimProRegular";
  text-decoration: none;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.buttonDiv3 {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  color: black;
  text-transform: none;
  font-size: 16px;
  font-family: "GTWalsheimProRegular";
  text-decoration: none;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.loginButton {
  width: 300px;
  background-color: #AA346F;
  color: white;
  border-style: none;
  border-width: 5px;
  border-radius: 50px;
  padding: 6px 2px 6px 2px;
  box-shadow: 2px 5px 9px -3px #725f7f;
  outline: none;
  font-family: GTWalsheimProRegular;
  font-size: calc(10px + 0.390625vw);
  cursor: 'pointer';
}

.loginButton:hover {
  cursor: pointer;
  background-color: #F14C00;
}

.signUpButton {
  min-width: 120px;
  background-color: #322F4B;
  color: white;
  /* font-weight: 200; */
  border-style: none;
  border-width: 5px;
  border-radius: 5px;
  padding: 10px 5px 10px 5px;
  box-shadow: 2px 5px 9px -3px #725f7f;
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: calc(12px + 0.390625vw);
  margin-left: 0px;
  margin-right: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.infoButton {
  position: fixed;
  color: #4E253A;
  margin-bottom: 2px;
  margin-left: 3px;
  height: 14px !important;
  width: 14px !important;
}

.navlinkStyle {
  color: #AA346F;
  text-transform: none;
  font-size: calc(10px + 0.390625vw);
  font-family: "GTWalsheimProRegular";
  text-decoration: none;
}

.infoText {
  font-family: "GTWalsheimProRegular";
}

.MuiInputBase-input {
  color: white !important;
}

.consentDiv {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  width: 100%;
  align-items: center;
  justify-content: left;
  margin-top: 30px;
  margin-left: 250px;
}

.consentDiv1 {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  width: 100%;
  align-items: center;
  justify-content: left;
  margin-top: 5px;
  margin-left: 250px;
}


.checkBoxLabel {
  display: flex;
  font-size: 14px;
  font-family: "GTWalsheimProRegular";
}
.accContainer {
    align-items: center;
    position: relative;
    height: 100vh;
}

.selectedMLDiv {
    display: flex;
    flex-direction: row;
    width: 96%;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    justify-content: space-between;
    margin: 20px;
    background-color: #F1D4D4;
    padding: 10px;
}

.MLIdDiv {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border: #4E253A 1px solid;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
    font-family: "GTWalsheimProRegular";
    color: #ffffff;
    background-color: #4E253A;
}

.unpairButton {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: 10px;
    font-size: 20px;
    font-family: "GTWalsheimProRegular";
    background-color: #F14C00;
    color: #ffffff;
}

.unpairButton:hover {
    cursor: pointer;
}

.footerDiv {
    position: absolute;
    bottom: 0%;
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}

.footerButtonsDiv {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    width: 100%;
    padding-top: 10px;
    background-color: #ffffff;
}

.warningDiv {
    align-content: center;
    justify-content: center;
    justify-items: center;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    color: #ffffff;
    background-color: #f59a9a;
    width: fit-content;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.footerButtons {
    align-content: center;
    justify-content: center;
    justify-items: center;
    display: flex;
    flex-direction: row;
    column-gap: 60px;
}

.warningIcon {
    display: flex;
    background-color: #FFFFFF;
    color: #F14C00;
    padding: 3px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.pairButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    background-color: #F14C00;
    color: #ffffff;
    font-size: 25px;
    font-family: "GTWalsheimProRegular";
    padding: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    column-gap: 5px;
}

.pairButton:hover {
    cursor: pointer;
}

.enterManuallyButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    background-color: #F1D4D4;
    color: #4E253A;
    font-size: 25px;
    font-family: "GTWalsheimProRegular";
    padding: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    column-gap: 5px;
}

.enterManuallyButton:hover {
    cursor: pointer;
}

.paperDiv {
    margin: 20px;
    min-height: 57vh;
    max-height: 73vh;
    border-radius: 20px;
    padding: 20px;
}

.patientInfoDiv {
    border-radius: 5px;
    padding: 20px;
    background-color: #fdf8fb !important;
    margin-left: 2px;
}

.patientInfoDiv2 {
    border-radius: 5px;
    padding: 0px;
    background-color: #fdf8fb !important;
    margin-left: 2px;
}

.profileSummary {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    background-color: #fdf8fb;
    border-radius: 20px;
    padding: 20px;
    padding-bottom: 5px;
}

.pfpDiv {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}

.nameAndIdDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.patientName {
    margin-top: -20px;
    width: 100%;
}

.patientId {
    margin-top: -20px;
    font-size: 22px;
    font-weight: 500;
}

.otherDetails {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
}

.otherDetails1 {
    display: flex;
    flex-direction: row;
    column-gap: 180px;
}

.otherDetails2 {
    display: flex;
    flex-direction: row;
    column-gap: 295px;
}

.infoLabel {
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
    font-weight: bold;
    color: rgb(153, 148, 148);
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}

.infoContent {
    color: #000;
}

.patientInfo {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.patientInfoTitle {
    font-family: "GTWalsheimProRegular";
    font-size: 22px;
    color: #4E253A;
    font-weight: bold;
}

.patientInfoItem {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-left: 20px;
    color: #000;
    border-radius: 10px;
    background-color: #ffffff;
    padding-top: 4px;
    padding-bottom: 4px;
    width: fit-content;
    padding-left: 8px;
    padding-right: 8px;
}

.patientInfoLabel {
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
    font-weight: bold;
}

.patientInfoContent {
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
    font-weight: normal;
}

.modal {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    position: relative;
    /* margin-left: 10%;
            margin-right: 90%;
            top: 50; */
}

.paperUnassignML {
    background-color: #fff;
    outline: none;
    border-radius: 20px;
    padding: 10px;
    align-items: center;
    width: 400px;
    /* height: 350px; */
    overflow-x: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.menuTitle {
    background-color: #ffff;
    display: flex;
    /* border-bottom: 1px solid gray; */
    text-align: center;
    width: 100%;
    align-items: center;
    font-size: 20px;
    font-family: "GTWalsheimProRegular";
}

.titleText {
    font-style: "GTWalsheimProRegular";
    font-weight: bold;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 28px;
    padding: 10px;
    color: #4E253A;
}

.patientListDiv {
    width: fit-content;
    padding: 10px;
    font-style: "GTWalsheimProRegular";
    font-size: 18px;
}
.responsive {
  margin-left: 10px;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  margin-bottom: 10px;
  background-color: #fbf4f7;
}

.rootDiv {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
}

.containerDiv {
  /* width: 100%; */
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 5px;
  /* padding: 10px; */
  background-color: #ffffff;
  /* background-image: linear-gradient(to right, #5B5687, #E7E6ED); */
  /* background-image: linear-gradient(to right, #D4D3E1, #EEEEF4); */
  /* background-image: linear-gradient(to right, #C8C7D7, #EEEEF4); */
  border-radius: 10px;
}

.datePickerDiv {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: #FFE0F0;
  text-align: left;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  vertical-align: middle;
}

.datePickerDiv svg {
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: #FFFFFF;
  background-color: #AA346F;
  padding: 8px;
  border-radius: 30px;
}

.noData {
  position: relative;
  clear: both;
  display: inline-block;
  white-space: nowrap;
  color: #000000;
  font-family: GTWalsheimProRegular;
  font-size: 22px;
  font-weight: 700;
  padding-top: 20px;
  padding-bottom: 20px;
}

.noDataWrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fbf4f7;
}

.dateInput {
  min-width: 220px;
  max-width: 600px;
  background-color: #FFE0F0;
  text-align: center;
  color: #4E253A;
  font-weight: 200;
  border-style: none;
  border-radius: 10px;
  outline: none;
  caret-color: #4E253A;
  font-family: GTWalsheimProRegular;
  font-size: 17px;
  padding: 10px 10px 6px 10px;
}

.datePickerWrapper {
  /* margin-left: 190px; */
  border-radius: 10px;
  margin-right: auto;
  width: fit-content;
  margin-right: auto;
  overflow: auto;
  visibility: visible;
  color: #4E253A;
  z-index: 2;
  position: absolute;
  border: 2px solid #4E253A;
}

.containerHeader {
  display: flex;
  flex-direction: row;
  background-color: #DED6E2;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  justify-content: space-between;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.headingDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4E253A;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
}

.tableContainer {
  margin-left: auto;
  margin-right: auto;
  overflow-x: auto;
  width: 100%;
}

.exportButton {
  vertical-align: middle !important;
  justify-content: center !important;
  /* display:flex !important; */
  flex-direction: row !important;
  text-align: center !important;
  align-items: center !important;
  color: #fff !important;
  font-size: 13px !important;
  border-radius: 8px !important;
  background-color: #7C596A !important;
  font-family: GTWalsheimProRegular !important;
  text-transform: capitalize !important;
  width: 85px !important;
  margin: 4px 6px 2px 5px !important;
  padding: 3px 0px !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.exportButton svg {
  width: 23px;
  height: 23px;
  padding: 2px 0px 2px 2px;
  border-radius: 3px;
  background-color: inherit;
}

.exportButton:hover {
  background-color: #4E253A !important;
}

.cardStyle {
  /* background-color: #36304a; */
  background-color: #E3E3E4;
  border: 1px solid #ddd;
  /* Add a border to the container if needed */
  border-collapse: collapse;
  /* Collapse the border spacing */
  /* margin: 0px 10px; */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* margin-top: 30px; */
  margin-left: auto;
  margin-right: auto;
  overflow-x: auto;
  width: 100%;
}

.header {
  /* display: flex; */
  align-items: center;
  /* background-color: #36304a; */
  background-color: #F1EFEF;
  padding: 10px;
  color: #000;
  font-family: GTWalsheimProRegular;
  font-size: 16px;
  font-weight: lighter;
}

.mouthLabId {
  min-width: 40px;
  max-width: 40px;
  text-align: center;
  font-family: GTWalsheimProRegular;
  /* padding: 10px 0px 10px 0px; */
  padding: 5px 0px 5px 0px;
  border: 7px solid transparent;
}

.timeStamp {
  min-width: 100px;
  max-width: 100px;
  text-align: center;
  font-family: GTWalsheimProRegular;
  padding-top: 5px;
  padding-bottom: 5px;
}

.deviceId {
  min-width: 85px;
  max-width: 85px;
  text-align: center;
  font-family: GTWalsheimProRegular;
  padding-top: 5px;
  padding-bottom: 5px;
}

.AIDI {
  min-width: 65px;
  max-width: 65px;
  text-align: center;
  font-family: GTWalsheimProRegular;
  padding-top: 5px;
  padding-bottom: 5px;
  /* padding: 10px 0px 10px 0px; */
}

.averageHR {
  min-width: 60px;
  text-align: center;
  font-family: GTWalsheimProRegular;
  padding-top: 5px;
  padding-bottom: 5px;
}

.averageRR {
  min-width: 60px;
  text-align: center;
  font-family: GTWalsheimProRegular;
  padding-top: 5px;
  padding-bottom: 5px;
}

.aidiTableIconWrapper {
  display: flex;
  flex-direction: row;
  background-color: #FFFFFF;
  border-radius: 50px;
  padding: 3px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.tableContainer {
  max-height: '75vh';
  overflow-y: scroll;
  padding: 5px;
}

.tableContainer {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.tableContainer::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.applyButtonDiv {
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  justify-content: right;
  background-color: #FFFFFF;
}

.applyButton {
  vertical-align: middle !important;
  justify-content: center !important;
  /* display:flex !important; */
  flex-direction: row !important;
  text-align: center !important;
  align-items: center !important;
  color: #fff !important;
  font-size: 15px !important;
  border-radius: 8px !important;
  background-color: #aa346f !important;
  font-family: GTWalsheimProRegular !important;
  text-transform: capitalize !important;
  width: 85px !important;
  margin: 4px 6px 2px 5px !important;
  padding: 3px 0px !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.applyButton:hover {
  background-color: #F14C00 !important;
  cursor: pointer;
}

.cancelButton {
  vertical-align: middle !important;
  justify-content: center !important;
  /* display:flex !important; */
  flex-direction: row !important;
  text-align: center !important;
  align-items: center !important;
  color: #aa346f !important;
  font-size: 15px !important;
  border-radius: 8px !important;
  background-color: #ffe0f0 !important;
  font-family: GTWalsheimProRegular !important;
  text-transform: capitalize !important;
  width: 85px !important;
  margin: 4px 6px 2px 5px !important;
  padding: 3px 0px !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.cancelButton:hover {
  background-color: #ffffff !important;
  cursor: pointer;
}
.loginWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: #FFF5FA;
  /* background: linear-gradient(to right, #f26539, #d36d80, #b76987,#943c73 ); */
  align-items: center;
  /* background-image: url(`../../../../Assets/Images/login-bg.png); */
}

.loginContentCard {
  /* background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(5px); */
  background-image: url(`../../../../Assets/Images/login-bg14.png);
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  /* Resize the background image to cover the entire container */
  font-family: "GTWalsheimProRegular";
  border-radius: 20px !important;
  text-align: center;
  align-items: center;
  display: flex;
  width: 80%;
  height: 70vh;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.qwe {
  /* background-color: bisque; */
  margin-top: 100%;
  margin-right: 70%;
  margin-bottom: 10%;
  padding: 10px;

}


.logoImage {
  max-width: 100%;
  max-height: 100%;
}

.loginFormContainer {
  backdrop-filter: blur(100px);
  height: 70vh;
  border-radius: 20px;
  overflow-y: scroll;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 30vw;
  padding-right: 10px;
  overflow-x: auto;
  border: 1px solid #AA346F;
  background-color: #FFEFF6;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.loginFormContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.loginFormContainer {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.loginCard {
  align-items: center;
  margin-top: 30px;
  min-width: 30vw;
  /* text-align: center; */
}

.loginCard1 {
  align-items: center;
  min-width: 30vw;
  /* text-align: center; */
}

.openMessage {
  font-family: "GTWalsheimProRegular";
  font-size: calc(16px + 0.390625vw);
  color: #AA346F;
}

.openMessage1 {
  font-family: "GTWalsheimProRegular";
  font-size: calc(16px + 0.390625vw);
  color: #AA346F;
  margin-top: 20px;
}

.openMessage2 {
  font-family: "GTWalsheimProRegular";
  font-size: calc(14px + 0.390625vw);
  color: #AA346F;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: 100%;
  align-items: center;
  width: 100%;
}

.formFieldWrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5px;
  margin-right: 5px;
  width: 20vw;
}

.formFieldWrapper1 {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 5px;
  margin-right: 5px;
  width: 20vw;
}

.formFieldWrapper2 {
  margin-top: 40px;
  margin-bottom: 30px;
  margin-left: 5px;
  margin-right: 5px;
  width: 20vw;
}

.formFieldWrapper3 {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  width: 20vw;
}

.formFieldWrapper4 {
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  width: 20vw;
}

.labelWrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 60%;
  text-align: left;
}

.labelWrapper1 {
  min-width: 60%;
  text-align: left;
}

.labelWrapper2 {
  margin-top: 5px;
  margin-bottom: 10px;
  min-width: 60%;
  text-align: left;
}

/* Input Element Styles */
.labelDivStyles {
  text-align: left;
  align-items: center;
}

.labelStyles {
  text-transform: none;
  font-size: calc(10px + 0.390625vw);
  font-family: "GTWalsheimProRegular";
  /* color: blueviolet; */
  color: #AA346F;
  margin-top: 10px;
  margin-bottom: 10px;
}

.labelStyles1 {
  text-transform: none;
  font-size: calc(10px + 0.390625vw);
  font-family: "GTWalsheimProRegular";
  /* color: blueviolet; */
  color: #AA346F;
  margin-top: 10px;
  margin-bottom: 20px;
}

.errorMessageStyles {
  text-transform: none;
  font-size: calc(10px + 0.390625vw);
  font-family: "GTWalsheimProRegular";
  /* color: blueviolet; */
  color: red;
  margin-bottom: 10px;
  margin-left: 20px;
}

.errorMessageStyles1 {
  text-transform: none;
  font-size: calc(8px + 0.390625vw);
  font-family: "GTWalsheimProRegular";
  /* color: blueviolet; */
  color: red;
  margin-bottom: 5px;
  margin-top: 5px;
}

.errorMessageStyles2 {
  text-transform: none;
  font-size: calc(8px + 0.390625vw);
  font-family: "GTWalsheimProRegular";
  /* color: blueviolet; */
  color: red;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 20px;
}

.inputStyle {
  min-width: 100%;
  background-color: #fff5fa;
  /* color: blueviolet; */
  color: #3e3f40;
  font-weight: 200;
  border-style: none;
  border-width: 10px;
  border-radius: 50px;
  padding: 0.8vw;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  /* caret-color: #7400b8; */
  caret-color: #000000;
  font-family: "GTWalsheimProRegular";
  font-size: calc(10px + 0.390625vw);
  border: 2px solid #FFD2E9;
}

.inputStyle:focus {
  border-radius: 1.3vw;
  background-color: #ffffff;
  border-color: #793f98;
}

.inputStyle::placeholder {
  color: #8c8490;
  font-size: calc(12px + 0.390625vw);
  ;
  font-style: italic;
}

/* Button Styles */

.buttonDiv {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 15px;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 0.390625vw);
  color: #4E253A;
  width: 100%;
}

.buttonDivConfirm {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 0.390625vw);
  color: #4E253A;
  width: 100%;
}

.buttonDivConfirm1 {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 15px;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 0.390625vw);
  color: #4E253A;
  width: 100%;
}

.buttonDivResend {
  display: flex;
  margin-top: 10px;
  margin-left: 15px;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 0.390625vw);
  color: #4E253A;
  width: 100%;
}

.buttonDivGoBack {
  display: flex;
  margin-top: -20px;
  margin-left: 15px;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 0.390625vw);
  color: #4E253A;
  width: 100%;
}

.footerDiv {
  font-size: calc(10px + 0.390625vw);
  color: #000000;
  text-align: left;
  margin-left: 15px;
}

.ccfooterDiv {
  font-size: small;
  color: #AA346F;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
  text-align: center;
}

.ccfooterDiv1 {
  font-size: small;
  color: #AA346F;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.loginButton {
  /* min-width: 100px; */
  /* background-color: #fe6b59; */
  /* background-color: #81668c; */
  min-width: 100%;
  background-color: #AA346F;
  color: white;
  border-style: none;
  border-width: 5px;
  border-radius: 50px;
  padding: 10px 5px 10px 5px;
  box-shadow: 2px 5px 9px -3px #725f7f;
  outline: none;
  font-family: GTWalsheimProRegular;
  font-size: calc(12px + 0.390625vw);
  cursor: 'pointer';
}


.loginButton:hover {
  cursor: pointer;
  background-color: #F14C00;
}

.signUpButton {
  min-width: 80%;
  /* background-color: #fe6b59; */
  /* background-color: #81668c; */
  background-color: #725f7f;
  color: white;
  /* font-weight: 200; */
  border-style: none;
  border-width: 10px;
  border-radius: 15px;
  padding: 10px 0px 10px 5px;
  box-shadow: 2px 5px 9px -3px #725f7f;
  outline: none;
  font-family: GTWalsheimProRegular;
  font-size: calc(14px + 0.390625vw);
  /* margin-left: 20px; */
  margin-right: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
}


.passwordDisplayActions {
  display: flex;
  align-content: center;
  align-items: center;
  outline: none;
  background-color: transparent;
  border: none;
  padding: 0px 0px;
}


.pwdLabel {
  text-transform: none;
  font-size: calc(8px + 0.390625vw);
  font-family: "GTWalsheimProRegular";
  /* color: blueviolet; */
  color: #4E253A;
  margin-top: 5px;
  /* margin-bottom: 15px; */
}

.pwdLabel1 {
  text-transform: none;
  font-size: calc(8px + 0.390625vw);
  font-family: "GTWalsheimProRegular";
  /* color: blueviolet; */
  color: #4E253A;
  /* margin-bottom: 15px; */
}


.navlinkStyle {
  color: #AA346F;
  text-transform: none;
  font-size: calc(12px + 0.390625vw);
  font-family: "GTWalsheimProRegular";
  text-decoration: none;
}

.navlinkStyle2 {
  color: #AA346F;
  text-transform: none;
  font-size: calc(10px + 0.390625vw);
  font-family: "GTWalsheimProRegular";
  text-decoration: none;
}

.infoButton {
  position: fixed;
  color: #4E253A;
  margin-bottom: 5px;
  margin-left: 3px;
  height: 16px;
  width: 16px;
}

.infoText {
  font-family: "GTWalsheimProRegular";
}

.headerIcon {
  height: 150px;
  width: 150px;
  margin-top: -50px;
  margin-bottom: -40px;
}

.stepInfoDiv {
  color: #AA346F;
  text-align: center;
  font-family: "GTWalsheimProRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  margin-top: 20px;
  margin-bottom: 20px;
}

.stepInfoDiv1 {
  color: #AA346F;
  text-align: center;
  font-family: "GTWalsheimProRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
}
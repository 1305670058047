.submitButton {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  min-width: 46%;
  background-color: #7C596A;
  color: white;
  /* font-weight: 200; */
  border: 1px solid #7C596A;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 18px;
  cursor:pointer;
  margin-bottom: 12px;
}

.submitButton:hover {
  background-color: #4E253A;
  border: 1px solid #4E253A;
}

.submitButtonOnly {
  margin-top: 10px;
  margin-left: 100px;
  margin-right: 10px;
  min-width: 46%;
  background-color: #7C596A;
  color: white;
  /* font-weight: 200; */
  border: 1px solid #7C596A;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 18px;
  cursor:pointer;
  margin-bottom: 12px;
}

.submitButtonOnly:hover {
  background-color: #4E253A;
  border: 1px solid #4E253A;
}

.cancelButton {
  margin-top: 10px;
  margin-right: 10px;
  min-width: 46%;
  background-color: #fff;
  color: #8D919A;
  font-weight: 600; 
  border: 1px solid #8D919A;
  border-radius: 10px;
  padding: 10px;
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 18px;
  cursor:pointer;
  margin-bottom: 12px;
}

.cancelButton:hover {
  color: #4E253A;
  background-color: #F1D4D4;
  border: 1px solid #8D919A;
}

.radioLabelForm {
  font-size: 18px;
  font-family: "GTWalsheimProRegular";
  color: black;
  margin: 10px 0px;
}

.inputCenterDiv {
  align-items: center;
  text-align: center;
}

.wrapperDiv {
  padding: 10px;
}

.container {
  margin-right: auto;
}

.buttonContainer {
  margin-top: 20px;
}

.statusMessage {
  padding: 20px 0px;
}
.smSuccess {
  color: rgb(0, 160, 0);
}
.smError {
  color: rgb(180, 0, 0);
}


  .accordion-details{
    display: flex;
    flex-direction: column;
  }

  .accordion-title {
    color: maroon !important;
    letter-spacing: 0.5px;
  }
  
  .icon{  
    margin-right: 10px
  }

  .note-main-container {
    display: inline-flex;
    padding: 22px 18px 23px 18px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    flex-shrink: 0;
    transition: all 0.3s ease-in-out;
  }

  .note-container {
    border: 0.25px solid #ffc8d9;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    width: 100%;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    position: relative;
    flex-grow: 0;
    box-shadow: 0px 0px 7.935px 0px rgba(0, 0, 0, 0.15);
  }

.note-container.collapsed {
  flex-grow: 0;
  max-height: 6.8rem;
}

.note-container.collapsed .note-content:not(.no-clamp) {
  -webkit-line-clamp: 2;
}

.note-content.no-clamp {
  -webkit-line-clamp: unset;
}

.note-container.expanded {
  flex-grow: 2; 
  max-height: 200rem;
}

.note-container.squeezed {
  flex-grow: 0;
  opacity: 0;
  max-height: 0;
  margin: 0;
  pointer-events: none; 
}

  .note-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #FFF;
    gap: 8px;
    border-radius: 8px;
  }

  .note-header img {
    width: 24px;
    height: 24px;
  }

  .createdBy {
    font-size: 14px;
    font-weight: bolder;
    letter-spacing: 0.7px;
    color: #7C596A;
    font-family: var(--FontFamily);
    margin-left: -5px;
  }

  .createdDateTime {
    display: flex;
    width: 100px;
    height: 13px;
    padding: 1px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 114px;
    background: #FEE7F7;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    font-size: 10px;
    font-family: var(--FontFamily);
    color: #715161;
    letter-spacing: 0.4px;
  }

  .modifiedDateTime {
    /* background: #F6F5F6;
    box-shadow: 0px 2.482px 2.482px 0px rgba(0, 0, 0, 0.25); */
    color: #AA346F;
    padding: 1px;
    font-size: 10px;
    letter-spacing: 0.4px;
    font-style: italic;
  }

  .flexFill {
    flex: 1;
  }

  .tagsBook {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .note-tag {
    display: flex;
    width: 73px;
    height: 24px;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    background: #efc5d9;
    font-size: 13px;
    font-family: var(--FontFamily);
    font-weight: 600;
    color: #aa346f;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }

  .note-tag.CCM {
    background: #7C596A;
    color: #FFFFFF;
  }

  .note-tag.RPM {
    background: #AA346F;
    color: #FFFFFF;
  }
  
  .bookmark-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 32px;
    background-color: #FCE6F2;
    gap: 7.096px;
  }
  
  .bookmark-note-icon {
    color: #AA346F !important;
    width: 20px !important;
    height: 20px !important;
  }

  .bookmark-icon-container button:hover {
    background-color: #f6eff4 !important;
  }

  .note-content {
    font-size: 14px;
    background-color: #FFF6FC;
    width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    transition: all 0.3s ease-in-out;
    position: relative;
  }

  .note-body {
    padding: 16px 12px 10px 12px;
    font-family: var(--FontFamily);
    color: #715161;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .note-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background-color: #FFF6FC;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 8px 12px 7px 12px;
  }

  .expandIcon, .shrinkIcon {
    width: 16px !important;
    height: 16px !important;
    color: #AA346F;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .disabledExpandIcon {
    cursor: not-allowed;
    width: 16px !important;
    height: 16px !important;
    color: #7d787e;
  }

  .duration {
    color: #AA346F;
    font-size: 14px;
    display: flex;
    gap: 4px;
    padding: 0 11px;
    letter-spacing: 0.4px;
  }

  .duration svg {
    width: 16px;
    height: 16px;
  }

  .person-icon {
    height: 18px !important;
    width: 18px !important;
    fill: rgb(124 89 106) !important;
    margin-left: 4px;
  }

  .shrinkIcon {
    width: 16px !important;
    height: 16px !important;
    color: #AA346F;
    transform: scale(1.3);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .shrinkIcon:hover{
    transform: none;
  }

  .expandIcon:hover{
    transform: scale(1.3);
  }
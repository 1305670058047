.containerDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 2px;
    margin-bottom: 15px;
}

.headerDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #FFE4F2;
    padding: 8px;
    font-family: "GTWalsheimProRegular";
    font-size: 15px;
    font-weight: 700;
    color: #4E253A;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.contentDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: #F8EFF5;
}

.fieldName {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-left: -1px;
    font-family: "GTWalsheimProRegular";
    font-size: 14px;
    font-weight: 400;
    color: #4E253A;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.113px;
}

.column1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.8vw;
}

.column2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.8vw;
}

.column3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.8vw;
}

.column4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.8vw;
}

.circlesListItem {
    font-size: 10px;
}

.inputFieldSelcted {
    display: flex;
    height: 33px;
    width: 15vw;
    border: solid #AA346F 0.5px;
    background-color: #F8F8F8;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
    border-radius: 2px;
}

.inputFieldSelctedReq {
    display: flex;
    height: 33px;
    width: 15vw;
    border: solid red 1.5px;
    background-color: #F8F8F8;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
    border-radius: 2px;
}

.inputFieldSelctedUE {
    display: flex;
    height: 33px;
    width: 15vw;
    border: solid #AA346F 0.5px;
    background-color: #FFEEF6;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
    border-radius: 2px;
}

.inputFieldSelcted1 {
    display: flex;
    height: 33px;
    width: 15vw;
    border: solid #AA346F 0.5px;
    background-color: #F5E4FD;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
    border-radius: 2px;
}

.inputFieldSelcted2 {
    display: flex;
    height: 33px;
    width: 8.5vw;
    border: solid #AA346F 0.5px;
    background-color: #F8F8F8;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
    border-radius: 2px;
}

.inputFieldSelctedDOB {
    display: flex;
    height: 36px;
    width: 15.8vw;
    border: solid #AA346F 0.5px;
    background-color: #F8F8F8;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
    border-radius: 2px;
}

.inputFieldSelctedDOBReq {
    display: flex;
    height: 36px;
    width: 15.8vw;
    border: solid red 1.5px;
    background-color: #F8F8F8;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
    border-radius: 2px;
}

.fieldDiv1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px;
}

.fieldDiv2 {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    width: 100%;
    margin: 10px;
}

.dropdown {
    height: 31px !important;
    width: 5.5vw !important;
    border: solid #AA346F 0.5px !important;
    background-color: #F8F8F8 !important;
    padding: 3px 5px !important;
    font-family: "GTWalsheimProRegular" !important;
}

.dropdown2 {
    height: 31px !important;
    width: 15.7vw !important;
    border: solid #AA346F 0.5px !important;
    background-color: #F8F8F8 !important;
    padding: 0px 5px !important;
    font-family: "GTWalsheimProRegular" !important;
}

.dropdownReq2 {
    height: 31px !important;
    width: 15.7vw !important;
    border: solid red 1.5px !important;
    background-color: #F8F8F8 !important;
    padding: 0px 5px !important;
    font-family: "GTWalsheimProRegular" !important;
}

.react-dropdown-select-content .react-dropdown-select-type-multi {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    height: 30px;
    overflow: hidden;
}

.subFieldName {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-left: -1px;
    font-family: "GTWalsheimProRegular";
    font-size: 14px;
    font-weight: 400;
    color: #4E253A;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.113px;
}

.datePickerWrapper {
    /* margin-left: 190px; */
    border-radius: 10px;
    margin-right: auto;
    width: fit-content;
    overflow: auto;
    visibility: visible;
    color: #4E253A;
    z-index: 2;
    position: absolute;
    border: 2px solid #4E253A;
    display: flex;
    flex-direction: column;
    margin-top: 50px !important;
}

.applyButtonDiv {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    padding-bottom: 10px;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    margin-top: -5px;
}

.applyButton {
    vertical-align: middle !important;
    justify-content: center !important;
    /* display:flex !important; */
    flex-direction: row !important;
    text-align: center !important;
    align-items: center !important;
    color: #fff !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    background-color: #aa346f !important;
    font-family: GTWalsheimProRegular !important;
    text-transform: capitalize !important;
    width: 85px !important;
    margin: 4px 6px 2px 5px !important;
    padding: 3px 0px !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.applyButton:hover {
    background-color: #F14C00 !important;
    cursor: pointer;
}

.proPicDiv {
    display: flex;
    flex-direction: column;
}

.picUpDiv {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 15px;
    background-color: #F8F8F8;
    width: 15vw;
    border: solid #A040AB 0.5px !important;
}

.picStyle {
    height: 100%;
    max-width: 100%;
    background-color: #F9F2F2;
    float: left;
}

.warningMsg {
    display: flex;
    flex-direction: column;
    font-size: 10px;
    color: darkgrey;
    align-items: center;
    justify-content: center;
}

.upButtonsDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    margin-top: 20px;
}

.uploadButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FCD3D6;
    border: solid #FFFFFF 0.5px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.uploadButton:hover {
    cursor: pointer;
}

.deleteButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FCD3D6;
    border: solid #FFFFFF 0.5px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;
}

.deleteButton:hover {
    cursor: pointer;
}

.picDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid #FFFFFF 0.5px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.pageButtonsDiv {
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
}

.cancelButton1 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    border: solid #AD7BB3 0.5px;
    color: #AD7BB3;
    font-size: 14px;
    width: 120px;
}

.cancelButton1:hover {
    cursor: pointer;
}

.saveButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #AA346F;
    border-radius: 5px;
    border: solid #AA346F 0.5px;
    color: #FFFFFF;
    font-size: 14px;
    width: 120px;
}

.saveButton:hover {
    cursor: pointer;
    background-color: #4E253A;
}

.dumDiv {
    display: flex;
    flex-direction: column;
    background-color: #F8EFF5;
    height: 100%;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding-bottom: 10px;
}

.fieldInput {
    display: flex;
}

.divFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.addNewDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.addNewContButton {
    display: flex;
    background-color: #A040AB;
    color: #FFFFFF;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: solid #A040AB 0.5px;
    font-size: 14px;
    font-family: "GTWalsheimProRegular";
    margin-bottom: 40px;
}

.addNewContButton:hover {
    cursor: pointer;
}

.consentDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
}

.consentLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-left: -20px;
    font-family: "GTWalsheimProRegular";
    font-size: 12px;
    color: darkgray;
    width: 90%;
}

.consentConent {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 12px;
    background-color: #FFFFFF;
    padding: 10px;
    border: solid #AD7BB3 0.5px;
    width: 90%;
    margin-top: -10px;
    overflow-wrap: break-word;
}

.consentButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    column-gap: 20px;
    padding-bottom: 30px;
}

.consentWarningDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: "GTWalsheimProRegular";
    font-size: 13px;
    font-weight: normal;
    color: red;
    font-style: italic;
    margin-bottom: 40px;
}

.modal {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    position: relative;
    /* margin-left: 10%;
          margin-right: 90%;
          top: 50; */
}

.paper {
    background-color: #fff;
    outline: none;
    border-radius: 20px;
    align-items: center;
    overflow-x: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 340px;
    width: 350px;
}

.menuTitle {
    background-color: #FFF1F8;
    display: flex;
    /* border-bottom: 1px solid gray; */
    text-align: center;
    align-items: center;
    font-size: 20px;
    font-family: "GTWalsheimProRegular";
    font-weight: bold;
    justify-content: center;
    padding: 10px;
    color: #4E253A;
}

.userQuestionMessage {
    font-family: "GTWalsheimProRegular";
    /* margin-left: auto; */
    color: #4E253A;
    font-weight: 600;
    font-size: 17px;
    width: 340px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
}

.mouthLabImgDiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mlImg {
    width: 128px;
    height: 120px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-left: 10px;
}

.submitButtonModal {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    min-width: 40%;
    border-radius: 30px;
    padding: 10px;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 12px;
    background-color: #AA346F;
    border: 1px solid #AA346F;
    color: white;
    font-weight: 600;
}

.submitButtonModal:hover {
    background-color: #F14C00;
    color: white;
    border: 1px solid #F14C00;
}

.cancelButtonModal {
    margin-top: 10px;
    margin-right: 10px;
    min-width: 40%;
    color: #AA346F;
    background-color: #FFFFFF;
    border: 1px solid #AA346F;
    font-weight: 600;
    border-radius: 30px;
    padding: 10px;
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 12px;
}

.cancelButtonModal:hover {
    color: #AA346F;
    background-color: #FFE0F0;
    border: 1px solid #FFE0F0;
}

.cancelButton {
    vertical-align: middle !important;
    justify-content: center !important;
    /* display:flex !important; */
    flex-direction: row !important;
    text-align: center !important;
    align-items: center !important;
    color: #aa346f !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    background-color: #ffe0f0 !important;
    font-family: GTWalsheimProRegular !important;
    text-transform: capitalize !important;
    width: 85px !important;
    margin: 4px 6px 2px 5px !important;
    padding: 3px 0px !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.cancelButton:hover {
    background-color: #ffffff !important;
    cursor: pointer;
}
.containerDiv {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 5px;
  background-color: #ffffff;
  border-radius: 10px;
}

.datePickerDiv {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #FFE0F0;
  text-align: left;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  border-radius: 100px;
  vertical-align: middle;
  justify-content: space-between;
}

.datePickerIconContainer {
  background-color: #AA346F;
  border-radius: 100px;
}

.datePickerIconContainer:hover,
.datePickerIconContainer:hover .dateInput,
.datePickerIconContainer:hover .datePickerDiv,
.datePickerIconContainer:hover svg {
  background-color: #5C0A33;
}

.datePickerDiv svg {
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: #FFFFFF;
  background-color: #AA346F;
  padding: 8px;
  border-radius: 30px;
}

.dateInputDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.dateInput {
  background-color: #AA346F;
  text-align: left;
  color: white;
  font-weight: 500;
  border-style: none;
  border-radius: 100px;
  outline: none;
  caret-color: #AA346F;
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  cursor: pointer;
  min-width: 13.5rem;
  max-width: 16rem;
}

.exportButton {
  vertical-align: middle !important;
  justify-content: center !important;
  /* display:flex !important; */
  flex-direction: row !important;
  text-align: center !important;
  align-items: center !important;
  color: #fff !important;
  font-size: 13px !important;
  border-radius: 8px !important;
  background-color: #7C596A !important;
  font-family: GTWalsheimProRegular !important;
  text-transform: capitalize !important;
  width: 85px !important;
  margin: 4px 6px 2px 5px !important;
  padding: 3px 0px !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.exportButton svg {
  width: 23px;
  height: 23px;
  padding: 2px 0px 2px 2px;
  border-radius: 3px;
  background-color: inherit;
}

.exportButton:hover {
  background-color: #4E253A !important;
}

.datePickerWrapper {
  /* margin-left: 190px; */
  border-radius: 10px;
  margin-right: auto;
  width: fit-content;
  margin-right: auto;
  overflow: auto;
  visibility: visible;
  color: #4E253A;
  z-index: 2;
  position: absolute;
  border: 2px solid #4E253A;
}

.notesEditor {
  display: block;
  width: 100%;
  padding-right: 10px;
  border-radius: 22.156px;
  border: 1.846px solid #D6A0BA;
  font-size: 16px;
  align-items: start;
  justify-content: left;
  margin-top: 13px;
  margin-bottom: 10px;
  margin-left: 20px;
  padding-left: 20px !important;
  font-family: GTWalsheimProRegular !important;
  background-color: #FCF9FA;
  box-shadow: 0px 0px 4px #ccc;
  overflow-wrap: break-word;
  overflow-y: scroll;
}

.notesEditor::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari and Opera */
}

.notesEditor {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.notesDisplayer {
  white-space: pre-wrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 10px 12px 10px;
  font-size: 16px;
  font-family: GTWalsheimProRegular !important;
  background-color: #FFEBF8;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow-wrap: anywhere; 
  max-height: 52px;
}
.expandIcon{
margin-left: auto !important;
display: flex;
}

.newNotesWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.dummyWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.addNewNoteDiv {
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: center;
  column-gap: 0px;
  position: relative;
  width: 100%;
  box-shadow: rgba(17, 17, 26, 0.1) 0px -6px 6px 0px;
  margin-left: 0px;
  border-bottom: #cccccc solid 1px;
}

.tagNameAdherence {
  font-size: 14px;
  border-radius: 50px;
  border: 1.5px solid #FEBADB;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  color: #172228;
  font-family: 'GTWalsheimProRegular' !important;
}

.selectedTagAdherence1 {
  font-size: 14px;
  background-color: #FEBADB;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #FEBADB;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.selectedTagAdherence {
  font-size: 14px;
  background-color: #FEBADB;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #FEBADB;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 6px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.tagNameMedication {
  font-size: 14px;
  border-radius: 50px;
  border: 1.5px solid #C6F1DD;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  color: #172228;
  font-family: 'GTWalsheimProRegular' !important;
}

.selectedTagMedication {
  font-size: 14px;
  background-color: #C6F1DD;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #C6F1DD;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 6px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.selectedTagMedication1 {
  font-size: 14px;
  background-color: #C6F1DD;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #C6F1DD;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.tagNameVitals {
  font-size: 14px;
  border-radius: 50px;
  border: 1.5px solid #FFCECE;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  color: #172228;
  font-family: 'GTWalsheimProRegular' !important;
}

.selectedTagVitals {
  font-size: 14px;
  background-color: #FFCECE;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #FFCECE;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 6px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.selectedTagVitals1 {
  font-size: 14px;
  background-color: #FFCECE;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #FFCECE;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.tagNameDevice {
  font-size: 14px;
  border-radius: 50px;
  border: 1.5px solid #FFE1F0;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  color: #172228;
  font-family: 'GTWalsheimProRegular' !important;
}

.selectedTagDevice {
  font-size: 14px;
  background-color: #FFE1F0;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #FFE1F0;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 6px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.selectedTagDevice1 {
  font-size: 14px;
  background-color: #FFE1F0;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #FFE1F0;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.tagNameOthers {
  font-size: 14px;
  border-radius: 50px;
  border: 1.5px solid #F3D8F6;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  color: #172228;
  font-family: 'GTWalsheimProRegular' !important;
}

.selectedTagOthers {
  font-size: 14px;
  background-color: #F3D8F6;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #F3D8F6;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 6px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.selectedTagOthers1 {
  font-size: 14px;
  background-color: #F3D8F6;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #F3D8F6;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.allNotesDiv {
  display: flex;
  flex-direction: column;
  width: 92vw;
}

.selectTagsDiv {
  margin-left: 0px;
  padding-top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.rootDiv {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
}

.noteHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  border: 1px solid #FFCFEF;
  background-color: #FFFFFF;
  border-top-left-radius: 10px;
  border-top-right-radius: 5px;
  height: 40px;
}

.createdDiv {
  display: flex;
  flex-direction: row;
  column-gap: 40px;
  align-items: center;
  justify-content: center;
}

.noteTags {
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  justify-content: left;
}

.noteTextArea {
  box-shadow: 0px 0px 4px #ccc;
  border-radius: 10px;
  background-color: #FFEBF8;
  padding-bottom: 1px;
  padding-left: 0px;
  border: 1px solid #FFCFEF;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.selectedTagName {
  font-size: 11px;
  background-color: #7C596A;
  color: #ffffff;
  border-radius: 50px;
  border: 1px solid #7C596A;
  padding-left: 4px;
  padding-right: 4px;
  max-height: 14px;
}

.noteMeasurementTime {
  font-size: 13px;
  font-family: GTWalsheimProRegular !important;
  margin-left: 5px;
  margin-bottom: 5px !important;
  background-color: #cccccc;
  width: fit-content;
  border-radius: 50px;
  padding-left: 5px;
  padding-right: 5px;
  height: 18px;
  margin-top: 10px;
}

.noteBlock {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-right: 10px;
}

.showNotesDiv {
  position: relative;
  overflow-y: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  overflow-x: hidden;
  height: calc(100vh - 21rem);
  width: calc(100vw - 135px);
}

.addNoteButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
}

.pinButton {
  /* color: #4E253A; */
  color: #AA346F;
  font-size: 24px !important;
}

.createdBy {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 17px !important;
  font-family: 'GTWalsheimProRegular' !important;
  font-weight: bold;
  color: #4E253A;
}

.createdTime {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px !important;
  font-weight: normal !important;
  color: #4E253A;
  float: right !important;
  background-color: #FEE7F7;
  filter: drop-shadow(0px 3.078px 3.078px rgba(0, 0, 0, 0.25));
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 50px;
  height: fit-content;
  margin-top: -4px;
}

.allNotesHeader {
  font-size: 25px !important;
  font-family: GTWalsheimProRegular !important;
  font-weight: bold;
  margin-left: 25px;
  margin-bottom: 10px;
}

.searchBar {
  position: relative;
  width: 97%;
  height: 40px;
  padding-right: 10px;
  border-radius: 50px;
  font-size: 16px !important;
  box-shadow: 0px 0px 4px #ccc;
  align-items: center;
  justify-content: left;
  margin-top: 10px;
  margin-left: 25px;
  margin-right: 0px;
  margin-bottom: 10px;
  background-color: #FFFFFF;
}

.searchIcon {
  height: 20px !important;
  width: 20px !important;
  float: left;
  color: #4E253A;
  margin-right: 5px;
}

.circularProgress {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  align-items: center;
}

.characterLimitDiv {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100px;
  align-items: center;
  font-size: 12px;
  color: rgb(185, 185, 185);
  font-family: "GTWalsheimProRegular";
  padding-left: 10px;
  margin-top: -2px;
}


.characterCountRed {
  color: red !important;
  width: fit-content;
}

.applyButtonDiv {
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  justify-content: right;
  background-color: #FFFFFF;
}

.applyButton {
  vertical-align: middle !important;
  justify-content: center !important;
  /* display:flex !important; */
  flex-direction: row !important;
  text-align: center !important;
  align-items: center !important;
  color: #fff !important;
  font-size: 15px !important;
  border-radius: 8px !important;
  background-color: #aa346f !important;
  font-family: GTWalsheimProRegular !important;
  text-transform: capitalize !important;
  width: 85px !important;
  margin: 4px 6px 2px 5px !important;
  padding: 3px 0px !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.applyButton:hover {
  background-color: #F14C00 !important;
  cursor: pointer;
}

.cancelButton {
  vertical-align: middle !important;
  justify-content: center !important;
  /* display:flex !important; */
  flex-direction: row !important;
  text-align: center !important;
  align-items: center !important;
  color: #aa346f !important;
  font-size: 15px !important;
  border-radius: 8px !important;
  background-color: #ffe0f0 !important;
  font-family: GTWalsheimProRegular !important;
  text-transform: capitalize !important;
  width: 85px !important;
  margin: 4px 6px 2px 5px !important;
  padding: 3px 0px !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.cancelButton:hover {
  background-color: #ffffff !important;
  cursor: pointer;
}

.circularProgressDiv {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.submitNotesButton {
  display: flex;
  width: 87px;
  height: 37px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 0px 0px 5px 0px;
  background: #87668B;
  color: #FFFFFF;
  margin-left: 10px;
}

.submitNotesButton:hover {
  background-color: #4E253A;
  cursor: pointer;
}

.submitNotesButtonDisabled {
  display: flex;
  width: 87px;
  height: 37px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 0px 0px 5px 0px;
  background: #DFD4E1;
  color: #FFFFFF;
  margin-left: 10px;
}

.newDumDiv {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  text-align: left;
  justify-content: left;
  align-items: start;
  width: fit-content;
  margin-right: -40px;
  padding-top: 10px;
}

.tagNameRPM {
  font-size: 14px;
  border-radius: 50px;
  border: 1.5px solid #FEBADB;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  color: #172228;
  font-family: 'GTWalsheimProRegular' !important;
}

.selectedTagRPM1 {
  font-size: 14px;
  background-color: #FEBADB;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #FEBADB;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.selectedTagRPM {
  font-size: 14px;
  background-color: #AA346F;
  color: white;
  border: 1.5px solid #AA346F;
  padding-left: 10px;
  padding-right: 10px;
  width: 60px;
  text-align: center;
  align-content: center;

  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.tagNameAdherence {
  font-size: 14px;
  border-radius: 50px;
  border: 1.5px solid #FEBADB;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  color: #172228;
  font-family: 'GTWalsheimProRegular' !important;
}

.selectedTagAdherence1 {
  font-size: 14px;
  background-color: #FEBADB;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #FEBADB;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.selectedTagAdherence {
  font-size: 14px;
  background-color: #FEBADB;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #FEBADB;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 6px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.tagNameCCM {
  font-size: 14px;
  border-radius: 50px;
  border: 1.5px solid #C6F1DD;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  color: #172228;
  font-family: 'GTWalsheimProRegular' !important;
}

.selectedTagCCM {
  font-size: 14px;
  background-color: #7C596A;
  color: white;
  align-content: center;
  border: 1.5px solid #7C596A;
  padding-left: 10px;
  padding-right: 10px;
  width: 60px;
  text-align: center;

  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.selectedTagCCM1 {
  font-size: 14px;
  background-color: #C6F1DD;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #C6F1DD;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.tagNameGeneral {
  font-size: 14px;
  border-radius: 50px;
  border: 1.5px solid #FFCECE;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  color: #172228;
  font-family: 'GTWalsheimProRegular' !important;
}

.selectedTagGeneral {
  font-size: 14px;
  background-color: #E86EAB;
  color: white;
  width: 60px;

  border: 1.5px solid #E86EAB;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  align-content: center;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.selectedTagGeneral1 {
  font-size: 14px;
  background-color: #FFCECE;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #FFCECE;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.filterDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'GTWalsheimProRegular' !important;
  float: right;
  column-gap: 10px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-left: 6px;
  padding-right: 6px;
  height: 32px;
  margin-bottom: 2px;
  background-color: #ffffff;
}

.filterDiv:hover {
  cursor: pointer;
}

.filterDivSelected {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'GTWalsheimProRegular' !important;
  float: right;
  column-gap: 10px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-left: 6px;
  padding-right: 6px;
  width: 140px;
  height: 32px;
  margin-bottom: 2px;
  background-color: #ffffff;
}

.filterDivLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
}

.filterBackdrop {
  position: absolute;
}

.wrapper {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  outline: none;
  width: fit-content;
  border-radius: 5px;
  align-items: center;
  overflow-x: hidden;
  position: absolute;
  /* -ms-transform: translate(-20%, -20%); */
  transform: translate(-50%, 2%);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.filtersDivText {
  font-family: 'GTWalsheimProRegular' !important;
  font-size: 0.8em !important;
  padding-bottom: 5px;
  column-gap: 5px;
  color: #4E253A;
  font-weight: bold;
  height: 30px;
  padding-left: 10px;
  margin-right: 0px !important;
  padding-top: 5px;
  padding-right: 15px;
  padding-left: 15px;
  white-space: nowrap;
}

.filtersDivTextSelected {
  font-family: 'GTWalsheimProRegular' !important;
  font-size: 12px !important;
  column-gap: 5px;
  color: #4E253A;
  font-weight: bold;
  background-color: #FDEAFF;
  padding-left: 10px;
  width: 140px;
  height: 30px;
  margin-right: 0px !important;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-right: 15px;
  padding-left: 15px;
  white-space: nowrap;
}

.notesTopBar {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.actionIcons{
  background-color: #FCE6F2;
  align-content: center;
  padding: 0 4px;
}

.actionIcons IconButton:disabled {
  color: rgba(0, 0, 0, 0.54);
  cursor: not-allowed;
}

.noteFooter{
  display: flex;
}
.editedNote{
  font-size: 12px;
  align-content: center;
  color :#4E253A;
}

.confirmationTooltipContainer {
  translate: -32px -29px !important;
  margin: 0 !important;
  padding: 0 !important;
  background-color: white !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  border: 1px solid #FFCFEF !important;
  border-radius: 4px !important;
  
}

.confirmationTooltip {
  text-align: center;
  padding: 0 !important;
  font-family: GTWalsheimProRegular !important;
  background-color: white !important;
  margin: 17px 0 0 0 !important;
  border: none !important;
  border-radius: inherit !important;
}

.confirmationText {
  font-size: 13px;
  color: #333;
  margin: 3px 0 20px 0;
  line-height: 23px;
  word-spacing: 1px;
}

.confirmationButtons {
  display: flex;
  justify-content: center;
  gap: 14px;
  margin-bottom: 26px;
}

.confirmationDeleteButton {
  font-weight: 500 !important;
  padding: 6px 16px !important;
  font-size: 12px !important;
  background-color: #AA346F !important;
  height: 29px !important;
  border-radius: 100px !important;
  font-family: GTWalsheimProRegular !important;
  box-shadow: none !important;
  text-transform: none !important;
  color: #FFFFFF !important;
}

.confirmationDeleteButton:hover{
  background-color: #5C0A33 !important;
  
}

.confirmationCancelButton {
  font-weight: 500 !important;
  padding: 6px 16px !important;
  border: 1px solid #AA346F !important;
  color: #AA346F !important;
  font-size: 12px !important;
  height: 29px !important;
  border-radius: 100px !important;
  font-family: GTWalsheimProRegular !important;
  text-transform: none !important;
}

.confirmationCancelButton:hover{
  background-color: #FFE0F0 !important;
  color: #5C0A33;
}

.buttonAddNote {
  background-color: #AA346F !important;
  color: white !important;
  text-transform: none !important;
  align-self: center !important;
  padding: 5px 0px !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 100px !important;
  gap: 8px !important;
  border: none !important;
  font-family: GTWalsheimProRegular !important;
  height: 40px !important;
  justify-content: end;
  transition: none !important;
}

.buttonAddNote:disabled {
  opacity: 0.3;
  color: white !important;
}

.buttonAddNote:not(:disabled):hover,
.buttonAddNote:not(:disabled):hover svg {
  background-color: #5C0A33 !important;
}

.circleAdd {
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  font-size:25px;
  align-items: center;
  justify-content: center;
}

.plusAdd {
  color: black;
  font-weight: bold;
  font-size: 18px;
  line-height:0px;
}

.tooltip {
  margin-top: 5;
  font: 14px;
  background: black !important;
  font-family: GTWalsheimProRegular;
}

.newNoteText {
  margin-left: 18px;
}

.datePickerIconContainer {
  display: inline-flex;
}
.headerWrapper {
    padding-bottom: 0px;
}

.headerStyles {
    height: 100%;
    max-width: 100%;
    background-color: #F9F2F2;
    float: left;
}

.headerMenuBar {
    background-color: #F9F2F2;
    border-radius: 0px 0px 20px 20px;
}

.headerBarWrapper {
    display: inline-flex;
    padding-left: 20px;
}

.headerBarWrapper div {
    display: flex;
}

.headerDiv {
    margin-left: 20px;
    margin-right: 20px;
    height: 100vh;
}

.regTabsDiv {
    padding-top: 20px;
    width: 100%;
}

.tabsLabel {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
    justify-content: center;
}

.tabNameDiv {
    display: flex;
    flex-direction: column;
    row-gap: 0px;
    align-items: center;
    justify-content: left;
    width: 150px;
}

.tabNameDiv1 {
    font-size: 14px;
}

.tabNameDiv2 {
    font-size: 15px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #363636 !important;
    font-weight: 500;
}

.ant-tabs-ink-bar {
    position: absolute;
    background: #000000;
    pointer-events: none;
}
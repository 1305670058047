.chart-container {
    width: 100%;
    height: 218px;
    position: relative;
    overflow: hidden;
}

.inner .domainAndTicks .tick line {
    opacity: 0;
}

.axes .domainAndTicks line.domain {
    opacity: 0;
}

.inner .grid .tick line {
    stroke: #CBD5E1;
    stroke-dasharray: 5, 5;
}

.domainAndTicks text {
    font-family: 'GTWalsheimProRegular';
    fill: #4E253A !important;
    font-weight: bold;
    letter-spacing: 0.4px;
    font-size: 13px !important;
}

.axes > g:nth-of-type(1) > g.Axis-Group.inner g.grid g.tick line {
    opacity: 0;
  }

.ReactChart .Series rect {
    fill: url(#gradientFill) !important;
  }
  
  
.graph-box {
    width: 91%; 
    margin: 33px auto 17px auto;
    border-radius: 16px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
}

.graph-container {
    padding: 1rem 1rem 1rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.graph-tabs {
    display: flex;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    background: linear-gradient(to bottom, #FDE7F1, #FFFFFF); /* Subtle gradient background */
}
  
.graph-tabs .tab {
    flex: 1;
    text-align: center;
    padding: 12px 0;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
    letter-spacing: 0.9px;
}

.graph-tabs .tab.active {
    background: #e176ab; 
    color: #FFFFFF; 
}

.graph-tabs .tab.disabled {
    background: #F2F2F2; 
    color: #C0C0C0; 
    cursor: not-allowed;
}

.graph-tabs .tab:not(.active):not(.disabled) {
    background: #F9F3F6; 
    color: #AA346F; 
}

  /* Y-Axis Label */
.y-axis-label {
    position: absolute;
    top: 41%;
    left: 0;
    transform: rotate(-90deg);
    transform-origin: center;
    font-size: 14px;
    font-weight: bold;
    color: #AA346F;
}

/* X-Axis Label */
.x-axis-label {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #AA346F;
}
.warningMsgContent{
  text-align: center;
  margin-bottom: 10px;
}

.timerDiv{
  display: flex;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.counterTime{
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  background-color: #FFF1F8;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 50px;
      border: 3.675px solid #AA346F;
  }

  .timerNumber{
      border-radius: 15px;
      /* border: 1px solid #D3D8E1; */
      color: #AA346F;
      background-color: #FFF1F8;
      min-width: 40px;
      padding: 5px;
      font-family: GTWalsheimProRegular;
      font-weight: bold;
      font-size: 24px;
      margin-left: auto;
      margin-right: auto;
    }
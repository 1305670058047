.containerDiv {
  /* background-color: #eef2fc; */
  width: 100%;
  /* align-items: center;
            text-align: center; */
  border-radius: 20px;
  box-shadow: 0px 2px 9px 0px #888888;
  border-bottom: 1px solid rgb(255, 203, 119, 0.4);
  padding: 20px;
  margin-top: 20px;
}

.wrapperDiv {
  padding: 10px;
}

.cardTitle {
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: GTWalsheimProRegular;
  font-size: 20px;
  color: #322960;
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}

.cancelButton {
  margin-top: 10px;
  margin-right: 10px;
  min-width: 46%;
  background-color: #fff;
  color: #8D919A;
  font-weight: 600;
  border: 1px solid #8D919A;
  border-radius: 10px;
  padding: 10px;
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 15px;
}

.cancelButton:hover {
  color: #4E253A;
  background-color: #F1D4D4;
  border: 1px solid #8D919A;
}

.submitButton {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  min-width: 45%;
  background-color: #7C596A;
  color: white;
  /* font-weight: 200; */
  border: 1px solid #7C596A;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 15px;
}

.submitButton:hover {
  background-color: #4E253A;
  border: 1px solid #4E253A;
}

.radioLabelForm {
  font-size: 18px;
  font-family: "GTWalsheimProRegular";
  color: #5F5C70;
  margin: 10px 0px 10px 5px;
  font-weight: bold;
}

.inputCenterDiv {
  align-items: center;
  text-align: left;
  margin-left: 5px;
  width: 95%;
}

.progressResponseDiv {
  align-items: center;
  text-align: center;
}

.buttonContainer {
  margin-top: 20px;
  margin-left: 5px;
}

.statusMessage {
  padding: 20px 0px;
}

.smSuccess {
  color: rgb(0, 160, 0);
}

.smError {
  color: rgb(180, 0, 0);
}


.selectDropdown {
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0px 10px;
  font-size: 18px;
  font-family: "GTWalsheimProRegular";
  line-height: 1.42857143;
  color: #000;
  background-color: #fff;
  background-image: none;
  border: 1px solid #a7a7a7;
  border-radius: 10px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  height: 45px;
}

.dropDownItems {
  font-size: 18px;
  font-family: "GTWalsheimProRegular";
  padding: 20px;
}

.circularProgressDiv {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.responsive {
  margin-right: auto;
  margin-left: auto;
}
/* @media screen and (max-width: 1500px) {
    .ecgCard {
      width: 1240px;
    }
  }
  
  @media screen and (max-width: 1200px) {
    .ecgCard {
      width: 1240px;
    }
  }
  @media screen and (max-width: 1400px) {
    .ecgCard {
      width: 820px;
    }
  }
  
  @media screen and (max-width: 900px) {
    .ecgCard {
      width: 100px;
    }
  }
  @media screen and (max-width: 768px) {
    .ecgCard {
      width: 610px;
    }
  }
  
  @media screen and (max-width: 420px) {
    .ecgCard {
      width: 640px;
    }
  } */

/* MEDIA QUERIES */
@media screen and (min-width: 600px) {
  /* For Tablets */
  .ecgCard {
    width: 100;
  }
}

@media screen and (min-width: 768px) {
  /* For Laptops */
  .ecgCard {
    width: 738px;
  }
}

@media screen and (min-width: 992px) {
  /* //For Large Laptops */
  .ecgCard {
    width: 1200px;
  }
}

@media screen and (min-width: 1280px) {
  /* //For Big TV's (HD Screens)  */
  .ecgCard {
    width: 1300px;
  }
}

@media screen and (min-width: 1920px) {
  /* //For Projectors or Higher Resolution Screens (Full HD) */
  .ecgCard {
    width: 1740px;
  }
}

@media screen and (min-width: 2500px) {
  /* //For Projectors or Higher Resolution Screens (Full HD) */
  .ecgCard {
    width: 2340px;
  }
}
@media screen and (min-width: 3840px) {
  /* //For 4K Displays (Ultra HD) */
  .ecgCard {
    width: 3810px;
  }
}
/* MEDIA QUERIES */




.zoomIconStyle {
  margin-left: 10px;
  margin-top: 3.5px;
  margin-bottom: auto;
}

/*  */

.imgeEcg {
  max-width: 170%;
  max-height: 100%;
}

.Modalimg {
  max-width: 100% !important;
  max-height: 100% !important;
}
/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.careplanTitle {
  font-size: 20px;
  font-family: GTWalsheimProRegular;
  color: #6c757d;
  margin-bottom: 20px;
}

.activityCard {
  background: transparent;
  border: none;
  /* background-color: rgb(229, 152, 155,0.5); */
  padding: 20px;
  border-radius: 10px;
  align-items: center;
  color: #495057;
  /* border-left: 5px solid rgb(229, 152, 155); */
}
.activityCard:hover {
  background-color: rgb(182, 204, 254, 0.7);
}

.message {
  display: flex;
  align-items: center;
  /* margin: auto; */
  font-size: 15px;
  text-align: left;
  font-family: GTWalsheimProRegular;
}

.contentDivTitle {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 12px;
  font-family: GTWalsheimProRegular;
  display: flex;
}

.numbers {
  font-size: 22px;
  font-family: GTWalsheimProRegular;
  color: #013a63;
}

/* .contentTitleIconStyle{
      margin-top: 3.5px;
      margin-bottom: auto;
    } */

.contentDiv {
  margin-left: 5px;
  margin-right: 5px;
  /* border-right: 1px solid #888888; */
  padding: 5px;
}

.contentStyle {
  margin-right: 10px;
  margin-left: 10px;
}

.contentDivLastStyles {
  margin-left: 5px;
  margin-right: 5px;
  /* border-right: 1px solid #888888; */
  padding: 5px;
}

.vl {
  border-left: 1px solid #535354;
  height: 50px;
}

/* Square tiles styling */

.squareCard {
  background: transparent;
  border: none;
  background-color: #faf9f9;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  color: #495057;
  box-shadow: 0px 2px 9px 0px #888888;
  /* border-left: 5px solid rgb(229, 152, 155); */
}

.squareCardTitle {
  display: flex;
  align-items: center;
}

.squareCardTitleIconStyle {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 8px;
}

.titleSquare {
  margin-top: auto;
  margin-bottom: auto;
  color: #e76f51;
  font-family: GTWalsheimProRegular;
}
.iconStyles {
  margin-top: 5px;
  margin-bottom: auto;
  color: #e76f51;
}
.moreInfoSquareCard {
  margin-left: auto;
  font-family: GTWalsheimProRegular;
  font-size: 15px;
  display: flex;
}

.moreIconStyleSquareCard {
  margin-left: 5px;
  margin-top: 3.5px;
  margin-bottom: auto;
}

.contentDivTitleSquareCard {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 12px;
  font-family: GTWalsheimProRegular;
  display: flex;
}

.numbersSquareCard {
  font-size: 35px;
  font-family: GTWalsheimProRegular;
  color: #013a63;
}

/* .contentTitleIconStyle{
      margin-top: 3.5px;
      margin-bottom: auto;
    } */

.contentDivSquareCard {
  /* margin-left: 5px;
      margin-right: 5px; */
  /* border-right: 1px solid #888888; */
  padding: 5px;
}

.contentStyleSquareCard {
  /* margin-right: 10px;
      margin-left: 10px; */
}

.contentDivLastStyles {
  /* margin-left: 5px;
      margin-right: 5px; */
  /* border-right: 1px solid #888888; */
  padding: 5px;
}

.vl {
  border-left: 1px solid #535354;
  height: 50px;
}

.activitySquareCard {
  background: transparent;
  border: none;
  /* background-color: rgb(229, 152, 155,0.5); */
  border-radius: 10px;
  align-items: center;
  color: #495057;
  /* border-left: 5px solid rgb(229, 152, 155); */
}

/* Modal menu styles */

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  background-color: #ffff;
  outline: none;
  border-radius: 10px;
  padding: 10px 0px 10px 0px;
  align-items: center;
  /* width: 950px;
    */
  min-height: 400px;
  overflow-x: auto;
}

.menuTitle {
  display: flex;
  border-bottom: 1px solid #e9ecef;
  background-color: #ffff;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  font-family: GTWalsheimProRegular;
}

.titleText {
  font-style: GTWalsheimProRegular;
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 17px;
  padding: 10px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.modalContainer {
  background-color: #ffff;
  height: 90%;
}

.buttonStyles {
}

.addMedicationFormDiv {
  display: flex;
}

/* Nav bar button */
/* nav bar horizontal styles */

.navbarHorizontal {
  /* border-bottom: 1px solid #ced4da; */
}

.navContent {
  text-align: right;
  background-color: white;
  display: flex;
  float: right;
}

.buttonsIconsDivStyle {
  padding: 5px;
  align-items: center;
  display: flex;
  float: right;
  margin-right: 15px;
}

.weekbuttonsIconsDivStyle {
  padding: 5px;
  align-items: center;
  display: flex;
  float: right;
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 10px;
}

.buttonsIconsDivStyle:hover {
  /* background-color: #DEE2E6; */
  background-color: #dee2e6;
  border-radius: 10px;
}

.buttonsIconsDivStyle:hover .buttonDivStyle {
  /* background-color: #DEE2E6; */
  background-color: #dee2e6;
  border-radius: 10px;
}

.buttonsIconsDivStyle:hover .navBarbutton {
  /* background-color: #DEE2E6; */
  background-color: #dee2e6;
  border-radius: 10px;
}

.buttonDivStyle {
  align-items: center;
  display: flex;
  float: right;
  margin-right: -5px;
}
.navBarbutton {
  border: none;
  background: none;
  background-color: white;
  color: #343a40;
  font-size: 16px;
  align-items: center;
  font-family: GTWalsheimProRegular;
  outline: none;
}

.weekButton {
  border: none;
  background: none;
  background-color: #dee2e6;
  color: #343a40;
  font-size: 16px;
  align-items: center;
  font-family: GTWalsheimProRegular;
}
.sortIconStyle {
  margin-right: 3px;
  color: #343a40;
}

.filerIconStyle {
  color: #343a40;
  margin-right: 10px;
}

/* Style for date on the nav content */

.notificationsLableContainer {
  color: #ffff;
  margin-left: 5px;
  font-family: GTWalsheimProRegular;
  font-size: 11px;
  display: flex;
  align-items: center;
  display: flex;
  margin-right: auto;
}

.notificationLable {
  /* background-color: #40916c;
    
    border-radius: 10px;
    font-family: GTWalsheimProRegular;
    font-size: 11px;
    padding: 0px 10px 0px 10px; */
  padding: 5px;
  align-items: center;
  display: flex;

  background-color: #dee2e6;
  border-radius: 10px;
  font-size: 17px;
}

.topIcondiv {
  margin-left: auto;

  /* color: #6c757d; */
}
.menuIconTop {
  margin-top: 8px;
  margin-bottom: 3px;
  color: #535354;
}

.containerNav {
  display: flex;
  align-items: center;
}

.ecgCard {
  border: none;
  background-color: #faf9f9;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  color: #495057;
  box-shadow: 0px 1px 7px 0px #888888;
  margin-bottom: 30px;
  margin-right: auto;
  margin-left: auto;
}

.ecgCardTitle {
  display: flex;
  align-items: center;
}

.ecgCardTitleIconStyle {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 8px;
}

.ecgCardIconStyles {
  margin-top: 5px;
  margin-bottom: auto;
  color: #e76f51;
}

.heartRateInformation {
  margin-top: auto;
  margin-bottom: auto;
  color: #e76f51;
  font-family: GTWalsheimProRegular;
}

.zoomEcgCard {
  margin-left: auto;
  font-family: GTWalsheimProRegular;
  font-size: 15px;
  display: flex;
  color: #d76071;
}

.ecgContainer {
  display: flex;
  margin-top: 10px;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  background-color: white;
  height: 300px;
  padding-top: 10px;
}

.yAxisLabels {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 2px 10px 10px;
  margin-bottom: auto;
  min-width: 50px;
}

.yLabel {
  font-size: 12px;
  color: #495057;
  margin-bottom: 24px;
}

.chartArea {
  overflow-x: scroll;
  flex-grow: 1;
  display: flex;
  align-items: baseline;
  margin-top: 5px;
}

.chartArea canvas {
  display: block;
}

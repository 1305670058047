/* This is the backdrop of drawer */
/* .primaryDrawer {
  width: 0px;
} */

.drawerPaper {
  width: 600px;
  background-color: #f3e8ee;
  overflow-x: hidden;
  border-radius: 24px 0 0 24px;
}

.tabsContainer {
  display: flex;
  justify-content: space-evenly;
}

.tabs {
  background-color: #f9f3f6;
  padding: 8px 20px;
  font-size: 16px;
  font-weight: bold;
  width: 200px;
  align-self: center;
  color: var(--DarkMaroon);
  text-align: center;
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  letter-spacing: 1px;
  cursor: pointer;
}

.notesDrawerButton {
    position: fixed;
    bottom: 10px;
    right: 5px;
    background-color: #7C596A;
    border-radius: 50px;
    transition: all 180ms cubic-bezier(0, 0, 0.2, 1) !important;
  }

  .notesDrawerButton.drawerOpen{
    right: 605px;
    transform: rotate(180deg);
    z-index: 1301;
  }

  .notesDrawerButton.drawerClosed{
    right: 5px;
    transform: rotate(0deg);
  }
  
  .notesDrawerButton .iconNotesDrawerButton {
    background-color: #F1D4D4;
    max-width: 30px;
    max-height: 30px;
    align-items: center;
    justify-content: center;
    margin: 5px;
  }
  
  .notesDrawerButtonInside {
    position: absolute;
    bottom: 10px;
    right: 5px;
    background-color: #7C596A;
    border-radius: 50px;
  }
  
  .notesDrawerButtonInside .iconNotesDrawerButton {
    background-color: #F1D4D4;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    margin: 5px;
  }
.paper {
    background-color: #fff;
    outline: none;
    border-radius: 20px;
    align-items: center;
    width: 400px;
    overflow-x: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 380px;
    width: 350px;
  }

  .menuTitle {
    background-color: #FFF1F8;
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 20px;
    font-family: "GTWalsheimProRegular";
    font-weight: bold;
    justify-content: center;
    padding: 10px;
    color: #4E253A;
  }

  .patientListDiv {
    width: fit-content;
    padding: 10px;
    font-style: "GTWalsheimProRegular";
    font-size: 18px;
  }

  .userQuestionMessage {
    font-family: "GTWalsheimProRegular";
    color: #4E253A;
    font-weight: 600;
    font-size: 17px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }

  .mouthLabImgDiv {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mlImg {
    width: 168px;
    height: 150px;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-left: 10px;
    margin-bottom: -10px;
  }

  .cancelButton {
    margin-top: 10px;
    margin-right: 10px;
    min-width: 40%;
    color: #AA346F;
    background-color: #FFFFFF;
    border: 1px solid #AA346F;
    font-weight: 600;
    border-radius: 30px;
    padding: 10px;
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 12px;
  }
  
  .cancelButton:hover {
    color: #AA346F;
    background-color: #FFE0F0;
    border: 1px solid #FFE0F0;
  }

  .submitButton {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    min-width: 40%;
    border-radius: 30px;
    padding: 10px;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 12px;
    background-color: #AA346F;
    border: 1px solid #AA346F;
    color: white;
    font-weight: 600;
  }
  
  .submitButton:hover {
    background-color: #F14C00;
    color: white;
    border: 1px solid #F14C00;
  }
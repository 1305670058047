.pageTitle {
  color: #4E253A;
  font-family: "GTWalsheimProRegular";
  font-size: 26px;
  font-weight: bold;
  margin-left: 20px;
  margin-right: auto;
}

.responsive {
  overflow-x: auto;
  width: 100%;
  /* padding: 20px; */
}

.iconsStyleSort {
  color: #353b48;
  margin-top: auto;
  margin-bottom: auto;

  margin-left: 5px;
}

/* Table header styles */

.TableWrapper {
  background: transparent;
  border: none;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  color: #495057;
  box-shadow: 0px 2px 5px 0px #cccaca;
  margin: 20px;
}

.tableHeaderStyles {
  /* background-color: #36304a; */
  background-color: #E3E3E4;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* margin-top: 30px; */
  margin-left: auto;
  margin-right: auto;
  overflow-x: auto;
  width: 100%;
}

.headerStyles {
  /* display: flex; */
  align-items: center;
  /* background-color: #36304a; */
  background-color: #E3E3E4;
  padding: 10px;
  color: #000;
  font-family: "GTWalsheimProRegular";
  font-size: 18px;
  font-weight: lighter;
}

.subjectIdHeader {
  max-width: 150px;
  min-width: 130px;
  text-align: center;
  align-items: center;
  font-size: 18px;
  font-family: "GTWalsheimProRegular";
  padding: 10px;
}

.deviceHeader {
  max-width: 150px;
  min-width: 150px;
  text-align: center;
  align-items: center;
  font-size: 18px;
  font-family: "GTWalsheimProRegular";
}

.statusHeader {
  max-width: 150px;
  min-width: 150px;
  text-align: center;
  align-items: center;
  font-size: 18px;
  font-family: "GTWalsheimProRegular";
}

.actionHeader {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  align-items: center;
  font-size: 18px;
  font-family: "GTWalsheimProRegular";
}

.mlLogWrapper {
}

.dummyDiv {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.dummyDiv::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.nameDiv {
  margin-left: 3px;
  align-items: center;
}

.rowCellSubjectID {
  max-width: 150px;
  min-width: 150px;
  text-align: center;
  background-color: #f5f6f8;
  font-size: 18px;
  font-family: GTWalsheimProRegular;
}

.rowCellSubjectID:hover {
  color: #4E253A;
}

.rowCellMouthLabID {
  max-width: 150px;
  font-size: 18px;
  min-width: 150px;
  border: 4px solid #F5F6F8;
  background-color: #fff;
  text-align: center;
  font-family: "GTWalsheimProRegular";

}

/* Device Status Vailable */
.deviceStatus {
  max-width: 150px;
  min-width: 150px;
  text-align: center;
  background-color: #D9EEEB;
  font-size: 18px;
  font-family: "GTWalsheimProRegular";
}

/* Device Status Not available */
.deviceStatusNotAvailable {
  max-width: 150px;
  min-width: 150px;
  text-align: center;
  background-color: #F9DED7;
  font-size: 18px;
  font-family: "GTWalsheimProRegular";
}




/* Modal Menu styling */

.modal {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  position: relative;
  /* margin-left: 10%;
          margin-right: 90%;
          top: 50; */
}

.paper {
  background-color: #fff;
  outline: none;
  border-radius: 20px;
  padding: 10px;
  align-items: center;
  width: 400px;
  /* height: 350px; */
  overflow-x: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.menuTitle {
  background-color: #ffff;
  display: flex;
  /* border-bottom: 1px solid gray; */
  text-align: center;
  width: 100%;
  align-items: center;
  font-size: 20px;
  font-family: "GTWalsheimProRegular";
}

.titleText {
  font-style: "GTWalsheimProRegular";
  font-weight: bold;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 28px;
  padding: 10px;
  color: #4E253A;
}

.buttonDiv {
  text-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 20px; */
}

.assignButton {
  /* min-width: 150px; */
  width: 100%;
  background-color: #7C596A;
  color: white;
  /* font-weight: 200; */
  border-style: none;
  border-width: 10px;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 16px;
  cursor: pointer;
}

.assignButton:hover {
  background-color: #4E253A;
  color: white;
  border: 1px solid #4E253A;
}

.removeButton {
  /* min-width: 150px; */
  width: 100%;
  background-color: #F1D4D4;
  color: #4E253A;
  font-weight: 700;
  border-style: none;
  border-width: 10px;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 16px;
  cursor: pointer;
}

.removeButton:hover {
  background-color: #F14C00;
  color: white;
  border: 1px solid #F14C00;
}

.paperUnassignML {
  background-color: #fff;
  outline: none;
  border-radius: 20px;
  padding: 10px;
  align-items: center;
  width: 400px;
  /* height: 350px; */
  overflow-x: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.noData {
  position: relative;
  clear: both;
  display: inline-block;
  white-space: nowrap;
  color: #000000;
  font-family: GTWalsheimProRegular;
  font-size: 22px;
  font-weight: 700;
  padding-top: 20px;
  padding-bottom: 20px;
}

.noDataWrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fbf4f7;
}
.searchBar {
    position: relative;
    width: 96%;
    height: 40px;
    border-radius: 50px;
    font-size: 14px;
    box-shadow: 0px 0px 4px #ccc;
    font-style: italic;
    background-color: #ffffff;
    padding:10px 4px 10px 4px;
}

.searchBar input {
    margin-left: 5px;
}

.searchIcon {
    height: 32px;
    width: 32px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-decoration: none;
    color: #4E253A;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }

  .startAdornment {
    height: auto;
    max-height: fit-content;
  }
.stepperButton {
  min-width: 100px;
  /* background-color: #fe6b59; */
  /* background-color: #81668c; */
  background-color: #4E253A;
  color: white;
  /* font-weight: 200; */
  border-style: none;
  border-width: 10px;
  border-radius: 15px;
  padding: 10px 5px 10px 5px;
  box-shadow: 2px 5px 9px -3px #4E253A;
  outline: none;
  font-family: GTWalsheimProRegular;
  font-size: 16px;
  margin: 20px 5px;
  cursor:pointer;
}

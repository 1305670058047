.aidarName {
  color: white;
  font-family: GTWalsheimProRegular;
  font-size: 18px;
  width: 100px;
}

.content {
  flex-grow: 1;
  /* height: 100vh; */
  padding: 0px;
  margin: 0;
}

.content {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.content::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}


.toolbar {
  border-top-left-radius: 25px;
  background-color: red;
}

.iconStyles {
  color: #8e7c8a;
}

.iconStylesSelected {
  color: white;
}

.listAlignmentStyles {
  text-align: center !important;
}

.profileMenuTopBar {
  border-bottom: 1px solid #ced4da;
  margin-top: auto;
  margin-bottom: auto;
}

.profileMenuHEading {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 10px;
}

/* .avatarStyles{
    border: 1px solid #2196F3;
} */

.avatarStyles {
  margin-right: 5px;
}

.profileMenuContent {
  display: flex;
  font-family: GTWalsheimProRegular;
}

.accountMenu {
  width: 50%;
}

.exploreMenu {
  width: 50%;
}

.contentHeading {
  color: #6c757d;
  font-size: 15px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: GTWalsheimProRegular;
}

.menuButtonStyle {
  padding: 5px;
  align-items: center;
  margin-right: 15px;
}

.navlinkStyle {
  color: inherit;
  text-decoration: none;
}

.navlinkStyle .disabled {
  pointer-events: none;
}

.menuButtonDivStyle {
  align-items: center;
  display: flex;
  float: right;
  font-family: GTWalsheimProRegular;
}

.iconDivStyle {
  margin-right: 10px;
}

.iconStyle {
  color: #343a40;
}

.profileMenuBottomBar {
  border-top: 1px solid #ced4da;
  margin-top: 10px;
  margin-bottom: auto;
}

.profileMenuFooterStyle {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.footerIconDivStyle {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.footerIconStyle {
  color: #343a40;
  margin-right: 5px;
}

.something {
  margin-right: 20px;
}

.listColor {
  font-family: GTWalsheimProRegular;
  color: white;
}

.versionColor {
  font-family: GTWalsheimProRegular;
  color: #8C8C8C;
  font-size: 8px;
}

/* Tooltip styles */
.tooltip {
  font-size: 16px;
  font-family: GTWalsheimProRegular;
}


.listItemStyles {
  color: red;
}


.listItemStyles:hover {
  color: blue;
  background-color: red;
}


/* Modal Menu styling */

.modal {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  position: relative;
  /* margin-left: 10%;
        margin-right: 90%;
        top: 50; */
}

.paper {
  background-color: #fff;
  outline: none;
  border-radius: 20px;
  align-items: center;
  width: 400px;
  /* height: 350px; */
  overflow-x: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 340px;
  width: 350px;
}

.menuTitle {
  background-color: #FFF1F8;
  display: flex;
  /* border-bottom: 1px solid gray; */
  text-align: center;
  align-items: center;
  font-size: 20px;
  font-family: "GTWalsheimProRegular";
  font-weight: bold;
  justify-content: center;
  padding: 10px;
  color: #4E253A;
}

.titleText {
  font-style: "GTWalsheimProRegular";
  font-weight: bold;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 24px;
  padding: 10px;
  color: #4E253A;
  text-align: left;
}


.userInfoMessage {
  font-family: "GTWalsheimProRegular";
  /* margin-left: auto; */
  margin-right: auto;
  color: #898B8F;
  font-size: 19px;
  margin-left: 10px;
  margin-bottom: 30px;
  /* text-align: center; */
}

.userQuestionMessage {
  font-family: "GTWalsheimProRegular";
  /* margin-left: auto; */
  color: #4E253A;
  font-weight: 600;
  font-size: 17px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.submitButton {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  min-width: 40%;
  border-radius: 30px;
  padding: 10px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 12px;
  background-color: #AA346F;
  border: 1px solid #AA346F;
  color: white;
  font-weight: 600;
}

.submitButton:hover {
  background-color: #F14C00;
  color: white;
  border: 1px solid #F14C00;
}

.cancelButton {
  margin-top: 10px;
  margin-right: 10px;
  min-width: 40%;
  color: #AA346F;
  background-color: #FFFFFF;
  border: 1px solid #AA346F;
  font-weight: 600;
  border-radius: 30px;
  padding: 10px;
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 12px;
}

.cancelButton:hover {
  color: #AA346F;
  background-color: #FFE0F0;
  border: 1px solid #FFE0F0;
}

.wrapper {
  background-color: azure;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2em;
}

.mouthLabImgDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mlImg {
  width: 168px;
  height: 150px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-left: 10px;
}
.containerDiv {
    display: flex;
    flex-direction: column;
    width: 97%;
    height: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
}

.headerDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #FFE4F2;
    padding: 8px;
    font-family: "GTWalsheimProRegular";
    font-size: 15px;
    font-weight: 700;
    color: #4E253A;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.contentDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: #F8EFF5;
}

.fieldName {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-left: -1px;
    font-family: "GTWalsheimProRegular";
    font-size: 14px;
    font-weight: 400;
    color: #4E253A;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.113px;
}

.column1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.8vw;
}

.column2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.8vw;
}

.column3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.8vw;
}

.column4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.8vw;
}

.circlesListItem {
    font-size: 10px;
}

.inputFieldSelctedE {
    display: flex;
    height: 32px;
    width: 15vw;
    border: solid #A040AB 0.5px;
    background-color: #FFFFFF;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
    border-radius: 2px;
}

.inputFieldSelcted {
    display: flex;
    height: 32px;
    width: 15vw;
    border: solid #A040AB 0.5px;
    background-color: #FFEEF6;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
    border-radius: 2px;
}

.inputFieldSelctedReq {
    display: flex;
    height: 32px;
    width: 15vw;
    border: solid red 2px;
    background-color: #F8F8F8;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
    border-radius: 2px;
}

.inputFieldSelcted2 {
    display: flex;
    height: 32px;
    width: 8.6vw;
    border: solid #A040AB 0.5px;
    background-color: #FFEEF6;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
    border-radius: 2px;
}

.inputFieldSelcted2E {
    display: flex;
    height: 32px;
    width: 8.6vw;
    border: solid #A040AB 0.5px;
    background-color: #FFFFFF;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
    border-radius: 2px;
}

.dropdown2 {
    height: 32px !important;
    width: 15.7vw !important;
    border: solid #A040AB 0.5px !important;
    background-color: #FFEEF6 !important;
    padding: 0px 5px !important;
    opacity: 1 !important;
}

.dropdown2E {
    height: 32px !important;
    width: 15.7vw !important;
    border: solid #A040AB 0.5px !important;
    background-color: #FFFFFF !important;
    padding: 0px 5px !important;
    opacity: 1 !important;
}

.inputFieldSelctedReq2 {
    display: flex;
    height: 32px;
    width: 8vw;
    border: solid red 2px;
    background-color: #F8F8F8;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
    border-radius: 2px;
}

.inputFieldSelctedDOB {
    display: flex;
    height: 32px;
    width: 15vw;
    border: solid #A040AB 0.5px;
    background-color: #F8F8F8;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
    border-radius: 2px;
}

.fieldDiv1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px;
}

.fieldDiv2 {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    width: 100%;
    margin: 10px;
}

.dropdown {
    height: 31px !important;
    width: 5.5vw !important;
    border: solid #A040AB 0.5px !important;
    background-color: #FFEEF6 !important;
    padding: 3px 5px !important;
    font-family: "GTWalsheimProRegular" !important;
    opacity: 1 !important;
}

.dropdownE {
    height: 31px !important;
    width: 5.5vw !important;
    border: solid #A040AB 0.5px !important;
    background-color: #FFFFFF !important;
    padding: 3px 5px !important;
    font-family: "GTWalsheimProRegular" !important;
    opacity: 1 !important;
}

.dropdownReq {
    height: 31px !important;
    width: 5.5vw !important;
    border: solid red 1.5px !important;
    background-color: #F8F8F8 !important;
    padding: 3px 5px !important;
    font-family: "GTWalsheimProRegular" !important;
}

.dropdownReq2 {
    height: 31px !important;
    width: 15.7vw !important;
    border: solid red 1.5px !important;
    background-color: #F8F8F8 !important;
    padding: 0px 5px !important;
    font-family: "GTWalsheimProRegular" !important;
}

.react-dropdown-select-content .react-dropdown-select-type-multi {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    height: 30px;
    overflow: hidden;
}

.subFieldName {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-left: -1px;
    font-family: "GTWalsheimProRegular";
    font-size: 14px;
    font-weight: 400;
    color: #4E253A;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.113px;
}

.datePickerWrapper {
    /* margin-left: 190px; */
    border-radius: 10px;
    margin-right: auto;
    width: fit-content;
    overflow: auto;
    visibility: visible;
    color: #4E253A;
    z-index: 2;
    position: absolute;
    border: 2px solid #4E253A;
    display: flex;
    flex-direction: column;
    margin-top: 10px !important;
}

.applyButtonDiv {
    display: flex;
    flex-direction: row;
    padding-bottom: 5px;
    align-items: center;
    justify-content: right;
    background-color: #FFFFFF;
}

.applyButton {
    vertical-align: middle !important;
    justify-content: center !important;
    /* display:flex !important; */
    flex-direction: row !important;
    text-align: center !important;
    align-items: center !important;
    color: #fff !important;
    font-size: 12px !important;
    border-radius: 8px !important;
    background-color: #7C596A !important;
    font-family: GTWalsheimProRegular !important;
    text-transform: capitalize !important;
    width: 65px !important;
    margin: 4px 6px 2px 5px !important;
    padding: 3px 0px !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.applyButton:hover {
    background-color: #4E253A !important;
    cursor: pointer;
}

.proPicDiv {
    display: flex;
    flex-direction: column;
}

.picUpDiv {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 15px;
    background-color: #F8F8F8;
    width: 15vw;
    border: solid #A040AB 0.5px !important;
}

.picStyle {
    height: 100%;
    max-width: 100%;
    background-color: #F9F2F2;
    float: left;
}

.warningMsg {
    display: flex;
    flex-direction: column;
    font-size: 10px;
    color: darkgrey;
    align-items: center;
    justify-content: center;
}

.upButtonsDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    margin-top: 20px;
}

.uploadButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FCD3D6;
    border: solid #FFFFFF 0.5px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.uploadButton:hover {
    cursor: pointer;
}

.deleteButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FCD3D6;
    border: solid #FFFFFF 0.5px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;
}

.deleteButton:hover {
    cursor: pointer;
}

.deleteButton1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FCD3D6;
    border: solid #FFFFFF 0.5px;
    padding: 2px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;
}

.deleteButton1:hover {
    cursor: pointer;
}

.picDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid #FFFFFF 0.5px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.pageButtonsDiv {
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.dumDiv {
    display: flex;
    flex-direction: column;
    background-color: #F8EFF5;
    height: 100%;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding-bottom: 10px;
}

.fieldInput {
    display: flex;
}

.divFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.addNewDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.addNewContButton {
    display: flex;
    background-color: #AA346F;
    color: #FFFFFF;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: solid #AA346F 0.5px;
    font-size: 12px;
    font-family: "GTWalsheimProRegular";
}

.addNewContButton:hover {
    cursor: pointer;
    background-color: #4E253A;
    border: solid #4E253A 0.5px;
}

.consentDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.consentLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-left: -20px;
    font-family: "GTWalsheimProRegular";
    font-size: 14px;
    font-weight: 400;
    width: 90%;
    color: #4E253A;
}

.consentContent {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 14px;
    background-color: #FFFFFF;
    padding: 0px;
    border: solid #AD7BB3 0.5px;
    width: 90%;
    overflow-wrap: break-word;
    max-height: 20vh;
    overflow-y: scroll;
    padding: 10px;
    font-style: italic;
}

.consentContent {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.consentContent::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.consentButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    column-gap: 20px;
    padding-bottom: 30px;
    margin-top: 20px;
}

.consentWarningDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: "GTWalsheimProRegular";
    font-size: 13px;
    font-weight: normal;
    color: red;
    font-style: italic;
    margin-bottom: 20px;
}

.editButtonDiv {
    display: flex;
    width: fit-content;
    height: fit-content;
}

.editButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 50px;
    margin-right: 5px;
    font-size: 12px;
    background-color: #D491B3;
    font-weight: normal !important;
    color: #FFFFFF;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.editButton:hover {
    background-color: #4E253A;
    color: #FFFFFF;
    cursor: pointer;
}

.editButtonClicked {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 50px;
    margin-right: 5px;
    margin-top: 0px;
    font-size: 12px;
    background-color: #FBD2E7;
    color: #FFFFFF;
    font-weight: normal !important;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.cancelButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    border: solid #AA346F 0.5px;
    color: #AA346F;
    font-size: 14px;
    width: 120px;
}

.cancelButton:hover {
    cursor: pointer;
    border: solid #4E253A 0.5px;
    color: #4E253A;
}

.saveButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #AA346F;
    border-radius: 5px;
    border: solid #AA346F 0.5px;
    color: #FFFFFF;
    font-size: 14px;
    width: 120px;
}

.saveButton:hover {
    cursor: pointer;
    background-color: #4E253A;
    border: solid #4E253A 0.5px;
}

.circularProgress {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    align-items: center;
}

.circularProgress svg {
    margin: 0 auto;
    display: block;
    color: #4E253A;
}
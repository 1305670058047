.responsive {
    overflow-x: auto;
    width: 100%;
    margin-bottom: 10px;
    /* border-bottom: 1px solid #cfd0d3; */
    padding: 10px;
}

.patientListContainer {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.patientListContainer::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.responsive1 {
    overflow-x: auto;
    width: 100%;
    /* border-bottom: 1px solid #cfd0d3; */
    padding-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.codeHeader {
    max-width: 8vw;
    min-width: 8vw;
    text-align: center;
    align-items: center;
    font-size: 0.8em;
    font-family: GTWalsheimProRegular;
    color: #ffffff;
    margin-bottom: 10px;
    cursor: pointer;
}

.typeHeader {
    max-width: 5vw;
    min-width: 5vw;
    text-align: center;
    align-items: center;
    font-size: 0.8em;
    font-family: GTWalsheimProRegular;
    color: #ffffff;
    margin-bottom: 10px;
    cursor: pointer;
}

.descHeader {
    max-width: 20vw;
    min-width: 20vw;
    text-align: center;
    align-items: center;
    font-size: 0.8em;
    font-family: GTWalsheimProRegular;
    color: #ffffff;
    margin-bottom: 10px;
    cursor: pointer;
}

.classHeader {
    max-width: 8vw;
    min-width: 8vw;
    text-align: center;
    align-items: center;
    font-size: 0.8em;
    font-family: GTWalsheimProRegular;
    color: #ffffff;
    margin-bottom: 10px;
    cursor: pointer;
}

.classHeader1 {
    max-width: 9vw;
    min-width: 9vw;
    text-align: center;
    align-items: center;
    font-size: 0.8em;
    font-family: GTWalsheimProRegular;
    color: #ffffff;
    margin-bottom: 10px;
    cursor: pointer;
}

.cdHeader {
    max-width: 8vw;
    min-width: 8vw;
    text-align: center;
    align-items: center;
    font-size: 0.8em;
    font-family: GTWalsheimProRegular;
    color: #ffffff;
    margin-bottom: 10px;
    cursor: pointer;
}

.headingContainer {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px !important;
    margin-right: 10px !important;
    background-color: #AA346F;
    color: #ffffff;
}

.headingContainer1 {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px !important;
    margin-right: 10px !important;
    background-color: #aa346f;
    color: #ffffff;
}

.iconsStyleSort {
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
}

.iconsStyleSort1 {
    color: #ffffff;
    height: 16px !important;
    width: 16px !important;
    font-size: 12px;
}

.patientIDHeader {
    max-width: 140px;
    min-width: 140px;
    text-align: center;
    align-items: center;
    font-size: 1.0em;
    font-family: "GTWalsheimProRegular";
    cursor: pointer;
}

.patientListContainer1 {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.patientListContainer1::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.circularProgress {
    display: flex;
    justify-content: center;
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    align-items: center;
    margin-top: 20px !important;
    margin-bottom: 40px !important;
    font-size: 16px !important;
    padding-bottom: 40px;
}

.circularProgress svg {
    display: block;
    color: #4E253A;
    margin: 0 auto;
}


.firstColumnContainer {
    max-width: 100px;
    min-width: 100px;
    text-align: center;
    font-size: 1.0em;
    font-family: "GTWalsheimProRegular";
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    align-items: center;
    padding: 3px;
}

.subjectIdDiv {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: center;
    background-color: #F5F6F8;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    margin-left: 25px;
    margin-right: 10px;
    padding: 10px;
}

.tableCellContents {
    max-width: 3vw;
    min-width: 3vw;
    background-color: #f7e3e6;
    /* max-width: 50px;
      min-width: 50px; */
    text-align: center;
    font-family: 'GTWalsheimProRegular';
    padding: 10px;
    min-height: 20px;
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.tableCellContentsNotes {
    max-width: 3vw;
    min-width: 3vw;
    background-color: #f7e3e6;
    /* max-width: 50px;
      min-width: 50px; */
    text-align: center;
    font-family: 'GTWalsheimProRegular';
    min-height: 20px;
    margin-left: 5px !important;
    margin-right: 5px !important;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.tableCellContentsCD {
    max-width: 7vw;
    min-width: 7vw;
    background-color: #f7e3e6;
    /* max-width: 50px;
      min-width: 50px; */
    text-align: center;
    font-family: 'GTWalsheimProRegular';
    padding: 10px;
    min-height: 20px;
    margin-left: 5px !important;
    margin-right: 5px !important;
}


.tableCellContentsCB {
    max-width: 9vw;
    min-width: 9vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    margin-bottom: 10px;
    border-radius: 1px;
}

.tableCellContentsCode {
    max-width: 8vw;
    min-width: 8vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    margin-bottom: 10px;
    border-radius: 1px;
}

.tableCellContentsDesc {
    max-width: 20vw;
    min-width: 20vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    margin-bottom: 10px;
    border-radius: 1px;
}

.tableCellContentsIcon {
    max-width: 5vw;
    min-width: 5vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    margin-bottom: 10px;
    border-radius: 1px;
}

.tableCellContentsIcon:hover {
    cursor: pointer;
}

.tableCellContentsRank {
    max-width: 8vw;
    min-width: 8vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    margin-bottom: 10px;
    border-radius: 1px;
}

.tableCellContentsClass {
    max-width: 8vw;
    min-width: 8vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    margin-bottom: 10px;
    border-radius: 1px;
}

.tableCellContentsOnsetDate {
    max-width: 8vw;
    min-width: 8vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    margin-bottom: 10px;
    border-radius: 1px;
}

.tableCellContentsFullName {
    max-width: 8vw;
    min-width: 8vw;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    font-family: GTWalsheimProRegular;
    margin-bottom: 10px;
    border-radius: 1px;
}

.mouthLabIdDiv {
    background-color: #F5F6F8;
    /* max-width: 50px;
      min-width: 50px; */
    text-align: center;
    font-size: 0.9em;
    font-family: 'GTWalsheimProRegular';
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    min-height: 20px;
    font-weight: bold;
    color: #4E253A;
}

.searchBar {
    position: relative;
    width: 100%;
    height: 36px;
    border-radius: 50px;
    font-size: 14px;
    box-shadow: 0px 0px 4px #ccc;
    font-style: italic;
    background-color: #ffffff;
}


.topDiv {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-top: 0px;
    padding: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 98%;
}

.cancelButWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    padding: 4px;
}

.filterDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'GTWalsheimProRegular' !important;
    float: right;
    column-gap: 10px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding-left: 6px;
    padding-right: 6px;
    height: 32px;
    margin-bottom: 2px;
    background-color: #ffffff;
}

.filterDiv:hover {
    cursor: pointer;
}

.filterDivSelected {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'GTWalsheimProRegular' !important;
    float: right;
    column-gap: 10px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding-left: 6px;
    padding-right: 6px;
    height: 32px;
    margin-bottom: 2px;
    background-color: #ffffff;
}

.filterDivLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
}

.filterBackdrop {
    position: absolute;
}

.wrapper {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    outline: none;
    width: fit-content;
    border-radius: 5px;
    align-items: center;
    overflow-x: hidden;
    position: absolute;
    /* -ms-transform: translate(-20%, -20%); */
    transform: translate(-50%, 2%);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.filtersDivText {
    font-family: 'GTWalsheimProRegular' !important;
    font-size: 0.8em !important;
    padding-bottom: 5px;
    column-gap: 5px;
    color: #4E253A;
    font-weight: bold;
    height: 30px;
    padding-left: 10px;
    margin-right: 0px !important;
    padding-top: 5px;
    padding-right: 15px;
    padding-left: 15px;
    white-space: nowrap;
}

.filtersDivTextSelected {
    font-family: 'GTWalsheimProRegular' !important;
    font-size: 12px !important;
    column-gap: 5px;
    color: #4E253A;
    font-weight: bold;
    background-color: #FDEAFF;
    padding-left: 10px;
    height: 30px;
    margin-right: 0px !important;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-right: 15px;
    padding-left: 15px;
    white-space: nowrap;
}

.tableDiv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reportsTableBody {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.reportsTableBody::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}


.tableCellContainer {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px !important;
    margin-right: 10px !important;
    border-radius: 1px;
    background-color: #f7e3e6;
    min-height: 2vh;
}

.tableContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
}



.paper {
    display: flex;
    flex-direction: column;
    background-color: #FFF6FC;
    outline: none;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 75vw;
    min-height: 70vh;
    max-height: 95vh;
    position: fixed;
    top: 50%;
    left: 50%;
    right: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.paper {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.paper::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.addDxHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #AA346F;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    font-family: "GTWalsheimProRegular";
    padding: 10px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    overflow: hidden;
}

.addDxBody {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    row-gap: 20px;
    height: 100%;
    overflow-y: auto;
}


.searchBar {
    position: relative;
    width: 90%;
    height: 36px;
    border-radius: 50px;
    font-size: 14px;
    box-shadow: 0px 0px 4px #ccc;
    font-style: italic;
    background-color: #ffffff;
}

.modal {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    position: relative;
    /* margin-left: 10%;
            margin-right: 90%;
            top: 50; */
}

.rowDiv {
    display: flex;
    flex-direction: row;
    column-gap: 1vw;
}

.rowDiv1 {
    display: flex;
    flex-direction: row;
    column-gap: 1vw;
}

.rowDiv2 {
    display: flex;
    flex-direction: column;
    width: 85%;
    justify-content: center;
}

.rowDiv3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.errMsgDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: red;
    font-style: italic;
    font-family: "GTWalsheimProRegular";
    margin-bottom: 15px;
    margin-bottom: 30px;
}

.buttonsDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 5vw;
    margin-top: 10px;

}

.fieldDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px;
}

.cancelButton {
    display: flex;
    color: #AA346F;
    border-radius: 20px;
    width: 6vw;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #FFFFFF;
    border: #AA346F 1px solid;
}

.cancelButton:hover {
    cursor: pointer;
}

.saveButton {
    display: flex;
    color: #FFFFFF;
    border-radius: 20px;
    width: 6vw;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #AA346F;
    border: #AA346F 1px solid;
}

.saveButton:hover {
    cursor: pointer;
}

.fieldDiv1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
}

.fieldLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-left: -1px;
    font-family: "GTWalsheimProRegular";
    font-size: 13px;
    font-weight: bold;
    color: #4e253a;
}

.fieldLabel1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-left: 20px;
    font-family: "GTWalsheimProRegular";
    font-size: 13px;
    font-weight: bold;
    color: #4e253a;
    width: 100%;
}

.fieldInput {
    display: flex;
}

.inputFieldSelcted {
    display: flex;
    height: 3.5vh;
    width: 26vw;
    border: solid #A040AB 0.5px;
    background-color: #FFFFFF;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
}

.inputFieldSelctedDisabled {
    display: flex;
    height: 3.5vh;
    width: 26vw;
    border: solid #A040AB 0.5px;
    background-color: #f8eff5;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
}

.dropdown {
    height: 2.5vh !important;
    width: 11.5vw !important;
    border: solid #A040AB 0.5px !important;
    background-color: #FFFFFF !important;
    padding: 3px 5px !important;
    font-family: "GTWalsheimProRegular" !important;
}

.checkBoxLabel {
    display: flex;
    font-size: 15px;
    font-family: "GTWalsheimProRegular";
    margin-left: 0px;
}

.inputFieldSelctedDOB {
    display: flex;
    height: 3.5vh;
    width: 11vw;
    border: solid #A040AB 0.5px;
    background-color: #FFFFFF;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
}

.inputFieldSelctedDOBDisabled {
    display: flex;
    height: 3.5vh;
    width: 11vw;
    border: solid #A040AB 0.5px;
    background-color: #f8eff5;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
}

.datePickerWrapper {
    /* margin-left: 190px; */
    border-radius: 10px;
    margin-right: auto;
    width: 20vw;
    overflow: auto;
    visibility: visible;
    color: #4E253A;
    z-index: 2;
    position: absolute;
    border: 2px solid #4E253A;
    display: flex;
    flex-direction: column;
    margin-top: 35px !important;
}

.applyButtonDiv {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    padding-bottom: 10px;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    margin-top: -5px;
}

.applyButton {
    vertical-align: middle !important;
    justify-content: center !important;
    /* display:flex !important; */
    flex-direction: row !important;
    text-align: center !important;
    align-items: center !important;
    color: #fff !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    background-color: #aa346f !important;
    font-family: GTWalsheimProRegular !important;
    text-transform: capitalize !important;
    width: 85px !important;
    margin: 4px 6px 2px 5px !important;
    padding: 3px 0px !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.applyButton:hover {
    background-color: #F14C00 !important;
    cursor: pointer;
}

.addNewNoteDiv {
    display: flex;
    flex-direction: row;
    position: relative;
    height: fit-content;
    align-items: center;
    column-gap: 0px;
}

.notesEditor {
    position: relative;
    width: 94% !important;
    padding-right: 10px !important;
    border-radius: 2px !important;
    font-size: 12px !important;
    align-items: start !important;
    justify-content: left !important;
    margin-left: 20px !important;
    padding-left: 10px !important;
    display: inline-block;
    overflow: hidden;
    font-family: GTWalsheimProRegular !important;
    background-color: #FFFFFF;
    border: solid #A040AB 0.5px;
    padding-top: 10px !important;
}

.mainWrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 74vw;
}

.cancelButton1 {
    vertical-align: middle !important;
    justify-content: center !important;
    /* display:flex !important; */
    flex-direction: row !important;
    text-align: center !important;
    align-items: center !important;
    color: #aa346f !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    background-color: #ffe0f0 !important;
    font-family: GTWalsheimProRegular !important;
    text-transform: capitalize !important;
    width: 85px !important;
    margin: 4px 6px 2px 5px !important;
    padding: 3px 0px !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.cancelButton1:hover {
    background-color: #ffffff !important;
    cursor: pointer;
}

.notesCharCount {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    font-family: "GTWalsheimProRegular";
    font-size: 10px;
    color: #4e253a;
    padding-top: 5px;
    padding-left: 20px;
}

.notesCharCountRed {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    font-family: "GTWalsheimProRegular";
    font-size: 10px;
    color: red;
    padding-top: 5px;
    padding-left: 20px;
}
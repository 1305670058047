.rootDiv {
    display: flex;
    flex-direction: column;
    overflow: hidden !important;
}

.containerDiv {
    /* width: 100%; */
    margin-left: 20px;
    align-items: center;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 5px;
    /* padding: 10px; */
    background-color: #ffffff;
    /* background-image: linear-gradient(to right, #5B5687, #E7E6ED); */
    /* background-image: linear-gradient(to right, #D4D3E1, #EEEEF4); */
    /* background-image: linear-gradient(to right, #C8C7D7, #EEEEF4); */
    border-radius: 10px;
}

.datePickerDiv {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: #FFE0F0;
    text-align: left;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    vertical-align: middle;
    align-items: center;
    box-shadow: 0px 1.446px 3.471px 1.446px rgba(0, 0, 0, 0.10);
}

.datePickerDiv svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
    color: #FFFFFF;
    background-color: #AA346F;
    padding: 5px;
    border-radius: 30px;
    align-self: center;
}

.dateInput {
    min-width: 220px;
    max-width: 600px;
    background-color: #FFE0F0;
    text-align: center;
    color: #AA346F;
    font-weight: 200;
    border-style: none;
    border-radius: 10px;
    outline: none;
    caret-color: #4E253A;
    font-family: GTWalsheimProRegular;
    font-size: 16px;
    text-align: left;
}

.exportButton {
    vertical-align: middle !important;
    justify-content: center !important;
    /* display:flex !important; */
    flex-direction: row !important;
    text-align: center !important;
    align-items: center !important;
    color: #fff !important;
    font-size: 13px !important;
    border-radius: 8px !important;
    background-color: #4E253A !important;
    font-family: GTWalsheimProRegular !important;
    text-transform: capitalize !important;
    width: 85px !important;
    margin: 4px 6px 2px 5px !important;
    padding: 3px 0px !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.exportButton svg {
    width: 23px;
    height: 23px;
    padding: 2px 0px 2px 2px;
    border-radius: 3px;
    background-color: inherit;
}

.exportButton:hover {
    background-color: #7C596A !important;
}

.datePickerWrapper {
    /* margin-left: 190px; */
    border-radius: 10px;
    margin-right: auto;
    width: fit-content;
    margin-right: auto;
    overflow: auto;
    visibility: visible;
    color: #4E253A;
    z-index: 2;
    position: absolute;
    border: 2px solid #4E253A;
    display: flex;
    flex-direction: column;
}

.brhrGraphsWrapper {
    padding: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin: 10px;
    background-color: #fbf4f7;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.aidiGraphWrapper {
    padding: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin: 10px;
    background-color: #fbf4f7;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.containerHeader {
    display: flex;
    flex-direction: row;
    background-color: #985C70;
    margin: -10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    justify-content: space-between;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin-bottom: 10px;
    padding: 0px;
}

.mainMessage {
    display: flex;
    font-size: large;
    font-weight: bold;
    font-family: "GTWalsheimProRegular";
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    padding: 10px;
}

.subMessage {
    display: flex;
    font-weight: normal;
    font-style: italic;
    font-family: "GTWalsheimProRegular";
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 10px;
}

.headingDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
}

.aidiGraphHeadingDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
    padding-left: 10px;
}

.baseline1LegendDiv {
    display: flex;
    flex-direction: row;
}

.baselineIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    font-weight: bold;
    /*background-color: #d7e4d7 ;
    background-color: #e1e8de;*/
    margin-right: 10px;
    margin-left: 10px;
    color: #000000;
    border-radius: 10px;
}

.baselineIconRec {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;
    font-weight: bold;
    /* /*background-color: #d7e4d7 ; */
    background-color: #a2d2ff;
    margin-right: 10px;
    color: black;
    margin-left: 10px;
}

.brLegendDiv {
    font-size: 15px;
    color: #F14C00;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.hrLegendDiv {
    font-size: 15px;
    color: #694FC0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.baselineLegendDiv {
    font-size: 15px;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.baselineLegendDivRec {
    font-size: 15px;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.legendDiv {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    padding: 8px;
    padding-left: 20px;
    background-color: #faeaea;
    margin: -10px;
    margin-bottom: 0px;
}

.tooltip {
    margin-top: 0;
    font-size: "12px";
    background-color: "#000000";
    font-family: "GTWalsheimProRegular";
}

.addBaselineButton {
    color: #FFFFFF !important;
    font-size: 14px !important;
    font-family: "GTWalsheimProRegular";
}

.expandAidiButton {
    color: #FFFFFF !important;
    font-size: 14px !important;
    font-family: "GTWalsheimProRegular";
}

.modal {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    position: relative;
    /* margin-left: 10%;
            margin-right: 90%;
            top: 50; */
}

.paperUnassignML {
    background-color: #fff;
    outline: none;
    border-radius: 20px;
    padding: 10px;
    align-items: center;
    width: 400px;
    /* height: 350px; */
    overflow-x: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.menuTitle {
    background-color: #ffff;
    display: flex;
    /* border-bottom: 1px solid gray; */
    text-align: center;
    width: 100%;
    align-items: center;
    font-size: 20px;
    font-family: "GTWalsheimProRegular";
}

.titleText {
    font-style: "GTWalsheimProRegular";
    font-weight: bold;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 28px;
    padding: 10px;
    color: #4E253A;
}

.patientListDiv {
    width: fit-content;
    padding: 10px;
    font-style: "GTWalsheimProRegular";
    font-size: 18px;
    font-weight: bold;
}

.subWarningDiv {
    margin-top: 8px;
    padding-top: 10px;
    font-family: "GTWalsheimProRegular";
    font-size: 13px;
    font-weight: normal;
    color: red;
    font-style: italic;
}

.menuButtons {
    position: relative;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: space-between;
}

.menuButtons2 {
    position: relative;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: center;
}

.abortButton {
    display: flex;
    padding: 10px;
    width: 120px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #8D919A;
    font-weight: 600;
    border: 1px solid #8D919A;
    border-radius: 10px;
    font-size: 16px;
    font-style: "GTWalsheimProRegular";
}

.abortButton:hover {
    cursor: pointer;
    /* background-color: #7C596A; */
    color: #4E253A;
    background-color: #F1D4D4;
    border: 1px solid #8D919A;
}

.continueButton {
    display: flex;
    padding: 10px;
    width: 120px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #4E253A;
    color: #ffffff;
    font-size: 16px;
    font-style: "GTWalsheimProRegular";
}

.continueButton:hover {
    cursor: pointer;
    background-color: #7C596A;
}

.addNewBaselineDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-right: 5px;
    min-height: 40px;
}

.labelDiv {
    /* Modify size here: */
    --size: 80px;

    position: relative;
    width: fit-content;
    height: fit-content;
    /* background: #faeaea; */
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
    font-family: "GTWalsheimProRegular";
    padding-top: 6px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 6px;
    margin-right: -1px;
    align-content: center;
    justify-content: center;
    color: #000;
}

.applyimage {
    background-image: url('../../../../Assets/Images/Baseline_background_image\ \(2\).png');
    /* Replace 'your-image-url.jpg' with the actual path to your image */
    background-size: cover;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
}


.circularProgress {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    align-items: center;
}

.promptDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.newBaselinePrompt {
    display: flex;
    flex-direction: row;
    width: fit-content;
    padding: 10px;
    background-color: #FFBCC2;
    margin-top: -9px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 18px;
    align-items: center;
    justify-content: center;
}

.newBaselinePrompt1 {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: fit-content;
    padding: 10px;
    background-color: #FFBCC2;
    margin-top: -9px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 18px;
    align-items: center;
    justify-content: center;
}

.promptMessage {
    display: flex;
    flex-direction: row;
}

.confMessageDiv {
    display: flex;
    flex-direction: row;
}

.confMessage {
    display: flex;
    flex-direction: row;
}

.baselineRecIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;
    font-weight: bold;
    background-color: #a2d2ff;
    color: black;
    margin-right: 5px;
    margin-left: 5px;
    height: 20px;
}

.yesButton {
    display: flex;
    flex-direction: row;
    column-gap: 3px;
    padding-top: 1px;
    padding-right: 6px;
    padding-left: 6px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: rgb(132, 212, 116);
    border-radius: 10px;
    align-items: center;
    justify-content: center;
}

.yesButton:hover {
    cursor: pointer;
}

.noButton {
    display: flex;
    flex-direction: row;
    column-gap: 3px;
    padding-top: 1px;
    padding-right: 6px;
    padding-left: 6px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: rgb(252, 93, 86);
    border-radius: 10px;
    align-items: center;
    justify-content: center;
}

.noButton:hover {
    cursor: pointer;
}

.circlesList {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;
    justify-content: center;
}

.tootlTipHeading {
    font-family: "GTWalsheimProRegular";
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 10px;
    font-style: italic;
}

.greenCircle {
    border-radius: 50px;
    height: 20px;
    width: 20px;
    background-color: #e1e8de;
}

.orangeCircle {
    border-radius: 50px;
    height: 20px;
    width: 20px;
    background-color: #FEF0DD;
}

.redCircle {
    border-radius: 50px;
    height: 20px;
    width: 20px;
    background-color: #fbdbde;
}

.circlesListItem {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    font-family: "GTWalsheimProRegular";
    font-size: 14px;
    font-style: italic;
    align-items: center;
    justify-content: center;
}

.mlIconWrapper {
    display: flex;
    flex-direction: row;
    background-color: #FFFFFF;
    border-radius: 50px;
    padding: 1px;
    margin-right: 10px;
    margin-left: 10px;
}

.applyButtonDiv {
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    justify-content: right;
    background-color: #FFFFFF;
}
.updateButtonBaseLine {
    margin-left: auto;
    margin-right: 2px;
    background-color:  #FFFFFF;
    font-family: GTWalsheimProRegular ;
    font-weight: 600;
    padding-left: 14px;
    font-size: 15px;
    color: #AA346F;
    border-radius: 40px;
    border: 0px;
    height: 30px;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.updateBaselineIcon {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    background-color: #AA346F;
    padding: 3px;
    border-radius: 50px;
    margin-left: 5px;
}

.updateButtonBaseLine:hover {
    cursor: pointer;
    color: white;
    background-color: #AA346F;
}

.applyButton {
    vertical-align: middle !important;
    justify-content: center !important;
    /* display:flex !important; */
    flex-direction: row !important;
    text-align: center !important;
    align-items: center !important;
    color: #fff !important;
    font-size: 15px !important;
    border-radius: 8px !important;
    background-color: #aa346f !important;
    font-family: GTWalsheimProRegular !important;
    text-transform: capitalize !important;
    width: 85px !important;
    margin: 4px 6px 2px 5px !important;
    padding: 3px 0px !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.applyButton:hover {
    background-color: #F14C00 !important;
    cursor: pointer;
}

.cancelButton {
    vertical-align: middle !important;
    justify-content: center !important;
    /* display:flex !important; */
    flex-direction: row !important;
    text-align: center !important;
    align-items: center !important;
    color: #aa346f !important;
    font-size: 15px !important;
    border-radius: 8px !important;
    background-color: #ffe0f0 !important;
    font-family: GTWalsheimProRegular !important;
    text-transform: capitalize !important;
    width: 85px !important;
    margin: 4px 6px 2px 5px !important;
    padding: 3px 0px !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.cancelButton:hover {
    background-color: #ffffff !important;
    cursor: pointer;
}

.MuiTab-wrapper {
    font-weight: bold !important;
}

.calErrorMsg {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: red;
    margin-right: auto;
    margin-left: auto;
}
  
  .add-edit-notes-container {
    width: 600px;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 10;
    height: 100%;
    background-color: #f3e8ee;
    border-radius: 8px;
    box-shadow: -6px 0px 8px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    font-family: var(--FontFamily);
  }
  
  .header {
    background-color: #f9f3f6;
    color: var(--DarkMaroon);
    border-radius: 24px 0 0 0 !important;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #ccc9c9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .close-icon {
    color: #888;
    font-size: 18px;
  }

  .close-button:hover {
    background-color: #AA346F !important;
  }

  .close-button:hover .close-icon{
    color: #FFFFFF;
  }
  
  .profile-details {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  
  .name-and-id {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 10px;
  }
  
  .name {
    font-size: 18px !important;
    font-weight: bold;
    margin-bottom: 4px;
  }
  
  .id {
    font-size: 14px !important;
    color: #666;
  }
  
  .notes-title {
    background-color: #f9f3f6;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    width: 200px;
    align-self: center;
    color: var(--DarkMaroon);
    text-align: center;
    border-radius: 0px 0px 12px 12px;
    margin-bottom: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .accordion-title {
    font-size: 17px !important;
    font-weight: 500 !important;
    display: flex;
    color: #4E253A !important;
    font-family: var(--FontFamily);
  }
  
  .custom-accordion {
    box-shadow: none;
    background-color: transparent;
    margin: 0; /* Remove margin around Accordion */
  }
  
  .accordion-summary {
    border-radius: 0;
    padding: 0 20px; /* Customize padding if needed */
    min-height: 48px !important;
  }
  
  .MuiAccordionSummary-content {
    margin: 0 !important;
    align-items: center;
  }
  
  .MuiAccordionSummary-expandIconWrapper {
    color: var(--DarkMaroon);
  }
  
  .MuiAccordion-root:before {
    display: none;
  }
  
  .MuiAccordionDetails-root {
    padding: 0;
  }
  
  .MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: none;
  }
  .MuiAccordionSummary-root {
    display: flex !important;
    padding: 0px 16px !important;
  }

  .icon-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    margin-right: 17px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }  

  .icon-container svg {
    height: 16px;
    width: 16px;
    fill: rgb(170 52 111) !important;
  }
  
  /* TextBoxWithButtons Component */
  .textbox-container {
    position: relative;
    margin-bottom: 0px !important;
    padding: 20px;
    font-family: var(--FontFamily);
  }
  
  .textarea-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .note-textarea {
    height: 300px;
    padding: 10px;
    border: 1px solid #ddd;
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    resize: none;
    font-size: 15px !important;
    font-family: var(--FontFamily);
    color: var(--DarkMaroon);
    outline: none;
  }

  .note-textarea::placeholder {
    color: #bdb4b9;
    font-style: italic;
  }

  .fromNotesTime {
    position: absolute;
    left: 10px;
    bottom: 11px;
  }
  
  .character-count {
    text-align: right;
    font-size: 12px;
    color: var(--DarkMaroon);
    background-color: #ffffff;
    padding: 5px 10px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
  
  .status-buttons-container {
    display: flex;
    align-items: center;
    height: 35px;
  }
  
  .status-button {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #f6eff4;
    color: var(--Maroon);
    font-weight: bold;
    cursor: pointer;
    border-radius: 0;
    transition: background-color 0.3s ease;
    font-family: var(--FontFamily);
    letter-spacing: 0.7px;
  }
  
  .status-button:first-child {
    border-radius: 0 0 0 8px;
  }
  
  .status-button.selected {
    color: #ffffff;
  }
  
  .bookmark-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px !important;
    width: 35px;
    border: 1px solid #ddd;
    border-radius: 0 0 8px 0px;
    background-color: #f6eff4;
  }
  
  .bookmark-icon {
    color: #87668b;
    height: 20px;
    width: 20px;
  }
  
  /* Action Buttons */
  .action-buttons-container {
    display: flex;
    justify-content: space-between;
    align-self: center;
    margin-top: 0px !important;
    margin-bottom: 18px;
  }
  
  .cancel-button,
  .submit-button {
    width: 150px;
    padding: 10px;
    border-radius: var(--ButtonRadius);
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: var(--FontFamily);
    font-size: 15px;
  }
  
  .cancel-button {
    margin-right: 20px;
    border: var(--BorderWidth) solid var(--Maroon);
    background-color: var(--LightPink);
    color: var(--Maroon);
  }
  
  .cancel-button:hover {
    background-color: var(--HoverPink);
  }
  
  .submit-button-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .submit-button {
    border: none;
    background-color: var(--Maroon);
    color: #ffffff;
  }
  
  .submit-button:disabled {
    background-color: #ddd;
    color: #a3a3a3;
    cursor: not-allowed;
  }
  
  .submit-button:hover:not(:disabled) {
    background-color: #8b4260;
  }
  .content-container{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    overflow-y: auto;
  }
  .accordion-details{
    display: flex;
    flex-direction: column;
    padding: 0 0 8px 0 !important;
  }
  
  .icon{  
    margin-right: 10px
  }
.containerDiv {
    display: flex;
    flex-direction: column;
    width: 97%;
    height: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.containerDiv1 {
    display: flex;
    flex-direction: column;
    width: 97%;
    height: 100%;
    margin-bottom: 10px;
}

.containerDiv2 {
    display: flex;
    flex-direction: column;
    width: 97%;
    height: 100%;
    margin-bottom: 120px;
}

.fieldInput {
    display: flex;
}

.headerDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #FFE4F2;
    padding: 8px;
    font-family: "GTWalsheimProRegular";
    font-size: 15px;
    font-weight: 700;
    color: #4E253A;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.contentDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: #F8EFF5;
}

.column1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.8vw;
}

.column2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.8vw;
}

.column3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.8vw;
}

.column4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.8vw;
}

.column31 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.8vw;
}

.column41 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.8vw;
}

.fieldDiv1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px;
}

.fieldName {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-left: -1px;
    font-family: "GTWalsheimProRegular";
    font-size: 14px;
    font-weight: 400;
    color: #4E253A;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.113px;
}

.fieldName2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-left: -1px;
    font-family: "GTWalsheimProRegular";
    font-size: 14px;
    font-weight: 400;
    color: #4E253A;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.113px;
}

.circlesListItem {
    font-size: 11px;
    font-weight: bold;
    font-family: "GTWalsheimProRegular";
}

.dropdown2 {
    height: 31px !important;
    width: 15.7vw !important;
    border: solid #A040AB 0.5px !important;
    background-color: #FFEEF6 !important;
    padding: 0px 5px !important;
    opacity: 1 !important;
    text-align: left;
}

.dropdown2E {
    height: 31px !important;
    width: 15.7vw !important;
    border: solid #A040AB 0.5px !important;
    background-color: #FFFFFF !important;
    padding: 0px 5px !important;
    opacity: 1 !important;
    text-align: left;
}

.dropdownReq2 {
    height: 31px !important;
    width: 15.7vw !important;
    border: solid red 1.5px !important;
    background-color: #F8F8F8 !important;
    padding: 0px 5px !important;
    font-family: "GTWalsheimProRegular" !important;
    opacity: 1 !important;
    text-align: left;
}

.inputFieldSelctedE {
    display: flex;
    height: 32px;
    width: 15vw;
    border: solid #A040AB 0.5px;
    background-color: #FFFFFF;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
    border-radius: 2px;
}

.inputFieldSelcted {
    display: flex;
    height: 32px;
    width: 15vw;
    border: solid #A040AB 0.5px;
    background-color: #FFEEF6;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
    border-radius: 2px;
}

.inputFieldSelctedReq {
    display: flex;
    height: 32px;
    width: 15vw;
    border: solid red 1.5px;
    background-color: #F8F8F8;
    font-size: 15px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
    border-radius: 2px;
}

.dumDiv {
    display: flex;
    flex-direction: column;
    background-color: #F8EFF5;
    height: 100%;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding-bottom: 10px;
}

.pageButtonsDiv {
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
}

.backButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #F8F8F8;
    border-radius: 5px;
    border: solid #AD7BB3 0.5px;
    color: #AD7BB3;
    font-size: 14px;
    width: 120px;
}

.backButton:hover {
    cursor: pointer;
}

.consentWarningDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: "GTWalsheimProRegular";
    font-size: 13px;
    font-weight: normal;
    color: red;
    font-style: italic;
    margin-bottom: 20px;
}

.editButtonDiv {
    display: flex;
    width: fit-content;
    height: fit-content;
}

.editButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 50px;
    margin-right: 5px;
    font-size: 12px;
    background-color: #D491B3;
    font-weight: normal !important;
    color: #FFFFFF;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.editButton:hover {
    background-color: #4E253A;
    color: #FFFFFF;
    cursor: pointer;
}

.editButtonClicked {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 50px;
    margin-right: 5px;
    margin-top: 0px;
    font-size: 12px;
    background-color: #FBD2E7;
    color: #FFFFFF;
    font-weight: normal !important;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.cancelButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    border: solid #AA346F 0.5px;
    color: #AA346F;
    font-size: 14px;
    width: 120px;
}

.cancelButton:hover {
    cursor: pointer;
    border: solid #4E253A 0.5px;
    color: #4E253A;
}

.saveButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #AA346F;
    border-radius: 5px;
    border: solid #AA346F 0.5px;
    color: #FFFFFF;
    font-size: 14px;
    width: 120px;
}

.saveButton:hover {
    cursor: pointer;
    background-color: #4E253A;
    border: solid #4E253A 0.5px;
}

.circularProgress {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    align-items: center;
}

.circularProgress svg {
    margin: 0 auto;
    display: block;
    color: #4E253A;
}
.dateTimePickerDiv {
    font-family: "GTWalsheimProRegular" !important;
    display: flex;
    flex-direction: row;
    background-color: #FFF6FB;
    padding: 7px 0;
    justify-content: space-between;
}

.date-selector div {
    border-radius: 0px 109.107px 109.107px 0px;
    padding-right: 10px;
    color: #AA346F;
    background: white;
}

.date-selector fieldset {
    border-radius: 0px 109.107px 109.107px 0px;
    box-shadow: 0px 0px 7.935px 0px rgba(0, 0, 0, 0.15);
    border: none;
}

.date-selector input {
    padding: 10px !important;
    color: #AA346F;
}

.time-pill, .right-pill {
    border-radius: 109.107px;
    box-shadow: 0px 0px 7.935px 0px rgba(0, 0, 0, 0.15);
    padding: 0px 4px;
    display: flex;
    align-items: center;
}

.right-pill {
    border-radius: 99.188px 0px 0px 99.188px !important;
    background: #FCE5F0 !important;
    width: 118px;
}

.right-pill div {
    background: #FCE5F0 !important;
}

.time-pill input {
    width: 56px !important;
    color: inherit;
}

.right-pill input {
    width: 20px !important;
    padding: 5px 0px !important;
    color: inherit !important;
    pointer-events: none;
}

.right-pill span {
    font-size: 13px;
    color: rgb(174, 155, 164);
    font-weight: 500;
    margin-bottom: -0.32px;
}

.time-pill div, .right-pill div {
    padding-right: 0 !important;
}

.time-pill div:not(.startTimePickerDiv):not(.endTimePickerDiv), .right-pill div:not(.startTimePickerDiv) {
    color: inherit !important;
}

.time-pill fieldset, .right-pill fieldset {
    border: none;
    border-bottom: 1px solid #AE9BA4;
    border-radius: 0;
    width: 55px;
    bottom: 5px;
}

.right-pill fieldset { 
    border-bottom: none;
}

 .time-pill svg {
    height: 20px; 
    width: 20px; 
}

.date-selector svg {
    height: 15px;
    width: 15px;
    padding: 7.5px; 
    color: #AA346F;
    background: white;
}

.dateTimePickerDiv input {
    font-size: 13px;
    font-family: GTWalsheimProRegular;
    font-weight: bold;
    padding: 10px;
    width: 70px;
}

.dateTimePickerDiv button {
    height: 20px; 
    width: 20px;
    margin-left: -10px; 
}

.dateTimePickerDiv label {
    font-size: 14px;
    font-family: GTWalsheimProRegular;
    font-weight: 600;
}

.date-selector svg, .right-pill svg, .time-pill svg:first-child, .time-pill svg:last-child {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: #FCF4F6;
    border-radius: 50%;
    height: 20px; 
    width: 20px; 
}

.right-pill svg {
    background-color: #FFF;
    margin-right: 9px;
}

.timePicker {
    text-align: center;
}

.time-pill-container {
    perspective: 1000px;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    background: #FFF;
    position: relative;
    height: 38px;
    width: 234px;
    border-radius: 109.107px;
  }
  .time-pill-container.flip {
    transform: rotateX(180deg);
  }

  .time-pill {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    position: absolute;
    background-color: #FFF;
  }
  
  .flip-card-front {
    z-index: 2;
  }
  
  .flip-card-back {
    background: #AA346F;
    color: white;
    transform: rotateX(180deg);
    justify-content: center;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  .disabledTag {
    background: #F2F2F2 !important; 
    color: #C0C0C0 !important; 
    cursor: not-allowed !important;
  }
  
/* TABLE HEADER STYLES */

.responsive {
  overflow-x: auto;
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 1px solid #cfd0d3;
  padding: 10px;
}

.header {
  display: flex;
  align-items: center;
}

.tableHeadingRow {
  margin-bottom: 20px;
}


.sidHeader {
  max-width: 95px;
  min-width: 95px;
  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
  color: #292f4c;
  margin-bottom: 10px;
}

.nameHeader {
  max-width: 95px;
  min-width: 95px;
  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
  color: #292f4c;
  margin-bottom: 10px;
}

.mlIdHeader {
  max-width: 90px;
  min-width: 90px;
  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
}

.ehrIdHeader {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
}

.genderHeader {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
}

.ageHeader {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
}

.durationHeader {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
  cursor: pointer;
}

.elapsedTimeHeader {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
  cursor: pointer;
}

.locationHeader {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
}

.diagnosisHeader {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: "GTWalsheimProRegular";
}

.goalsHeader {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: "GTWalsheimProRegular";
  cursor: pointer;
}

.statusHeader {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: "GTWalsheimProRegular";
}

.caseManagerHeader {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: "GTWalsheimProRegular";
}

.buttonTabHeader {
  width: 50px;
  text-align: center;
  font-size: 1.0em;
}

.iconsStyleSort {
  color: #353b48;
  margin-top: auto;
  margin-bottom: auto;

  margin-left: 5px;
}

/* TABLE HEADER STYLES */

/* SEARCH BAR STYLE */

.searchInput {
  margin-left: 10px;
  color: #353b48;
  border: 0;
  outline: 0;
  background: none;
  width: 100%;
  caret-color: white;
  font-family: "GTWalsheimProRegular";
  font-size: 15px;
  caret-color: #29304c;
}

.searchInput::placeholder {
  /* color: white; */
  color: #353b48;
  font-family: "GTWalsheimProRegular" light;
}

.searchbar:focus {
  background-color: cyan;
}

.searchInput:focus {
  color: #29304c;
  caret-color: #29304c;
}

.searchIcon {
  height: 35px;
  width: 35px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  text-decoration: none;
  color: #4E253A;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;
  margin-left: auto;
}

/* SEARCH BAR STYLE */
.searchBar {
  position: relative;
  width: 96%;
  height: 40px;
  padding:10px 4px 10px 4px;
  border-radius: 50px;
  font-size: 16px;
  box-shadow: 0px 0px 4px #ccc;
  margin-right: 10px;
}

.noSubjects {
  margin: 60px 20px;
  font-family: GTWalsheimProRegular;
  font-size: 22px;
  font-weight: bold;
  color: #4E253A;
  text-align: center;
}

.noSubjects1 {
  font-family: GTWalsheimProRegular;
  font-size: 22px;
  font-weight: bold;
  color: #4E253A;
  text-align: center;
  margin-top: 40px;
}

.noSubjects2 {
  font-family: GTWalsheimProRegular;
  font-size: 22px;
  font-weight: bold;
  color: #4E253A;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 80px;
}

.errorMsgsDiv {
  display: flex;
  flex-direction: column;
}
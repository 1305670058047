/* Date Picker Custom Input Style */

.containerDiv {
    /* width: 100%; */
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    /* padding: 10px; */
    background-color: #ffffff;
    border-radius: 10px;
}

.datePickerDiv {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #FFE0F0;
    text-align: left;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;
}

.datePickerDiv svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
    color: #FFFFFF;
    background-color: #AA346F;
    padding: 8px;
    border-radius: 30px;
}

.exportButton {
    vertical-align: middle !important;
    justify-content: center !important;
    /* display:flex !important; */
    flex-direction: row !important;
    text-align: center !important;
    align-items: center !important;
    color: #fff !important;
    font-size: 13px !important;
    border-radius: 3px !important;
    background-color: #7C596A !important;
    font-family: GTWalsheimProRegular !important;
    text-transform: capitalize !important;
    width: 85px !important;
    margin: 7px !important;
    padding: 2px 0px !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.exportButton svg {
    width: 23px;
    height: 23px;
    padding: 2px 0px 2px 2px;
    border-radius: 3px;
    background-color: inherit;
}

.exportButton:hover {
    background-color: #4E253A !important;
}

.dateInput {
    min-width: 220px;
    max-width: 600px;
    background-color: #FFE0F0;
    text-align: center;
    color: #4E253A;
    font-weight: 200;
    border-style: none;
    border-radius: 10px;
    outline: none;
    caret-color: #4E253A;
    font-family: GTWalsheimProRegular;
    font-size: 17px;
    padding: 10px 10px 6px 10px;
}

.datePickerWrapper {
    /* margin-left: 190px; */
    border-radius: 10px;
    margin-right: auto;
    width: fit-content;
    margin-right: auto;
    overflow: auto;
    visibility: visible;
    color: #4E253A;
    z-index: 2;
    position: absolute;
    border: 2px solid #4E253A;
}

.monthPlaceholder {
    background-color: #322F4B;
    /* padding: 15px 15px 15px 15px; */
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
}

.iconStyles {
    color: #fff;
}

.applyButtonDiv {
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    justify-content: right;
    background-color: #FFFFFF;
}

.applyButton {
    vertical-align: middle !important;
    justify-content: center !important;
    /* display:flex !important; */
    flex-direction: row !important;
    text-align: center !important;
    align-items: center !important;
    color: #fff !important;
    font-size: 15px !important;
    border-radius: 8px !important;
    background-color: #aa346f !important;
    font-family: GTWalsheimProRegular !important;
    text-transform: capitalize !important;
    width: 85px !important;
    margin: 4px 6px 2px 5px !important;
    padding: 3px 0px !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.applyButton:hover {
    background-color: #F14C00 !important;
    cursor: pointer;
}

.cancelButton {
    vertical-align: middle !important;
    justify-content: center !important;
    /* display:flex !important; */
    flex-direction: row !important;
    text-align: center !important;
    align-items: center !important;
    color: #aa346f !important;
    font-size: 15px !important;
    border-radius: 8px !important;
    background-color: #ffe0f0 !important;
    font-family: GTWalsheimProRegular !important;
    text-transform: capitalize !important;
    width: 85px !important;
    margin: 4px 6px 2px 5px !important;
    padding: 3px 0px !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.cancelButton:hover {
    background-color: #ffffff !important;
    cursor: pointer;
}

.expColButtons {
    /* position: absolute; */
    width: 100%;
    right: 40px;
    display: flex;
    align-items: center;
    justify-content: right;
    flex-direction: row;
    column-gap: 10px;
    padding-top: 6px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 10px;
}

.expAllButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: "GTWalsheimProRegular";
    color: #FFFFFF;
    font-size: 14px;
    border-radius: 22.308px;
    border: 1px solid #FFFCFF;
    background-color: #AA346F;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.expAllButton:hover {
    background-color: #F14C00;
    cursor: pointer;
}

.colAllButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: "GTWalsheimProRegular";
    color: #FFFFFF;
    font-size: 14px;
    border-radius: 22.308px;
    border: 1px solid #FFFCFF;
    background-color: #AA346F;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.colAllButton:hover {
    background-color: #F14C00;
    cursor: pointer;
}

.circularProgress {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    align-items: center;
    color: #4E253A;
}
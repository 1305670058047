/* Date Picker custom input style */
.labelDivStyles {
  text-align: left;
  align-items: center;
  display: flex;
  /* margin-left: 10px; */
}

.inputStyle {
  min-width: 100px;
  max-width: 120px;
  background-color: #5F5C70;
  text-align: center;
  color: #fff;
  font-weight: 200;
  border-style: none;
  outline: none;
  caret-color: #fff;
  font-family: GTWalsheimProRegular;
  font-size: 15px;
  /* padding: 15px 15px 15px 15px; */
  padding: 10px 10px 10px 10px;
}

.inputStyle:focus {
  border-radius: 17px;
  background-color: #5F5C70;
  border-color: #5F5C70;
}

.inputStyle::placeholder {
  color: #8c8490;
  font-size: 1.2em;
  font-style: italic;
  font-size: 15px;
}

/* Date Picker Custom Input Style */

.containerDiv {
  /* width: 100%; */
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  /* padding: 10px; */
  background-color: #ffffff;
  /* background-image: linear-gradient(to right, #5B5687, #E7E6ED); */
  /* background-image: linear-gradient(to right, #D4D3E1, #EEEEF4); */
  /* background-image: linear-gradient(to right, #C8C7D7, #EEEEF4); */
  border-radius: 10px;
}

.datePickerDiv {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #FFE0F0;
  text-align: left;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.datePickerDiv svg {
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: #FFFFFF;
  background-color: #AA346F;
  padding: 8px;
  border-radius: 30px;
}

.exportButton {
  vertical-align: middle !important;
  justify-content: center !important;
  /* display:flex !important; */
  flex-direction: row !important;
  text-align: center !important;
  align-items: center !important;
  color: #fff !important;
  font-size: 13px !important;
  border-radius: 3px !important;
  background-color: #7C596A !important;
  font-family: GTWalsheimProRegular !important;
  text-transform: capitalize !important;
  width: 85px !important;
  margin: 7px !important;
  padding: 2px 0px !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.exportButton svg {
  width: 23px;
  height: 23px;
  padding: 2px 0px 2px 2px;
  border-radius: 3px;
  background-color: inherit;
}

.exportButton:hover {
  background-color: #4E253A !important;
}

.dateInput {
  min-width: 220px;
  max-width: 600px;
  background-color: #FFE0F0;
  text-align: center;
  color: #4E253A;
  font-weight: 200;
  border-style: none;
  border-radius: 10px;
  outline: none;
  caret-color: #4E253A;
  font-family: GTWalsheimProRegular;
  font-size: 17px;
  padding: 10px 10px 6px 10px;
}

.datePickerWrapper {
  /* margin-left: 190px; */
  border-radius: 10px;
  margin-right: auto;
  width: fit-content;
  margin-right: auto;
  overflow: auto;
  visibility: visible;
  color: #4E253A;
  z-index: 2;
  position: absolute;
  border: 2px solid #4E253A;
}

.monthPlaceholder {
  background-color: #322F4B;
  /* padding: 15px 15px 15px 15px; */
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
}

.iconStyles {
  color: #fff;

}

.RangeIndicator {
  color: #793f98;
  font-size: 20px;
  font-family: GTWalsheimProRegular;
  align-items: center;
}

.partitionLine {
  border: 1px solid #fff;
  height: 10px;
}

.buttonDiv {
  /* margin-left: 10px;
  margin-right: 10px; */
  text-align: center;
}

.submitButton {
  min-width: 100px;
  background-color: #4E253A;
  color: white;
  border-style: none;
  border-width: 10px;
  border-radius: 10px;
  /* padding: 15px 15px 15px 15px; */
  padding: 10px 10px 10px 10px;
  outline: none;
  font-family: GTWalsheimProRegular;
  font-size: 16px;
}

.submitButton:hover {
  background-color: #4E253A;
  cursor: pointer;
}

.tableContainerStyles {
  /* padding: 20px; */
  margin: 20px;

}

.labelStyles {
  text-transform: none;
  font-size: 20px;
  font-family: GTWalsheimProRegular;
  /* color: blueviolet; */
  color: #793f98;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.IconContainer {}

.IconMUIContainer {
  background-color: #fff;
  color: red;
}

.IconMUIContainer:hover {
  background-color: aqua;
}

.applyButtonDiv {
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  justify-content: right;
  background-color: #FFFFFF;
}

.applyButton {
  vertical-align: middle !important;
  justify-content: center !important;
  /* display:flex !important; */
  flex-direction: row !important;
  text-align: center !important;
  align-items: center !important;
  color: #fff !important;
  font-size: 15px !important;
  border-radius: 8px !important;
  background-color: #aa346f !important;
  font-family: GTWalsheimProRegular !important;
  text-transform: capitalize !important;
  width: 85px !important;
  margin: 4px 6px 2px 5px !important;
  padding: 3px 0px !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.applyButton:hover {
  background-color: #F14C00 !important;
  cursor: pointer;
}

.cancelButton {
  vertical-align: middle !important;
  justify-content: center !important;
  /* display:flex !important; */
  flex-direction: row !important;
  text-align: center !important;
  align-items: center !important;
  color: #aa346f !important;
  font-size: 15px !important;
  border-radius: 8px !important;
  background-color: #ffe0f0 !important;
  font-family: GTWalsheimProRegular !important;
  text-transform: capitalize !important;
  width: 85px !important;
  margin: 4px 6px 2px 5px !important;
  padding: 3px 0px !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.cancelButton:hover {
  background-color: #ffffff !important;
  cursor: pointer;
}
html * {
  -webkit-font-smoothing: antialiased;
}

body {
  background: #fff !important;
  font-family: "GTWalsheimProRegular";
}



/* Responsive table */
.responsiveTable {
  overflow-x: auto;
  width: 100%;
  /* border: 2px solid black; */
}

/* Header div styles */
.content {
  display: flex;
  align-items: center;
  font-family: GTWalsheimProRegular;
}

.name .nameDiv {
  margin-left: 20px;
  background-color: #f5f6f8;
  color: black;
  display: flex;
  align-items: center;
}

.name {
  height: 50px;
  max-width: 200px;
  min-width: 200px;
  text-align: left;
  border-left: 5px solid #ef476f;
  background-color: #f5f6f8;
  font-size: 15em;
  font-family: GTWalsheimProRegular;
}

.name:hover .navlinkStyle {
  color: #0353a4;
  cursor: pointer;
}

/* Moderate risk patient name*/
.nameMod .nameModDiv {
  margin-left: 20px;
  background-color: #f5f6f8;
  color: black;
  display: flex;
  align-items: center;
}

.nameMod {
  height: 50px;
  max-width: 200px;
  min-width: 200px;
  text-align: left;
  border-left: 5px solid #ff9893;
  background-color: #f5f6f8;
  font-size: 15px;
  font-family: GTWalsheimProRegular;
}

.nameMod:hover .navlinkStyle {
  color: #0353a4;
  cursor: pointer;
}

/* Stable risk patient name*/
.nameStable .nameStableDiv {
  margin-left: 20px;
  background-color: #f5f6f8;
  color: black;
  display: flex;
  align-items: center;
}

.nameStable {
  height: 50px;
  max-width: 150px;
  min-width: 150px;
  text-align: left;
  background-color: #f5f6f8;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
  border-left: 5px solid #7edcb1;
  border-top: 5px solid black;
  border-bottom: 5px solid black;
  border-image: linear-gradient(to right, rgb(212, 33, 33), rgb(253, 253, 253), rgb(253, 253, 253), rgb(253, 253, 253), rgb(253, 253, 253), rgb(253, 253, 253), rgb(253, 253, 253), rgb(253, 253, 253), rgb(253, 253, 253), rgb(253, 253, 253)) 1;
}

.nameStable:hover .navlinkStyle {
  color: #0353a4;
  cursor: pointer;
}

.mlId {
  max-width: 50px;
  min-width: 50px;
  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
}

.gender {
  max-width: 50px;
  min-width: 50px;
  text-align: center;
  background-color: #f5f6f8;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
}

.age {
  max-width: 50px;
  min-width: 50px;
  text-align: center;
  background-color: #f5f6f8;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
}

.duration {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  background-color: #f5f6f8;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
}

.siteID {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  background-color: #f5f6f8;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
}

.diagnosis {
  max-width: 150px;
  min-width: 150px;
  text-align: center;
  background-color: #f5f6f8;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
}

.goalsAchieved {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  background-color: #eaf6f4;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
}

.goalsNotAchieved {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  background-color: #fde5ea;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
}

.status {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  background-color: #fe607f;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
}

.caseManager {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  background-color: #f5f6f8;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
}

.program {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  background-color: #cbdfbd;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
}

.buttonTab {
  width: 50px;
  text-align: center;
  background-color: #292f4c;
  font-size: 1.0em;
  border-radius: 8px;
  font-family: GTWalsheimProRegular;
}

/* Button stle */
.buttonStyles {
  color: white;
  border: none;
  background: none;
  background-color: #292f4c;
  font-size: 1.0em;
  align-items: center;
  font-family: GTWalsheimProRegular;
  cursor: pointer;
  outline: none;
  margin-top: 5px;
}

/* .buttonTab:hover .buttonStyles {
    background-color: #535454;
    color: white;
  }
  
  .buttonTab:hover {
    background-color: #535454;
  } */

.chatIconNameTable {
  margin-left: auto;
  /* margin-right: 10px; */
  align-items: center;
  /* margin-top: 5px; */
  color: #e9ecef;
}

.notesButton {
  color: #ced4da;
}

/* Modal Menu styling */

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  background-color: white;
  outline: none;
  border-radius: 10px;
  padding: 10px 0px 10px 0px;
  align-items: center;
  width: 1000px;
  height: 500px;
  overflow-x: auto;
}

.menuTitle {
  display: flex;
  /* border-bottom: 1px solid gray; */
  text-align: center;
  width: 100%;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  font-family: GTWalsheimProRegular;
  align-items: center;
  margin-bottom: 10px;
}

.titleText {
  font-style: GTWalsheimProRegular;
  font-weight: bold;
  margin-left: auto;

  margin-top: auto;
  margin-bottom: auto;
  font-size: 17px;
  padding: 10px;
  text-align: center;
}

.notesContainer {
  background-color: #f4f7fd;
  height: 330px;
  width: 100%;
}

.textarea {
  background-color: white;
  height: 120px;
}

.tooltip {
  font-size: 16px;
  font-family: GTWalsheimProRegular;
}

.closeIconTitle {
  margin-left: auto;
  margin-right: 20px;
  margin-bottom: auto;
}

/* 
  .hourElapsed{
    max-width: 100px;
    min-width: 100px;
    text-align: center;
    background-color: #f5f6f8;
    font-size: 1.0em;
    font-family: GTWalsheimProRegular;
    
    border-right: 5px solid #7edcb1;
    border-top: 5px solid black;
    border-bottom:  5px solid black;
    border-image: linear-gradient(to left, rgb(248, 79, 57),rgb(245, 246, 248),rgb(245, 246, 248),rgb(245, 246, 248) ,rgb(245, 246, 248),rgb(245, 246, 248),rgb(245, 246, 248)) 1;
  } */

/*  NEW CSS */

.firstColumnContainer {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  background-color: #f5f6f8;
  font-size: 1.0em;
  font-family: "GTWalsheimProRegular";
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background-image: linear-gradient(to right, rgb(128, 89, 106), rgb(245, 246, 248) 13%);
  align-items: center;
  padding: 3px;
}

.selectedfirstColumn {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  background-color: #0cf005;
  font-size: 1.0em;
  font-family: "GTWalsheimProRegular";
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background-image: linear-gradient(to right, rgb(78, 37, 58), rgb(245, 246, 248) 13%);
  align-items: center;
  padding: 3px;
  box-shadow: 0 8px 8px -8px #4E253A;
  background-color: #FEF4F9;
}

.selectedfirstColumn div, .selectedLastColumnContainer div, .selectedTableCellContainer div:not(.excludeHover) {
  background-color: #FEF4F9;
}

.lastColumnContainer {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  background-color: #f5f6f8;
  font-size: 1.0em;
  font-family: "GTWalsheimProRegular";
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-image: linear-gradient(to left, rgb(128, 89, 106), rgb(245, 246, 248) 13%);
  align-items: center;
  padding: 3px;
}

.selectedLastColumnContainer {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  background-color: #f5f6f8;
  font-size: 1.0em;
  font-family: "GTWalsheimProRegular";
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-image: linear-gradient(to left, rgb(78, 37, 58), rgb(245, 246, 248) 13%);
  align-items: center;
  padding: 3px;
  box-shadow: 0 8px 8px -8px #4E253A;
  background-color: #FEF4F9;
}


.tableCellContainer {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  background-color: #f5f6f8;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
  align-items: center;
  /* padding: 5px;  */
}

.selectedTableCellContainer {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  background-color: #f5f6f8;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
  align-items: center;
  /* padding: 5px;  */
  box-shadow: 0 8px 8px -8px #4E253A;
  background-color: #FEF4F9;
}

.statusTableCellContainer {
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  background-color: #d9eeeb;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
  align-items: center;
  /* padding: 5px;  */
}

.subjectIdDiv {
  border-radius: 5px;
  text-align: center;
  background-color: #F5F6F8;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /*width:90%;  some width */
}

/* style to hide the anchor tag blue color and underline effect using css */
.navlinkStyle {
  color: inherit;
  text-decoration: none;
  font-family: GTWalsheimProRegular;
  white-space: nowrap;         
  overflow: hidden;            
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.navlinkStyle :hover {
  color: #0353a4;
  cursor: pointer;
}


.firstColumnContainer:hover .navlinkStyle {
  color: #0353a4;
  cursor: pointer;
}


.mouthLabIdDiv {
  background-color: #fff;
  /* max-width: 50px;
      min-width: 50px; */
  text-align: center;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  border: 2px solid #F5F6F8;
}


.tableCellContents {
  background-color: #F5F6F8;
  /* max-width: 50px;
      min-width: 50px; */
  text-align: center;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 92%;
  /* some width */
}

.hourElapsedDiv {
  /* max-width: 100px;
    min-width: 100px; */

  border-radius: 5px;
  text-align: center;
  background-color: #F5F6F8;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
  margin-left: auto;
  margin-right: auto;
  /* padding: 15px; */
  padding: 15px 0px 15px 0px;
}

.tableButtonsCell {
  background-color: #fff;
  width: 50px;
  /* max-width: 30px;
    min-width: 20px; */
  text-align: center;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
  border-radius: 8px;
  /* padding: 15px; */
}

.buttonTab {

  text-align: center;
  background-color: #fff;
  font-size: 1.0em;
  border-radius: 5px;

  margin-left: auto;
  margin-right: auto;
}

.buttonStyles {
  color: white;
  border: none;
  background: none;
  background-color: #fff;
  font-size: 1.0em;
  align-items: center;
  font-family: GTWalsheimProRegular;
  outline: none;
  margin-top: 5px;
}

/* .buttonTab:hover .buttonStyles {
    background-color: #535454;
    color: white;
  }
  
  .buttonTab:hover {
    background-color: #535454;
  } */

.activeButton {
  /*     min-width: 150px;
    max-width: 150px; */
  width: 100%;
  background-color: #EEFFF7;
  color: #000000;
  border-style: none;
  /* border-width: 10px; */
  /* border: 3px solid #3AD26E; */
  border: 3px solid #EEFFF7;
  padding: 15px 0px;
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 16px;
  /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); */
}

.calibrateButton {
  /*     min-width: 150px;
    max-width: 150px; */
  width: 100%;
  background-color: #FFEDED;
  color: #000000;
  border-style: none;
  /* border-width: 10px; */
  border: 3px solid #FFEDED;
  padding: 15px 0px;
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 16px;
  /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); */
}

.completedButton {
  /*     min-width: 150px;
    max-width: 150px; */
  width: 100%;
  background-color: #F6FCFF;
  color: #000000;
  border-style: none;
  /* border-width: 10px; */
  border: 3px solid #F6FCFF;
  padding: 15px 0px;
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 16px;
  /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); */
}

.inactiveButton {
  /*     min-width: 150px;
    max-width: 150px; */
  width: 100%;
  background-color: #EEEFF2;
  color: #000000;
  border-style: none;
  /* border-width: 10px; */
  border: 3px solid #EEEFF2;
  padding: 15px 0px;
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 16px;
  /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); */
}

.terminateButton {
  /*     min-width: 150px;
    max-width: 150px; */
  width: 100%;
  background-color: #F5F0F0;
  /* color: rgba(128, 128, 128, 0.75);; */
  color: #000000;
  border-style: none;
  /* border-width: 10px; */
  border: 3px solid #F5F0F0;
  padding: 15px 0px;
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 16px;
  /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); */
}

.registerButton {
  /*     min-width: 150px;
    max-width: 150px; */
  width: 100%;
  background-color: #FDEAFF;
  color: #000000;
  border-style: none;
  /* border-width: 10px; */
  border: 3px solid #FDEAFF;
  padding: 15px 0px;
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 16px;
  /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); */
}

.redButton {
  width: 100%;
  background-color: #FFB1CF;
  color: #000000;
  border-style: none;
  border: 3px solid #FFB1CF;
  padding: 15px 0px;
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 16px;
}

.yellowButton {
  width: 100%;
  background-color: #FCFFAE;
  color: #000000;
  border-style: none;
  border: 3px solid #FCFFAE;
  padding: 15px 0px;
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 16px;
}

.greenButton {
  width: 100%;
  background-color: #B9FFDE;
  color: #000000;
  border-style: none;
  border: 3px solid #B9FFDE;
  padding: 15px 0px;
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 16px;
}

.qvIcon {
  color: #757575;

}

.qvIcon:hover {
  color: #4E253A;
}

.tableRow:hover, .tableRow:hover button {
  cursor: pointer;

}

.tableRow:hover td:not(.excludeHover) {
  background-color: #FEF4F9;
  box-shadow: 0 8px 8px -8px #4E253A;
}

.tableRow:hover div:not(.excludeHover) {
  background-color: #FEF4F9;
}
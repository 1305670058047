.backColor {
    background-color: #F3E8EE;
    /* height: 100%; */
    /* background-image: url(`../../../../../../../Assets/Images/QV-BG.png); */
}

.cardNoRisk {
    box-shadow: 0px 0px 2px #495057;
    margin: 10px 20px;
    padding: 5px 5px 5px 5px;
    border-radius: 10px;
    transition: 0.3s linear all;
    /* background-image: linear-gradient(to right, #00B4D8 ,#ADE8F4); */
    background-color: #FDF8FA;
    opacity: 0.7;
    display: flex;
    /* border: 1px solid linear-gradient(to right, #00B4D8 ,#ADE8F4); */
    border: 1px solid #A69B9B;
}

.cardHighRisk {
    box-shadow: 0px 0px 2px #495057;
    margin: 10px 20px;
    padding: 5px 5px 5px 5px;
    border-radius: 10px;
    transition: 0.3s linear all;
    /* background-image: linear-gradient(to right, #00B4D8 ,#ADE8F4); */
    background-color: #FDF8FA;
    opacity: 0.7;
    display: flex;
    /* border: 1px solid linear-gradient(to right, #00B4D8 ,#ADE8F4); */
    border: 1px solid #F06868;
}

.cardHighRisk:hover {
    box-shadow: 2px 2px 3px #495057;
}

.cardNoRisk:hover {
    box-shadow: 2px 2px 3px #495057;
}

.iconDivStyles {
    height: 55px;
    width: 60px;
    display: flex;
    align-items: center;
    margin: 0px;
    padding-top: 0px;
    background-color: #F2EFEF;
    border-radius: 5px;
    justify-content: center;
    border: 1px solid #FFF;
}

.iconRiskDivStyles {
    height: 55px;
    width: 60px;
    display: flex;
    align-items: center;
    margin: 0px;
    padding-top: 0px;
    background-color: #F06868;
    border-radius: 5px;
    justify-content: center;
    border: 1px solid #FFF;
}

.countDivStyles {
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.patientCountNormal {
    background-color: #e7e7e7;
    border: 1px solid #fff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #4e253a;
    font-family: GTWalsheimProRegular;
    font-size: 22px;
    font-weight: 1000;
    height: 30px;
    text-align: center;
    padding-top: 6px;
    width: 70px;
}

.patientCountHighRisk {
    background-color: #e7e7e7;
    border: 1px solid #fff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #F06868;
    font-family: GTWalsheimProRegular;
    font-size: 22px;
    font-weight: 1000;
    height: 30px;
    text-align: center;
    padding-top: 6px;
    width: 70px;
}

.patientCatagoryHighRisk {
    background-color: #F06868;
    border: 1px solid #fff;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    color: #FFFFFF;
    font-family: GTWalsheimProRegular;
    font-size: 11.2px;
    font-weight: 200;
    padding-top: 1px;
    text-align: center;
    width: 70px;
}

.patientCatagoryNoRisk {
    background-color: #dedada;
    border: 1px solid #fff;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    color: #4e253a;
    font-family: GTWalsheimProRegular;
    font-size: 11.2px;
    font-weight: 700;
    padding-top: 1px;
    text-align: center;
    width: 70px;
}

.highRiskIcon {
    width: 35px;
    height: 35px;
    fill: #FFFFFF;
}

.noRiskIcon {
    width: 35px;
    height: 35px;
    fill: #4E253A;
}

.ll {
    display: flex;
    align-items: center;
}

.something {
    display: flex;
    align-items: center;
    width: 50%;
}

.ok {
    text-align: right;
    width: 50%;
}

.iconDivStyles :hover>.highRiskIcon {
    color: #03045e;
}

.graphsAidarView {
    box-shadow: 2px 2px 10px #dadada;
    margin: 5px;
    padding: 20px 10px;
    height: 50px;
    border-radius: 5px;
    transition: 0.3s linear all;
    /* background-image: linear-gradient(to right, #00B4D8 ,#ADE8F4); */
    background-color: #535354;
    opacity: 0.7;
    /* display: flex; */
    text-align: center;
    align-items: center;
    /* border: 1px solid linear-gradient(to right, #00B4D8 ,#ADE8F4); */
}

.graphValues {
    margin-top: 11px;
    font-size: 18px;
    color: #ffff;
    text-align: center;
    align-items: center;
    font-family: GTWalsheimProRegular;
}

/* Modal Menu styling */

.modal {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    position: relative;
    /* margin-left: 10%;
        margin-right: 90%;
        top: 50; */
}

.paper {
    background-color: white;
    outline: none;
    border-radius: 10px;
    padding: 10px 0px 10px 0px;
    align-items: center;
    width: 800px;
    height: 480px;
    overflow-x: auto;
    position: absolute;
    top: 50%;
    left: 31%;
    right: 69%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.menuTitle {
    background-color: #ffff;
    display: flex;
    /* border-bottom: 1px solid gray; */
    text-align: center;
    width: 100%;
    align-items: center;
    font-size: 20px;
    font-family: GTWalsheimProRegular;
}

.titleText {
    font-style: GTWalsheimProRegular;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 18px;
    padding: 10px;
    text-align: center;
}

.patientListDiv {
    display: flex;
}

/*red color hover */

.cardHighRiskRed {
    box-shadow: 2px 2px 10px #dadada;
    margin: 5px;
    padding: 20px 10px;
    height: 50px;
    border-radius: 5px;
    transition: 0.3s linear all;
    /* background-image: linear-gradient(to right, #00B4D8 ,#ADE8F4); */
    background-color: #e63946;
    opacity: 0.7;
    display: flex;
    /* border: 1px solid linear-gradient(to right, #00B4D8 ,#ADE8F4); */
}

.cardHighRiskRed:hover {
    background-color: #f4f7fd;
    border: 1px solid #e63946;
}

.cardHighRiskRed:hover .highRiskIconred {
    color: #e63946;
}

.cardHighRiskRed:hover .patientCatagoryHighRiskred {
    color: #e63946;
}

.cardHighRiskRed:hover .patientCountHighRiskred {
    color: #e63946;
}

.patientCountHighRiskred {
    font-size: 26px;
    color: #f4f7fd;
    text-align: right;
    font-family: GTWalsheimProRegular;
}

.patientCatagoryHighRiskred {
    font-size: 15px;
    color: #f4f7fd;
    text-align: right;
    font-family: GTWalsheimProRegular;
}

.highRiskIconred {
    color: #f4f7fd;
}

.graphButtons {
    /* background: transparent; */
    background-color: #06293d;
    border: none;
    /* background-color: #faf9f9; */
    padding: 10px;
    border-radius: 10px;
    align-items: center;
    color: #495057;
    box-shadow: 0px 2px 9px 0px #888888;
    /* border-left: 5px solid rgb(229, 152, 155); */
    margin-right: 10px;
    margin-left: 10px;
}

.graphIconDiv {
    align-items: center;
    text-align: center;
}

.iconStyleGraphButton {
    margin-left: auto;
    margin-right: auto;
    color: #e85c4b;
}

.titleDivStyle {
    text-align: center;
}

.buttonTitle {
    margin-right: auto;
    margin-left: auto;
    font-family: GTWalsheimProRegular;
    font-size: 16px;
    color: #ffff;
}

/* timestamp at bottom styles */
.timeStampStyles {
    display: flex;
    flex-direction: column;
    font-size: 17px;
    font-family: GTWalsheimProRegular;
    align-items: center;
    text-align: center;
    color: #6f7071;
    /* margin-right: 20px; */
    margin-left: 15px;
    margin-bottom: 0px;
    bottom: 11%;
    position: fixed;
    width: 550px;
}

.timeStampText {
    font-size: 12px;
    color: #7C596A;
    font-family: GTWalsheimProRegular;
}

.painDiv {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    margin-left: 20px;
    border-radius: 6px;
    margin-top: 10px;
}

.painDivDesc {
    font-family: 'GTWalsheimProRegular';
    font-size: 15px;
    font-weight: bold;
    color: #4E253A;
    margin-bottom: 5px;
}

.addNewNoteDiv {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 10px;
    height: fit-content;
    align-items: center;
    column-gap: 0px;
    width: 97%;
    padding-right: 10px;
}

.notesEditor {
    position: relative;
    width: 95%;
    padding-right: 10px;
    border-radius: 2px;
    font-size: 12px;
    align-items: start;
    justify-content: left;
    margin-top: 10px;
    margin-left: 20px;
    padding-left: 10px !important;
    display: inline-block;
    overflow: hidden;
    font-family: GTWalsheimProRegular !important;
    background-color: #FFFFFF;
    padding-top: 8px !important;
    min-height: 60px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.painLevelRating {
    display: flex;
    flex-direction: row;
    column-gap: 14px;
    padding: 5px;
}

.ratingdiv {
    display: flex;
    height: 30px;
    width: 30px;
    border: solid 2px #4E253A;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
    border-radius: 6px;
    background-color: #F1D4D4;
}

.selectedRatingdiv {
    display: flex;
    height: 30px;
    width: 30px;
    border: solid 2px #4E253A;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
    border-radius: 6px;
    background-color: #4E253A;
    color: #FFFFFF;
    font-weight: 500;
}
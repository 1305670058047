.circlesList {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding: 10px;
    justify-content: left;
}

.tootlTipHeading {
    font-family: "GTWalsheimProRegular";
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
    font-style: normal;
    padding-top: 10px;
    background-color: #F7E1EA;
}

.greenCircle {
    border-radius: 50px;
    height: 18px;
    width: 18px;
    background-color: #C6E8BF;
    border: 0.5px solid #cccccc;
}

.orangeCircle {
    border-radius: 50px;
    height: 18px;
    width: 18px;
    border: 0.5px solid #cccccc;
    background-color: #FFE299;
}

.redCircle {
    border-radius: 50px;
    height: 18px;
    width: 18px;
    background-color: #FB9595;
    border: 0.5px solid #cccccc;
}

.circlesListItem {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    font-family: "GTWalsheimProRegular";
    font-size: 14px;
    font-style: normal;
    align-items: center;
    justify-content: space-around;
}

.aidiGraphWrapper {
    padding: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin: 10px;
    background-color: #fbf4f7;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.containerHeader {
    display: flex;
    flex-direction: row;
    background-color: #985C70;
    margin: -10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    justify-content: space-between;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin-bottom: 10px;
    padding: 0px;
}

.mainMessage {
    display: flex;
    font-size: large;
    font-weight: bold;
    font-family: "GTWalsheimProRegular";
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    padding: 10px;
}

.noDataWrapper {
    align-items: center;
    justify-content: center;
    background-color: #fbf4f7;
    min-height: 50vh;
    display: flex;
    flex-direction: row;
}

.retrainDiv {
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 20px;
}

.retrainHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #FFF1F8;
    font-weight: bold;
    font-size: 17px;
    padding: 10px;
    color: #4E253A;
}

.retrainHeaderClose {
    display: flex;
    flex-direction: column;
    padding: 4px;
    border-radius: 50%;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.retrainHeaderClose:hover {
    cursor: pointer;
}

.retrainBodyText {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #AA346F;
    font-weight: 600;
    text-align: center;
}

.retrainBodyButtons {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    margin-bottom: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.retrainSupBut{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #4E253A;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: #4E253A 2px solid;
    font-size: 14px;
    width: 120px;
}

.retrainSupBut:hover {
    cursor: pointer;
}

.retrainCarTBut {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: #AA346F 2px solid;
    background-color: #AA346F;
    font-size: 14px;
    width: 120px;
}

.retrainCarTBut:hover {
    cursor: pointer;
}

.retrainBodyImg{
    margin-bottom: 10px;
    margin-bottom: 20px;
}

.supportIcon {
}

.subMessage {
    display: flex;
    font-weight: normal;
    font-style: italic;
    font-family: "GTWalsheimProRegular";
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 10px;
}

.aidiGraphHeadingDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    padding-left: 10px;
}

.expandAidiButton {
    color: #FFFFFF !important;
    font-size: 14px !important;
    font-family: "GTWalsheimProRegular";
}

.aidiIconWrapper {
    display: flex;
    flex-direction: row;
    background-color: #FFFFFF;
    border-radius: 50px;
    padding: 1px;
    margin-right: 10px;
}

.legendDiv {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.baselineLegendDiv {
    font-size: 15px;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.baselineLegendDivRec {
    font-size: 15px;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.baselineIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    font-weight: normal;
    margin-right: 10px;
    margin-left: 10px;
    color: #000000;
    border-radius: 10px;
}

.baselineIconRec {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;
    font-weight: bold;
    margin-right: 10px;
    margin-left: 10px;
    color: #FFFFFF;
}

.legendDiv {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    padding: 8px;
    padding-left: 20px;
    background-color: #faeaea;
    margin: -10px;
    margin-bottom: 0px;
}

.graphDiv {
    display: flex;
    flex-direction: row;
}

.legendDivV2 {
    display: flex;
    flex-direction: row;
}

.graphDivV2 {
    display: flex;
    flex-direction: column;
    min-height: 50vh;
}

.colorBar {
    min-width: 29px;
    height: 220px;
    margin-top: 20px;
    margin-right: -50px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.redBar {
    background-color: #FB9595;
    height: 102px;
    margin-top: 5px;
    border-top-left-radius: 5px;
    opacity: 1;
}

.yellowBar {
    background-color: #FFE299;
    height: 27px;
    opacity: 1;
}

.greenBar {
    background-color: #C6E8BF;
    height: 56px;
    border-bottom-left-radius: 5px;
    opacity: 1;
}


.baselineLegendItem {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.legendLabel {
    color: white;
    padding: 2px 6px;
    border-radius: 3px;
    margin-right: 8px;
    font-weight: bold;
}

.tooltipHeader {
    color: white;
    padding: 8px;
    font-weight: bold;
    height: 16px;
    line-height: 28px;
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: "GTWalsheimProRegular";
}

.tooltipSubHeader {
    font-weight: normal;
    margin-top: 5px;
    color: #4E253A;
    font-size: 12px;
    text-align: center;
    margin: 10px;
}

.tooltipDivider {
    margin: 5px 0px;
    opacity: 0.3;
    margin-bottom: 10px;
}

.tooltipParamItem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 18px;
    padding-left: 10px;
}

.tooltipParamNumber {
    width: 20px;
    height: 20px;
    border-radius: 108px;
    background-color: #FFF;
    color: black;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    font-size: 11px;
    font-family: "GTWalsheimProRegular";
}

.tooltipParamText {
    color: #4E253A;
    font-size: 14px;
    font-family: "GTWalsheimProRegular";
    margin-left: 10px;
}

.infoIcon {
    height: 17px !important;
    width: 17px !important;
    cursor: pointer;
}

.headerInfoIcon {
    height: 20px;
    width: 20px;
    fill: #ffffff;
    margin-right: 5px;
    margin-left: 10px;
}

.expandButton {
    padding: 8px;
}

.expandIconWrapper {
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrowIcon {
    height: 25px;
    width: 25px;
    fill: #4E253A;
}

.loadingWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh !important;
}

.noDataMessage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 6px;
    align-self: center;
    align-items: center;
    width: 220px;
    border-radius: 20px;
    border: 2.08px solid #AA346F;
}

.noDataMessageText {
    font-weight: 700;
    color: #AA346F;
    font-size: 20px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
}

.heartBeatIcon {
    height: 44px;
    width: 44px;
}

.infoCardPaper {
    max-width: 400px;
    margin: auto;
    border-radius: 12px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.infoCardHeader {
    background-color: #FFF1F8;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
}

.infoCardTitle {
    text-align: center;
}

.infoCardContent {
    padding: 10px;
}

.infoCardImage {
    width: 150px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.infoCardButton:hover {
    border-color: black;
    background-color: #f0f0f0;
}
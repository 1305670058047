/* General Styles */
:root {
  --Maroon: #aa346f;
  --DarkMaroon: #7c596a;
  --LightPink: #fce5f0;
  --HoverPink: #ffc8d9;
  --ButtonRadius: 89.286px;
  --BorderWidth: 1.286px;
  --FontFamily: GTWalsheimProRegular;
}

.add-edit-notes-container {
  width: 600px;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 10;
  height: 100%;
  background-color: #f3e8ee;
  border-radius: 8px;
  box-shadow: -6px 0px 8px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  font-family: var(--FontFamily);
}

.header {
  background-color: #f9f3f6;
  color: var(--DarkMaroon);
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc9c9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.close-icon {
  color: #888;
  font-size: 18px;
}

.profile-details {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.name-and-id {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10px;
}

.name {
  font-size: 14px;
  font-weight: bold;
}

.id {
  font-size: 12px;
  color: #666;
}

.notes-title {
  background-color: #f9f3f6;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  width: 200px;
  align-self: center;
  color: var(--DarkMaroon);
  text-align: center;
  border-radius: 0px 0px 12px 12px;
  margin-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.accordion-title {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  color: var(--DarkMaroon);
  font-family: var(--FontFamily);
}

.custom-accordion {
  box-shadow: none;
  background-color: transparent;
  margin: 0; /* Remove margin around Accordion */
}

.accordion-summary {
  background-color: #f9f3f6;
  border-radius: 0;
  padding: 0 20px; /* Customize padding if needed */
  min-height: 48px !important;
}

.MuiAccordionSummary-content {
  margin: 0 !important;
  align-items: center;
}

.MuiAccordionSummary-expandIconWrapper {
  color: var(--DarkMaroon);
}

.MuiAccordion-root:before {
  display: none;
}

.MuiAccordionDetails-root {
  padding: 0;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
  padding: 0 !important;
  box-shadow: none;
}
.MuiAccordionSummary-root {
  background-color: #EFC5D9;
  display: flex !important;
  padding: 0px 16px !important;
}

/* TextBoxWithButtons Component */
.textbox-container {
  position: relative;
  margin-bottom: 20px;
  font-family: var(--FontFamily);
}

.textarea-wrapper {
  display: flex;
  flex-direction: column;
}

.note-textarea {
  height: 300px;
  padding: 10px;
  border: 1px solid #ddd;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  resize: none;
  font-size: 14px;
  font-family: var(--FontFamily);
  color: var(--DarkMaroon);
  outline: none;
}

.character-count {
  text-align: right;
  font-size: 12px;
  color: var(--DarkMaroon);
  background-color: #ffffff;
  padding: 5px 10px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.status-buttons-container {
  display: flex;
  align-items: center;
  height: 35px;
}

.status-button {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #f6eff4;
  color: var(--Maroon);
  font-weight: bold;
  cursor: pointer;
  border-radius: 0;
  transition: background-color 0.3s ease;
}

.status-button:first-child {
  border-radius: 0 0 0 8px;
}

.status-button.selected {
  color: #ffffff;
}

.bookmark-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  border: 1px solid #ddd;
  border-radius: 0 0 8px 0px;
  background-color: #f6eff4;
}

.bookmark-icon {
  color: #aa346f;
  height: 20px;
  width: 20px;
}

/* Action Buttons */
.action-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  align-self: center;
}

.cancel-button,
.submit-button {
  width: 150px;
  padding: 10px;
  border-radius: var(--ButtonRadius);
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: var(--FontFamily);
}

.cancel-button {
  margin-right: 20px;
  border: var(--BorderWidth) solid var(--Maroon);
  background-color: var(--LightPink);
  color: var(--Maroon);
}

.cancel-button:hover {
  background-color: var(--HoverPink);
}

.submit-button-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
}

.submit-button {
  border: none;
  background-color: var(--Maroon);
  color: #ffffff;
}

.submit-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
  background-color: #8b4260;
}

.view-all-notes-button {
  width: 100%;
  background-color: #B95888;
  color: #ffffff;
  border: none;
  border-radius: 0 0 8px 8px;
  padding: 15px;
  cursor: pointer;
  margin-top: auto;
  font-family: var(--FontFamily);
  font-size: 15px;
}
.view-all-notes-button:hover {
  background-color: #8b4260;
}
.content-container{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  overflow-y: auto;
}
.accordion-details{
  display: flex;
  flex-direction: column;
}

.icon{  
  margin-right: 10px
}
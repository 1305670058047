.accContainer {
  align-items: center;
}

.displayContainer {
  background-color: #fff;
  /* background-color: red; */
  /* border-radius: 10px; */
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 5px;
  padding-top: 5px;
  /* margin-left: 10px;
   margin-right: 10px; */
  align-items: center;
  height: 55px;
  display: flex;
}

.imgTopStyles {
  /* height: 200px;
    width: 100%; */
  /* border-radius: 10px; */
  height: 2.5rem;
  margin-top: 7px;
  margin-right: 25px;
  margin-left: 20px;
}

.userGreetings {
  margin-left: auto;
  margin-right: 20px;
  font-family: "GTWalsheimProRegular";
  font-size: 18px;
}

.infoBottomImage {
  display: flex;
  padding: 0px 0px 5px 5px;
}

.textInfoBottomImage {
  color: #5f5c70;
  margin-right: 20px;
  margin-left: 20px;
}

.textInfoBottomImageRight {
  color: #5f5c70;
  margin-left: auto;
  margin-right: 20px;
}

.userGreetingsMessage {
  display: flex;
}

.userGreetingIcon {
  margin-right: 5px;
  color: #535354;
}

.MuiCircularProgress-colorPrimary {
  color: #4E253A !important;
}

.MuiCircularProgress-root {
  left: 43%;
  position: absolute;
  top: 44vh;
}

.selectedMLDiv {
  display: flex;
  flex-direction: row;
  width: 96%;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  justify-content: space-between;
  margin: 20px;
  background-color: #F1D4D4;
  padding: 10px;
}

.MLIdDiv {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border: #4E253A 1px solid;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 20px;
  font-family: "GTWalsheimProRegular";
  color: #ffffff;
  background-color: #4E253A;
}

.unpairButton {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 10px;
  font-size: 20px;
  font-family: "GTWalsheimProRegular";
  background-color: #F14C00;
  color: #ffffff;
}

.unpairButton:hover {
  cursor: pointer;
}

.modal {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  position: relative;
  /* margin-left: 10%;
          margin-right: 90%;
          top: 50; */
}

.paperUnassignML {
  background-color: #fff;
  outline: none;
  border-radius: 20px;
  padding: 10px;
  align-items: center;
  width: 400px;
  /* height: 350px; */
  overflow-x: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.menuTitle {
  background-color: #ffff;
  display: flex;
  /* border-bottom: 1px solid gray; */
  text-align: center;
  width: 100%;
  align-items: center;
  font-size: 20px;
  font-family: "GTWalsheimProRegular";
}

.titleText {
  font-style: "GTWalsheimProRegular";
  font-weight: bold;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 28px;
  padding: 10px;
  color: #4E253A;
}

.allPatientsHeader {
  width: 100%;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  background-color: #FFEEEA;
  margin-bottom: 20px;
}

.projectNameDiv {
  font-weight: bold;
  font-size: 20px;
  font-family: "GTWalsheimProRegular";
  color: #DF5927;
}

.locationDiv {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: "GTWalsheimProRegular";
  color: #4E253A;
  margin-right: 25px;
}

.locIconDiv {
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 50%;
}

.userNameDiv {
  padding-left: 10px;
  padding-right: 20px;
  font-weight: bold;
  font-size: 16px;
  font-family: "GTWalsheimProRegular";
  /* color: #fe6b59; */
  color: #DF5927;
}

.userPicDiv {
  /* border: 3px solid #fe6b59; */
  border: 3px solid #DF5927;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.avatar {
  height: 20px;
  width: 20px;
}

.userInfoDiv {
  display: flex;
  flex-direction: row !important;
  column-gap: 10px !important;
  border-radius: 50px;
  border: 1.5px solid #7C596A;
  padding-left: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 5px;
  background-color: #FFFBFD;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  min-width: 140px;
  align-items: center;
  justify-content: left;
  font-size: 16px;
  font-family: "GTWalsheimProRegular";
}

.avatar2 {
  height: 24px !important;
  width: 24px !important;
  border: 1.5px solid #7C596A;
}

.accessDeniedDiv {
  height: 100%;
  width: 100%;
  display: flex;
  font-size: 24px;
  font-family: "GTWalsheimProRegular";
  font-weight: bold;
  align-items: center;
  justify-content: center;
}